import React, { useState, useContext, useEffect } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import styled, { keyframes } from "styled-components";
import { theme } from "../../utils/theme";
import { AppErrorText, AppText, Loading } from "../UI";
import { FlexDiv } from "../UI/FlexDiv";
import { PhoenixAppButton, PhoenixExpandButton, PhoenixIcon, PhoenixInput } from "../UI/Phoenix";
import { reorder, sequences } from "../../images/NewDesign";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { SequenceRow } from "../UI/Sequences/SequenceRow";
import { ModalContext } from "../../context";
import { DeleteSequenceModal, RenameSequenceModal } from "../modal";
import { loggedInUser } from "../../apollo/cache";
import { errorToast, successToast } from "../../utils/toast";
import { SequenceNav } from "../UI/Sequences/SequenceNav";
import { useDebounce } from "../../utils/hooks";
import { MixpanelActions } from "../../services/mixpanel";
import { LeadListWindow } from "../UI/Sequences/LeadListWindow";
import { APP_ROLES } from "../../utils/variables";

const FETCH_SEQUENCES = gql`
  query fetchSequences($sequencesFilter: SequencesFilter!) {
    fetchSequences(SequencesFilter: $sequencesFilter) {
      id
      created_at
      updated_at
      created_by {
        id
        full_name
      }
      last_updated_by {
        id
        full_name
      }
      active_steps {
        conditions {
          id
        }
      }
      name
      is_paused
      priority
      steps_count
      email_action_count
      phone_action_count
      sms_action_count
      custom_task_action_count
      leads_count
      manager_feedback_count
      exit_count
      completed_count
      conversion_count
      not_interested_count
      all_leads_count
      sequence_entry_criteria {
        id
        current_phase
        types
        channels
        person_spoke_to
        sequence_criteria_condition {
          id
          condition_type
        }
      }
    }
  }
`;

const UPDATE_SEQUENCE_LIST_ORDER = gql`
  mutation updatePriorities($updateSequencesPriorityInput: updateSequencesPriorityInput!) {
    updatePriorities(updateSequencesPriorityInput: $updateSequencesPriorityInput)
  }
`;

const CREATE_SEQUENCE = gql`
  mutation createOrUpdateSequence($sequenceInput: SequenceInput!) {
    createOrUpdateSequence(SequenceInput: $sequenceInput) {
      id
      created_at
      updated_at
      created_by {
        id
        full_name
      }
      last_updated_by {
        id
        full_name
      }
      name
      priority
      is_paused
      steps_count
      email_action_count
      phone_action_count
      sms_action_count
      custom_task_action_count
      leads_count
      manager_feedback_count
      exit_count
      completed_count
      sequence_entry_criteria {
        id
        current_phase
      }
    }
  }
`;

export const SequencesPage: React.FC = () => {
  const { showDeleteSequenceModal, showRenameSequenceModal, sequenceNavExpanded, setSequenceNavExpanded } = useContext(
    ModalContext,
  );

  const [didFirstLoad, setDidFirstload] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [sequenceList, setSequenceList] = useState<any[]>([]);
  const [selectedSequenceId, setSelectedSequenceId] = useState<string>("");
  const [manualLoad, setManualLoad] = useState<boolean>(false);
  const [doAnimateHandle, setDoAnimateHandle] = useState<boolean>(false);
  const [sequenceDrilldownID, setSequenceDrilldownID] = useState<string | null>(null);
  const [drilldownMetrics, setDrilldownMetrics] = useState<any>({
    active_count: 0,
    completed_count: 0,
    not_interested_count: 0,
    conversion_count: 0,
    all_leads_count: 0,
    system_exit_count: 0,
  });

  const { data: dataSequences, loading: loadingSequences, error: errorSequences, refetch: refetchSequences } = useQuery(
    FETCH_SEQUENCES,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        sequencesFilter: {},
      },
      onCompleted({ fetchSequences }) {
        console.log("fetchSequences:", fetchSequences);
        setSequenceList(fetchSequences);
        setLoadingSearch(false);
        manualLoad && setManualLoad(false);
        !didFirstLoad && setDidFirstload(true);
      },
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
        !didFirstLoad && setDidFirstload(true);
      },
    },
  );

  useDebounce(
    () => {
      !!didFirstLoad &&
        refetchSequences({
          sequencesFilter: {
            name: search,
          },
        });
      setDoAnimateHandle(!!search);
    },
    [search],
    500,
  );

  useEffect(() => {
    !loadingSearch && setLoadingSearch(true);
  }, [search]);

  const [updateSequenceListOrder, { loading: loadingUpdateSequenceListOrder }] = useMutation(
    UPDATE_SEQUENCE_LIST_ORDER,
    {
      async onCompleted({ updatePriorities }) {
        console.log("updatePriorities:", updatePriorities);
      },
      onError({ message }) {
        errorToast(`${message}`);
        Sentry.captureEvent({
          message: `updatePriorities GraphQL Error: ${message}`,
        });
        console.log(`updatePriorities GraphQL Error: ${message}`);
      },
    },
  );

  const [createSequence, { loading: loadingCreateSequence }] = useMutation(CREATE_SEQUENCE, {
    async onCompleted({ createOrUpdateSequence }) {
      console.log("createSequence:", createOrUpdateSequence);
      successToast("Sequence successfully created!");

      // update local list state
      const newSequenceList = [createOrUpdateSequence, ...sequenceList];
      setSequenceList(newSequenceList);

      // update sequence list priorities -- new sequence becomes priority '0', old priority 0 becomes 1
      updateSequenceListOrder({
        variables: {
          updateSequencesPriorityInput: {
            sequences_id: newSequenceList?.map((ele: any) => ele.id),
          },
        },
      });

      MixpanelActions.track("Sequence Created");
    },
    onError({ message }) {
      errorToast(`${message}`);
      Sentry.captureEvent({
        message: `createSequence GraphQL Error: ${message}`,
      });
      console.log(`createSequence GraphQL Error: ${message}`);
    },
  });

  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;

    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }

    const sequenceListCopy = [...sequenceList];

    // remove sequence from list at original position
    const removedEle = sequenceListCopy.splice(source.index, 1)[0];
    // add sequence to list in new position
    sequenceListCopy.splice(destination.index, 0, removedEle);

    setSequenceList(sequenceListCopy);

    MixpanelActions.track("Sequence Order Updated", {
      old_order: source.index + 1,
      new_order: destination.index + 1,
    });

    updateSequenceListOrder({
      variables: {
        updateSequencesPriorityInput: {
          sequences_id: sequenceListCopy?.map((ele: any) => ele.id),
        },
      },
    });
  };

  const handleCreateSequence = () => {
    MixpanelActions.track("Sequence Created");
    return createSequence({
      variables: {
        sequenceInput: { name: "New Sequence", priority: 0 },
      },
    });
  };

  const handleSequenceInsert = (newSequence: any, position: number) => {
    const sequenceListCopy = [...sequenceList];
    sequenceListCopy.splice(position, 0, newSequence);
    setSequenceList(sequenceListCopy);
    return sequenceListCopy;
  };

  if (loggedInUser()?.role !== APP_ROLES.ADMIN) return null;

  const selectedSequence = sequenceList?.find((ele) => ele.id === selectedSequenceId);

  return (
    <Main expanded={sequenceNavExpanded}>
      {showDeleteSequenceModal && (
        <DeleteSequenceModal
          sequence={selectedSequence}
          sequenceId={selectedSequenceId}
          setManualLoad={setManualLoad}
        />
      )}
      {showRenameSequenceModal && (
        <RenameSequenceModal
          dataSequences={dataSequences}
          sequenceList={sequenceList}
          setSequenceList={setSequenceList}
        />
      )}

      {!!sequenceDrilldownID && (
        <LeadListWindow
          draggable
          sequenceId={sequenceDrilldownID}
          onClose={() => setSequenceDrilldownID(null)}
          metrics={drilldownMetrics}
        />
      )}

      <PhoenixExpandButton
        expandedState={sequenceNavExpanded}
        moveDirection="right"
        moveDistance={224}
        top={24}
        left={72}
        onClick={() => setSequenceNavExpanded(!sequenceNavExpanded)}
      />
      <SequenceNav expanded={sequenceNavExpanded} />
      <TopOptions>
        <AppText fontSize={22} fontWeight={500}>
          Sequences
        </AppText>
        {manualLoad && (
          <div style={{ margin: "4px auto 0px 16px" }}>
            <Loading />
          </div>
        )}

        <RightOptions>
          <PhoenixInput
            placeholder="Search"
            variant="primary"
            type="text"
            name="search"
            id="search"
            searchInput
            fontSize={10}
            fontWeight={400}
            displayNoContextText
            width={243}
            value={search}
            onChange={(e: any) => setSearch(e.target.value)}
          />

          <PhoenixAppButton
            buttonType="secondary"
            variant="brand"
            onClick={handleCreateSequence}
            disabled={!!loadingCreateSequence}
          >
            <FlexDiv justify="center" align="center" gap={16}>
              <PhoenixIcon svg={sequences} size={16} color={theme.WHITE_COLOR} hoverColor={theme.WHITE_COLOR} pointer />
              <AppText
                fontSize={10}
                fontWeight={600}
                variant="white"
                style={{ textTransform: "uppercase", letterSpacing: "1px", lineHeight: "16px" }}
              >
                Create Sequence
              </AppText>
            </FlexDiv>
          </PhoenixAppButton>
        </RightOptions>
      </TopOptions>

      <Body>
        <SequencesListContainer>
          <DragDropContext onDragEnd={onDragEnd}>
            <ColumnContainer>
              <AppText fontSize={12} fontWeight={600} style={{ minWidth: "220px", maxWidth: "220px" }}>
                Sequence Name
              </AppText>
              <AppText fontSize={12} fontWeight={600}>
                Phase
              </AppText>
              <AppText fontSize={12} fontWeight={600}>
                Date Created
              </AppText>
              <AppText fontSize={12} fontWeight={600}>
                Last Edited
              </AppText>
              <AppText fontSize={12} fontWeight={600}>
                Actions
              </AppText>
            </ColumnContainer>
            <SequencesList>
              {!!errorSequences && <AppErrorText>{errorSequences.message}</AppErrorText>}
              <Droppable droppableId="sequences-droppable-field">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {!loadingSequences || (loadingSequences && didFirstLoad) ? (
                      sequenceList?.length ? (
                        sequenceList?.map((data: any, i: number) => (
                          <Draggable
                            key={`${data?.id}`}
                            draggableId={`${data?.id}`}
                            index={i}
                            isDragDisabled={!!search || !!loadingSequences}
                          >
                            {(provided) => (
                              <FlexDiv
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                align="center"
                                gap={8}
                                style={{ ...provided.draggableProps.style, marginBottom: "8px" }}
                              >
                                <div ref={provided.innerRef} {...provided.dragHandleProps}>
                                  <GrabHandle
                                    svg={reorder}
                                    variant="neutral"
                                    size={16}
                                    hide={!!search}
                                    doAnimate={doAnimateHandle}
                                  />
                                </div>
                                <SequenceRow
                                  data={{ ...data, priority: i }}
                                  provided={provided}
                                  handleSequenceInsert={handleSequenceInsert}
                                  updateSequenceListOrder={updateSequenceListOrder}
                                  setManualLoad={setManualLoad}
                                  onClick={() => setSelectedSequenceId(data.id)}
                                  setSequenceDrilldownID={setSequenceDrilldownID}
                                  setDrilldownMetrics={setDrilldownMetrics}
                                />
                              </FlexDiv>
                            )}
                          </Draggable>
                        ))
                      ) : (
                        !search &&
                        !loadingSequences &&
                        !loadingSearch && (
                          <FlexDiv direction="column" align="center" style={{ marginTop: "120px" }}>
                            <AppText color={theme.NEUTRAL300} fontSize={12}>
                              Let's get started.
                            </AppText>
                            <AppText fontSize={16} style={{ marginBottom: "32px" }}>
                              Create your first sequence
                            </AppText>
                            <PhoenixAppButton
                              buttonType="secondary"
                              variant="brand"
                              onClick={handleCreateSequence}
                              disabled={!!loadingCreateSequence}
                            >
                              <FlexDiv justify="center" align="center" gap={16}>
                                <PhoenixIcon
                                  svg={sequences}
                                  size={16}
                                  color={theme.WHITE_COLOR}
                                  hoverColor={theme.WHITE_COLOR}
                                  pointer
                                />
                                <AppText
                                  fontSize={10}
                                  fontWeight={600}
                                  variant="white"
                                  style={{ textTransform: "uppercase", letterSpacing: "1px", lineHeight: "16px" }}
                                >
                                  Create Sequence
                                </AppText>
                              </FlexDiv>
                            </PhoenixAppButton>
                          </FlexDiv>
                        )
                      )
                    ) : (
                      <SkeletonContainer align="center" direction="column" gap={8}>
                        <SequenceSkeleton />
                        <SequenceSkeleton />
                        <SequenceSkeleton />
                        <SequenceSkeleton />
                        <SequenceSkeleton />
                        <SequenceSkeleton />
                      </SkeletonContainer>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </SequencesList>
          </DragDropContext>
        </SequencesListContainer>
      </Body>
    </Main>
  );
};

interface MainProps {
  expanded: boolean;
}

const Main = styled.div<MainProps>`
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  margin-left: ${(props) => (props.expanded ? "248px" : "24px")};
  border-radius: 8px;
  overflow: hidden;
  transition: margin-left 0.22s ease-in-out;
  min-width: 1200px;
`;

const TopOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 105px;
  padding: 0px 24px 0px 48px;
`;

const RightOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  width: 100%;
  height: fit-content;
  max-height: 80vh;
  min-height: 80vh;

  margin: 0px;
  padding: 48px 112px 0px 112px;

  background-color: ${theme.PRIMARY50};
  border-top: 1px solid ${theme.NEUTRAL200};
  overflow-y: auto;
`;

const ColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 64px 20px 64px;
`;

const SequencesListContainer = styled.div`
  width: 100%;
`;
const SequencesList = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 65vh;
  padding-bottom: 60px;
`;

const pulse = keyframes`
  0% {
    background-color: ${theme.PRIMARY100};
  }
  50% {
    background-color: ${theme.PRIMARY300};
  }
  100% {
    background-color: ${theme.PRIMARY100};
  }
`;

const SequenceSkeleton = styled.div`
  width: calc(100% - 32px);
  height: 73px;

  margin-left: 20px;

  background-color: ${theme.PRIMARY100};
  animation: ${pulse} 1s ease infinite;
  border-radius: 8px;
`;

const SkeletonContainer = styled(FlexDiv)`
  ${SequenceSkeleton}:nth-child(1) {
    opacity: 1;
    animation-delay: 0.1s;
  }
  ${SequenceSkeleton}:nth-child(2) {
    opacity: 0.84;
    animation-delay: 0.2s;
  }
  ${SequenceSkeleton}:nth-child(3) {
    opacity: 0.68;
    animation-delay: 0.3s;
  }
  ${SequenceSkeleton}:nth-child(4) {
    opacity: 0.52;
    animation-delay: 0.4s;
  }
  ${SequenceSkeleton}:nth-child(5) {
    opacity: 0.36;
    animation-delay: 0.5s;
  }
  ${SequenceSkeleton}:nth-child(6) {
    opacity: 0.2;
    animation-delay: 0.6s;
  }
`;

const grabHandleFadeIn = keyframes`
  0% {
    opacity: 0;
    width: 0px;
  }
  100% {
    opacity: 1;
    width: 16px;
  }
`;
const grabHandleFadeOut = keyframes`
  0% {
    opacity: 1;
    width: 16px;
  }
  100% {
    opacity: 0;
    width: 0px;
  }
`;

interface GrabHandleProps {
  hide: boolean;
  doAnimate: boolean;
}

const GrabHandle = styled(PhoenixIcon)<GrabHandleProps>`
  cursor: grab;
  animation: ${(props) => props.doAnimate && (props.hide ? grabHandleFadeOut : grabHandleFadeIn)} 0.2s ease forwards;
`;
