import React, { useContext, useState, useEffect, useRef, Dispatch, SetStateAction } from "react";
import styled, { keyframes, css } from "styled-components";
import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import moment from "moment";
import { FlexDiv } from "../FlexDiv";
import { theme } from "../../../utils/theme";
import { AppText } from "../";
import { PhoenixAppButton, PhoenixIcon } from "../Phoenix";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import {
  chat,
  chevron_down,
  chevron_up,
  clone_blue,
  edit,
  info,
  log_in,
  mail,
  more_vertical,
  phone_call,
  sequences,
  settings,
  steps,
  trash,
  users,
} from "../../../images/NewDesign";
import { ManageLeadsMenu } from "./ManageLeadsMenu";
import { EditSequenceMenu } from "./EditSequenceMenu";
import { ModalContext } from "../../../context";
import { errorToast, successToast } from "../../../utils/toast";
import {
  entryCriteriaOptions,
  findEntryCriteriaMatchingLabel,
  getInclusionCriteriaFilters,
} from "../../../utils/sequences";
import { isEqual } from "lodash";
import { MixpanelActions } from "../../../services/mixpanel";

const CLONE_SEQUENCE = gql`
  mutation clone_sequence($sequence_id: String!) {
    clone_sequence(sequence_id: $sequence_id) {
      id
      created_at
      updated_at
      created_by {
        id
        full_name
      }
      last_updated_by {
        id
        full_name
      }
      name
      priority
      is_paused
      steps_count
      email_action_count
      phone_action_count
      sms_action_count
      custom_task_action_count
      leads_count
      manager_feedback_count
      exit_count
      completed_count
      sequence_entry_criteria {
        id
        current_phase
      }
    }
  }
`;

interface SequenceRowProps {
  data: any;
  provided: any; // for drag and drop
  onClick?: () => void;
  handleSequenceInsert?: any;
  updateSequenceListOrder?: any;
  setManualLoad?: Dispatch<SetStateAction<boolean>>;
  setSequenceDrilldownID: Dispatch<SetStateAction<string | null>>;
  setDrilldownMetrics: Dispatch<SetStateAction<any>>;
}

export const SequenceRow: React.FC<SequenceRowProps> = ({
  data,
  provided,
  onClick,
  handleSequenceInsert,
  updateSequenceListOrder,
  setManualLoad,
  setSequenceDrilldownID,
  setDrilldownMetrics,
}) => {
  const { setShowDeleteSequenceModal } = useContext(ModalContext);
  const isMounted = useRef(false);

  const [expanded, setExpanded] = useState<boolean>(false);
  const [showEditSequenceMenu, setShowEditSequenceMenu] = useState<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [cloneSequence, { loading: loadingCloneSequence }] = useMutation(CLONE_SEQUENCE, {
    async onCompleted({ clone_sequence }) {
      console.log("clone_sequence:", clone_sequence);
      successToast("Sequence successfully cloned!");
      if (!!handleSequenceInsert && !!updateSequenceListOrder) {
        const newListWithOrder = handleSequenceInsert(clone_sequence, data.priority + 1);
        updateSequenceListOrder({
          variables: {
            updateSequencesPriorityInput: {
              sequences_id: newListWithOrder?.map((ele: any) => ele.id),
            },
          },
        });
      }
      setManualLoad && setManualLoad(false);
    },
    onError({ message }) {
      errorToast(`${message}`);
      Sentry.captureEvent({
        message: `clone_sequence GraphQL Error: ${message}`,
      });
      console.log(`clone_sequence GraphQL Error: ${message}`);
      setManualLoad && setManualLoad(false);
    },
  });

  const onEditSequence = () => {
    MixpanelActions.track("Sequence Editing Initiated", {
      steps: data?.steps_count,
      sms_steps: data?.sms_action_count,
      email_steps: data?.email_action_count,
      dial_steps: data?.phone_action_count,
      feedback_steps: data?.manager_feedback_count,
      custom_steps: data?.custom_task_action_count,
      active_leads: data?.leads_count,
      coverted_leads: data?.conversion_count,
      completed_leads: data?.completed_count,
      not_interested_leads: data?.not_interested_count,
      system_exits: data?.exit_count,
      all_leads: data?.all_leads_count,
      is_active: !data?.is_paused,
      branches: data?.active_steps?.filter((ele: any) => !!ele?.conditions?.length).length,
      entry_criteria: findEntryCriteriaMatchingLabel(data?.sequence_entry_criteria?.current_phase),
      inclusion_criteria_filters: getInclusionCriteriaFilters(data?.sequence_entry_criteria),
    });
  };

  return (
    <SequenceItemContainer isMounted={isMounted.current} onClick={onClick}>
      <PhoenixStyledTooltip place="right" id={`sequence-row-${data?.id}`} fontSize={10} padding={8} />
      <SequenceItemTop>
        {/* Title */}
        <FlexDiv gap={8} align="center" style={{ minWidth: "296px", maxWidth: "296px" }}>
          <PhoenixIcon svg={sequences} variant="neutral" size={16} hoverColor="neutral" />
          <AppText
            fontSize={14}
            fontWeight={500}
            style={{ lineHeight: "20px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
          >
            {data?.name}
          </AppText>
        </FlexDiv>

        {/* Phase */}
        <div style={{ minWidth: "145px", maxWidth: "145px" }}>
          {data?.sequence_entry_criteria?.current_phase?.[0] && (
            <PhaseContainer>
              {
                entryCriteriaOptions?.find((option: { value: string | string[]; label: string }) =>
                  data?.sequence_entry_criteria?.current_phase[0] &&
                  data?.sequence_entry_criteria?.current_phase?.length === 1
                    ? option.value === data?.sequence_entry_criteria?.current_phase[0]
                    : isEqual(option.value, data?.sequence_entry_criteria?.current_phase),
                )?.label
              }
            </PhaseContainer>
          )}
        </div>

        {/* Date Created */}
        <AppText fontSize={12} style={{ color: theme.NEUTRAL300, lineHeight: "18px", maxWidth: "144px" }}>
          {`${moment(data?.created_at).format("MM/DD/YY")} by ${data?.created_by?.full_name}`}
        </AppText>

        {/* Last Edited */}
        <AppText fontSize={12} style={{ color: theme.NEUTRAL300, lineHeight: "18px", maxWidth: "144px" }}>
          {`${moment(data?.updated_at).format("MM/DD/YY")} by ${data?.last_updated_by?.full_name}`}
        </AppText>

        {/* Edit, Duplicate, Delete, Expand */}
        <ActionsContainer>
          <FlexDiv align="center" gap={16}>
            <div style={{ position: "relative" }}>
              {showEditSequenceMenu && (
                <EditSequenceMenu
                  sequenceId={data?.id}
                  sequenceInitialName={data?.name}
                  setShowEditSequenceMenu={setShowEditSequenceMenu}
                  priority={data?.priority}
                  onEditSequence={onEditSequence}
                />
              )}
              <PhoenixIcon
                svg={edit}
                size={16}
                pointer
                onClick={(e: MouseEvent) => {
                  e.stopPropagation();
                  setShowEditSequenceMenu(!showEditSequenceMenu);
                }}
              />
            </div>

            <img
              src={clone_blue}
              style={{ cursor: "pointer" }}
              data-for={`sequence-row-${data?.id}`}
              data-tip="Clone sequence"
              onClick={() => {
                if (!loadingCloneSequence) {
                  MixpanelActions.track("Sequence Cloned", {
                    steps: data?.steps_count,
                    sms_steps: data?.sms_action_count,
                    email_steps: data?.email_action_count,
                    dial_steps: data?.phone_action_count,
                    feedback_steps: data?.manager_feedback_count,
                    custom_steps: data?.custom_task_action_count,
                    active_leads: data?.leads_count,
                    coverted_leads: data?.conversion_count,
                    completed_leads: data?.completed_count,
                    not_interested_leads: data?.not_interested_count,
                    system_exits: data?.exit_count,
                    all_leads: data?.all_leads_count,
                    is_active: !data?.is_paused,
                    branches: data?.active_steps?.filter((ele: any) => !!ele?.conditions?.length).length,
                    entry_criteria: findEntryCriteriaMatchingLabel(data?.sequence_entry_criteria?.current_phase),
                    inclusion_criteria_filters: getInclusionCriteriaFilters(data?.sequence_entry_criteria),
                  });
                  cloneSequence({
                    variables: {
                      sequence_id: data?.id,
                    },
                  });
                }
                setManualLoad && setManualLoad(true);
              }}
            />

            <PhoenixIcon
              svg={trash}
              variant="danger"
              disabled={!data?.is_paused}
              size={16}
              pointer={data?.is_paused}
              data-for={!data?.is_paused && `sequence-row-${data?.id}`}
              data-tip="Cannot delete while active"
              onClick={() => data?.is_paused && setShowDeleteSequenceModal(true)}
            />
          </FlexDiv>

          <PhoenixIcon
            svg={expanded ? chevron_up : chevron_down}
            size={24}
            pointer
            onClick={() => setExpanded(!expanded)}
          />
        </ActionsContainer>
      </SequenceItemTop>
      {expanded && (
        <SequenceItemBottom>
          <MetricsLeft>
            <StepsContainer>
              <PhoenixIcon svg={steps} size="small" hoverColor={theme.PRIMARY500} />
              <AppText style={{ whiteSpace: "nowrap", lineHeight: "18px" }}>
                <span style={{ fontWeight: 600 }}>{data?.steps_count}</span> Steps
              </AppText>
            </StepsContainer>

            <MetricsContainerLeft>
              <MetricText>
                <PhoenixIcon svg={mail} size="small" variant="neutral" /> {data?.email_action_count}
              </MetricText>
              <MetricText>
                <PhoenixIcon svg={chat} size="small" variant="neutral" /> {data?.sms_action_count}
              </MetricText>
              <MetricText>
                <PhoenixIcon svg={phone_call} size="small" variant="neutral" /> {data?.phone_action_count}
              </MetricText>
              <MetricText>
                <PhoenixIcon svg={info} size="small" variant="neutral" /> {data?.manager_feedback_count}
              </MetricText>
              <MetricText>
                <PhoenixIcon svg={settings} size="small" variant="neutral" /> {data?.custom_task_action_count}
              </MetricText>
              <MetricText>
                <PhoenixIcon svg={log_in} size="small" variant="alert" /> {data?.exit_count}
              </MetricText>
            </MetricsContainerLeft>
          </MetricsLeft>

          <MetricsRight>
            <StepsContainer>
              <PhoenixIcon svg={users} size="small" hoverColor={theme.PRIMARY500} />
              <AppText style={{ lineHeight: "18px" }}>Leads</AppText>
            </StepsContainer>

            <MetricsContainerRight>
              <FlexDiv gap={16}>
                <FlexDiv align="center" gap={4}>
                  <Dot color={theme.SUCCESS500} />
                  <AppText style={{ lineHeight: "18px" }}>
                    <span style={{ fontWeight: 600 }}>{data?.leads_count}</span> Active
                  </AppText>
                </FlexDiv>
                <FlexDiv align="center" gap={4}>
                  <Dot color={theme.PRIMARY500} />
                  <AppText style={{ lineHeight: "18px" }}>
                    <span style={{ fontWeight: 600 }}>{data?.completed_count}</span> Completed
                  </AppText>
                </FlexDiv>
                <FlexDiv align="center" gap={4}>
                  <Dot color={theme.DANGER500} />
                  <AppText style={{ lineHeight: "18px" }}>
                    <span style={{ fontWeight: 600 }}>{data?.not_interested_count}</span> Not interested
                  </AppText>
                </FlexDiv>
                <FlexDiv align="center" gap={4}>
                  <Dot color={theme.DATAVIZ_1_400} />
                  <AppText style={{ lineHeight: "18px" }}>
                    <span style={{ fontWeight: 600 }}>{data?.conversion_count}</span> Conversion
                  </AppText>
                </FlexDiv>
              </FlexDiv>

              <PhoenixAppButton
                buttonType="secondary"
                variant="brand-outline"
                width={120}
                height={32}
                centered
                borderRadius={4}
                padding="0px"
                onClick={() => {
                  setSequenceDrilldownID(data?.id || "");
                  setDrilldownMetrics({
                    active_count: data?.leads_count || 0,
                    completed_count: data?.completed_count || 0,
                    not_interested_count: data?.not_interested_count || 0,
                    conversion_count: data?.conversion_count || 0,
                    all_leads_count: data?.all_leads_count || 0,
                    system_exit_count: data?.exit_count || 0,
                  });
                }}
                disabled={false}
              >
                <AppText
                  fontSize={10}
                  fontWeight={600}
                  color={theme.PRIMARY500}
                  lineHeight={16}
                  letterSpacing={1}
                  uppercase
                >
                  All Leads
                </AppText>
              </PhoenixAppButton>
            </MetricsContainerRight>
          </MetricsRight>
        </SequenceItemBottom>
      )}
    </SequenceItemContainer>
  );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

interface SequenceItemContainerProps {
  isMounted: boolean;
}

const SequenceItemContainer = styled.div<SequenceItemContainerProps>`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-width: 620px;
  margin-right: 8px;

  background-color: #fff;
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 8px;

  animation: ${(props) =>
    !props.isMounted
      ? css`
          ${fadeIn} 0.25s ease forwards
        `
      : "unset"};
  animation-fill-mode: initial;
  opacity: 1;
`;

const SequenceItemTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 73px;
  padding: 16px;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  height: 100%;
  padding-left: 24px;

  border-left: 1px solid ${theme.NEUTRAL200};
`;

const SequenceItemBottom = styled.div`
  display: flex;
  border-top: 1px solid ${theme.NEUTRAL200};
`;

const MetricsLeft = styled.div`
  display: flex;
  align-items: center;

  width: fit-content;

  padding: 8px 0px 8px 16px;
  border-right: 1px solid ${theme.NEUTRAL200};
`;

const MetricsRight = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  padding: 8px 0px 8px 16px;
`;

const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  height: 40px;
  padding-right: 24px;

  border-right: 1px solid ${theme.NEUTRAL200};
`;

const MetricsContainerLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  padding: 0px 24px;
`;
const MetricsContainerRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: 0px 24px;
`;

const MetricText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  font-weight: 600;
  line-height: 18px;
  font-size: 12px;
`;

interface DotProps {
  color: string;
}
const Dot = styled.div<DotProps>`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: ${(props) => props.color};
`;

const PhaseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  margin-right: auto;
  padding: 6px 8px;

  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 12px;

  color: ${theme.PRIMARY500};
  background-color: ${theme.PRIMARY200};
  border-radius: 4px;
`;
