import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import React, { useContext, useState, useEffect, useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { AppErrorText, AppText, FlexDiv, Loading, SkeletonBlock } from "../UI";
import { DeleteCallFolderPopup, SaveToFolderModal, ShareFolderModal, UpdateFolderNamePopup } from "../modal";

import { ModalContext } from "../../context";
import { arrow_left, arrow_right, minus, refresh } from "../../images/NewDesign";
import { appToast } from "../../utils/toast";
import { PhoenixAppButton, PhoenixDateRange, PhoenixIcon, PhoenixInput, PhoenixMultiSelect } from "../UI/Phoenix";
import { AllCallsTable, Folder } from "../Segments";
import { useDebounce } from "../../utils/hooks";
import { OptionItem, SortOption } from "../../types";
import {
  CALL_LIBRARY_CALL_TYPE_OPTIONS,
  CALL_LIBRARY_RECORDING_TRACK_OPTIONS,
  CALL_LIBRARY_TIME_FRAME_OPTIONS,
} from "../../utils/misc";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { isEqual } from "lodash";
import { personSpokenToOptions } from "src/utils/sequences";
import { MixpanelActions } from "src/services/mixpanel";

const FETCH_VIEWABLE_FOLDERS = gql`
  query fetchViewableFolders {
    fetchViewableFolders {
      id
      name
      size
      owner {
        id
        full_name
      }
      conferences {
        id
        salesperson
        created_at
        duration
        latest_call_result {
          id
          label
        }
        lead {
          id
          full_name
        }
        latest_activity {
          id
          user {
            id
            profile_image
          }
        }
      }
    }
  }
`;

const DELETE_FOLDER = gql`
  mutation removeFolder($folder_id: String!) {
    removeFolder(folder_id: $folder_id) {
      id
      name
    }
  }
`;

const DELETE_CALL = gql`
  mutation removeFolder($folder_id: String!, $conference_id: String!) {
    removeCallFromFolder(folder_id: $folder_id, conference_id: $conference_id) {
      id
      name
    }
  }
`;

const FETCH_REPS = gql`
  query fetchReps {
    fetchReps {
      id
      full_name
    }
  }
`;

const GET_CALL_RESULT_OPTIONS = gql`
  query getCallResultOptions {
    getCallResultOptions
  }
`;

const FETCH_CONFERENCES = gql`
  query fetchConferences(
    $userIds: [String!]!
    $skip: Int
    $take: Int
    $callResult: [DISPOSITIONTYPE!]!
    $topics: [String!]!
    $durationMin: Int
    $durationMax: Int
    $callType: [String!]!
    $teamIds: [String!]!
    $trackers: [String!]!
    $lowerboundDate: Date
    $upperboundDate: Date
    $sortBy: ConferencesSortOrder!
    $personSpokenTo: [CALLRESULT!]!
    $recordingTrack: [String!]!
  ) {
    fetchConferences(
      user_ids: $userIds
      skip: $skip
      take: $take
      call_result: $callResult
      topics: $topics
      call_type: $callType
      duration_min: $durationMin
      duration_max: $durationMax
      team_ids: $teamIds
      trackers: $trackers
      lowerbound_date: $lowerboundDate
      upperbound_date: $upperboundDate
      sort_by: $sortBy
      spoke_to: $personSpokenTo
      recording_track: $recordingTrack
    )
  }
`;

const FETCH_CONFERENCES_COUNT = gql`
  query fetchConferencesCount(
    $userIds: [String!]!
    $callType: [String!]!
    $callResult: [DISPOSITIONTYPE!]!
    $topics: [String!]!
    $durationMax: Int
    $durationMin: Int
    $teamIds: [String!]!
    $trackers: [String!]!
    $lowerboundDate: Date
    $upperboundDate: Date
    $personSpokenTo: [CALLRESULT!]!
    $recordingTrack: [String!]!
  ) {
    fetchConferencesCount(
      user_ids: $userIds
      call_type: $callType
      call_result: $callResult
      topics: $topics
      duration_max: $durationMax
      duration_min: $durationMin
      team_ids: $teamIds
      trackers: $trackers
      lowerbound_date: $lowerboundDate
      upperbound_date: $upperboundDate
      spoke_to: $personSpokenTo
      recording_track: $recordingTrack
    )
  }
`;

const FETCH_TEAMS = gql`
  query fetchTeams {
    fetchTeams {
      id
      name
    }
  }
`;

type Team = { id: string; name: string };
type Rep = { id: string; full_name: string };

interface IFilterState {
  selectedReps: OptionItem[];
  selectedTeams: OptionItem[];
  selectedPersonSpokenTo: OptionItem[];
  selectedCallTypes: OptionItem[];
  selectedCallResults: OptionItem[];
  selectedRecordingTracks: OptionItem[];
  duration: {
    min: {
      hr?: string;
      min?: string;
      sec?: string;
    };
    max: {
      hr?: string;
      min?: string;
      sec?: string;
    };
  };
  selectedTimeframe: { label: string; value: any };
}

const callLibraryDefaultState = {
  sortOrder: "descending",
  selectedFolder: { id: "", name: "", conferences: [], owner: {} as any },
  currentConferenceID: "",
  queryFolderID: "",
  currentFolderID: "",
  currentFolderName: "",
  folderDelete: true,
};

const filterDefaultState = {
  selectedReps: [],
  selectedTeams: [],
  selectedCallTypes: [],
  selectedCallResults: [],
  selectedPersonSpokenTo: [],
  selectedRecordingTracks: [],
  duration: {
    min: { hr: "", min: "", sec: "" },
    max: { hr: "", min: "", sec: "" },
  },
  selectedTimeframe: CALL_LIBRARY_TIME_FRAME_OPTIONS[3],
};

const CallLibraryPageV2: React.FC = () => {
  const [sortOrder, setSortOrder] = useState(callLibraryDefaultState.sortOrder);
  const [selectedFolder, setSelectedFolder] = useState(callLibraryDefaultState.selectedFolder);

  const [currentConferenceID, setCurrentConferenceID] = useState(callLibraryDefaultState.currentConferenceID);

  const [currentFolderID, setCurrentFolderID] = useState(callLibraryDefaultState.currentFolderID);
  const [currentFolderName, setCurrentFolderName] = useState(callLibraryDefaultState.currentFolderName);

  const [folderDelete, setFolderDelete] = useState(callLibraryDefaultState.folderDelete);

  const [selectedTab, setSelectedTab] = useState<"savedCalls" | "allCalls">("allCalls");

  //TODO: add type
  const [conferences, setConferences] = useState<any[]>([]);
  const [callsPerPage, setCallsPerPage] = useState(25);
  const [hasLoadedTable, setHasLoadedTable] = useState(false);
  const [page, setPage] = useState(1);
  const [moveCall, setMoveCall] = useState(false);

  // filters
  const [appliedFilters, setAppliedFilters] = useState<IFilterState>(filterDefaultState);
  const [selectedReps, setSelectedReps] = useState<OptionItem[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<OptionItem[]>([]);
  const [selectedTimeframe, setSelectedTimeframe] = useState<any>(CALL_LIBRARY_TIME_FRAME_OPTIONS[3]);
  const [selectedCallResults, setSelectedCallResults] = useState<OptionItem[]>([]);
  const [selectedCallTypes, setSelectedCallTypes] = useState<OptionItem[]>([]);
  const [duration, setDuration] = useState<{
    min: {
      hr?: string;
      min?: string;
      sec?: string;
    };
    max: {
      hr?: string;
      min?: string;
      sec?: string;
    };
  }>({
    min: { hr: "", min: "", sec: "" },
    max: { hr: "", min: "", sec: "" },
  });
  const [selectedPersonSpokenTo, setSelectedPersonSpokenTo] = useState<OptionItem[]>([]);
  const [selectedRecordingTracks, setSelectedRecordingTracks] = useState<OptionItem[]>([]);

  const [tableSort, setTableSort] = useState<SortOption>("created_at");
  const [tableSortOrder, setTableSortOrder] = useState<"asc" | "desc">("asc");
  const tableSortBy = useMemo(() => {
    return tableSort.toUpperCase() + "_" + tableSortOrder.toUpperCase();
  }, [tableSort, tableSortOrder]);

  const disabledResetFilters = useMemo(() => isEqual(appliedFilters, filterDefaultState), [appliedFilters]);

  const calculatedDurationMin = useMemo(() => {
    return (
      (Number(duration.min.hr) ?? 0) * 3600 + (Number(duration.min.min) ?? 0) * 60 + (Number(duration.min.sec) ?? 0)
    );
  }, [duration.min]);

  const calculatedDurationMax = useMemo(() => {
    return (
      (Number(duration.max.hr) ?? 0) * 3600 + (Number(duration.max.min) ?? 0) * 60 + (Number(duration.max.sec) ?? 0)
    );
  }, [duration.max]);

  const resetFilters = useCallback(() => {
    setSelectedReps([]);
    setSelectedTeams([]);
    setSelectedPersonSpokenTo([]);
    setSelectedTimeframe(CALL_LIBRARY_TIME_FRAME_OPTIONS[0]);
    setSelectedCallResults([]);
    setSelectedCallTypes([]);
    setDuration({ min: { hr: "", min: "", sec: "" }, max: { hr: "", min: "", sec: "" } });
    setSelectedRecordingTracks([]);
    setAppliedFilters(filterDefaultState);
  }, []);

  const filtersHaventChanged = useMemo(() => {
    // all properties in appliedFilters match their state counterparts
    return Object.keys(appliedFilters).every((key) => {
      const appliedStateValue = appliedFilters[key as keyof IFilterState];
      const componentStateValue = {
        selectedReps,
        selectedTeams,
        selectedPersonSpokenTo,
        selectedTimeframe,
        selectedCallResults,
        selectedCallTypes,
        selectedRecordingTracks,
        duration,
      }[key as keyof IFilterState];
      return isEqual(appliedStateValue, componentStateValue);
    });
  }, [
    appliedFilters,
    selectedReps,
    selectedTeams,
    selectedPersonSpokenTo,
    selectedTimeframe,
    selectedCallResults,
    selectedCallTypes,
    selectedRecordingTracks,
    duration,
  ]);

  const {
    deleteModal,
    setDeleteModal,
    folderUpdateModal,
    setFolderUpdateModal,
    shareFolderModal,
    setShareFolderModal,
    moveToAnotherFolderModal,
    setMoveToAnotherFolderModal,
  } = useContext(ModalContext);

  const { data: dataFolders, loading: loadingFolders, error: errorFolders } = useQuery(FETCH_VIEWABLE_FOLDERS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataReps, loading: loadingReps } = useQuery(FETCH_REPS, {
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const [fetchConferences, { loading: loadingConferences, called: calledFetchConferences }] = useLazyQuery(
    FETCH_CONFERENCES,
    {
      fetchPolicy: "network-only",
      onCompleted({ fetchConferences }) {
        !hasLoadedTable && setHasLoadedTable(true);
        setConferences(fetchConferences);
      },
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );
  const [fetchConferencesCount, { data: dataConferencesCount, loading: loadingConferencesCount }] = useLazyQuery(
    FETCH_CONFERENCES_COUNT,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: dataCallResultOptions, loading: loadingCallResultOptions } = useQuery(GET_CALL_RESULT_OPTIONS, {
    fetchPolicy: "cache-first",
  });

  const { data: dataTeams, loading: loadingTeams } = useQuery(FETCH_TEAMS, {
    fetchPolicy: "cache-and-network",
  });

  const [deleteOneFolder] = useMutation(DELETE_FOLDER, {
    onCompleted({ deleteOneFolder }) {
      appToast("Folder deleted successfully!");
      if (!deleteOneFolder) {
        return;
      }
    },
    onError({ message }) {
      toast(message);
      Sentry.captureEvent({
        message: `deleteOneFolder GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchViewableFolders", "fetchFolder"],
  });

  const [deleteOneCall] = useMutation(DELETE_CALL, {
    onCompleted({ deleteOneCall }) {
      appToast("Call removed successfully!");
      if (!deleteOneCall) {
        return;
      }
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `deleteOneCall GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchViewableFolders", "fetchFolder"],
  });

  useEffect(() => {
    if (selectedTab === "allCalls" && !calledFetchConferences) {
      fetchConferences({
        variables: {
          userIds: selectedReps?.map((rep) => rep.value),
          teamIds: selectedTeams?.map((team) => team.value),
          callType: selectedCallTypes?.map((type) => type.value),
          personSpokenTo: selectedPersonSpokenTo?.map((person) => person.value),
          recordingTrack: selectedRecordingTracks?.map((track) => track.value),
          callResult: selectedCallResults.flatMap((result) =>
            result.value === "ProspectDropped" ? ["ProspectDropped", "PropsectDropped"] : [result.value],
          ),
          topics: [],
          trackers: [],
          durationMin: calculatedDurationMin,
          durationMax: calculatedDurationMax,
          skip: 0,
          take: callsPerPage,
          lowerboundDate: selectedTimeframe?.value?.[0],
          upperboundDate: selectedTimeframe?.value?.[1],
          sortBy: tableSortBy,
        },
      });
      fetchConferencesCount({
        variables: {
          userIds: selectedReps?.map((rep) => rep.value),
          teamIds: selectedTeams?.map((team) => team.value),
          callType: selectedCallTypes?.map((type) => type.value),
          personSpokenTo: selectedPersonSpokenTo?.map((person) => person.value),
          recordingTrack: selectedRecordingTracks?.map((track) => track.value),
          callResult: selectedCallResults.flatMap((result) =>
            result.value === "ProspectDropped" ? ["ProspectDropped", "PropsectDropped"] : [result.value],
          ),
          topics: [],
          trackers: [],
          durationMin: calculatedDurationMin,
          durationMax: calculatedDurationMax,
          lowerboundDate: selectedTimeframe?.value?.[0],
          upperboundDate: selectedTimeframe?.value?.[1],
        },
      });
    }
  }, [selectedTab]);

  const pageCount = useMemo(() => Math.ceil(dataConferencesCount?.fetchConferencesCount / callsPerPage), [
    dataConferencesCount,
    callsPerPage,
  ]);

  useDebounce(
    async () => {
      if (!hasLoadedTable) return;

      await fetchConferences({
        variables: {
          userIds: selectedReps?.map((rep) => rep.value),
          teamIds: selectedTeams?.map((team) => team.value),
          callType: selectedCallTypes?.map((type) => type.value),
          personSpokenTo: selectedPersonSpokenTo?.map((person) => person.value),
          recordingTrack: selectedRecordingTracks?.map((track) => track.value),
          callResult: selectedCallResults.flatMap((result) =>
            result.value === "ProspectDropped" ? ["ProspectDropped", "PropsectDropped"] : [result.value],
          ),
          topics: [],
          trackers: [],
          skip: page === 1 ? 0 : (page - 1) * callsPerPage,
          take: callsPerPage,
          durationMin: calculatedDurationMin,
          durationMax: calculatedDurationMax,
          lowerboundDate: selectedTimeframe?.value?.[0],
          upperboundDate: selectedTimeframe?.value?.[1],
          sortBy: tableSortBy,
        },
      });
    },
    [page, callsPerPage],
    300,
  );

  const handleCallCountChange = useCallback(
    (val: number) => {
      setCallsPerPage(val);
      setPage(1);
    },
    [setCallsPerPage, setPage],
  );

  const handleRenameClick = useCallback(
    (item) => {
      setCurrentFolderName(item?.name);
      setCurrentFolderID(item?.id);
      setFolderUpdateModal(true);
    },
    [setCurrentFolderName, setCurrentFolderID, setFolderUpdateModal],
  );

  const handleDeleteClick = useCallback(
    (item, folder: boolean) => {
      if (folder) {
        setCurrentFolderID(item?.id);
        setFolderDelete(true);
        setDeleteModal(true);
      } else {
        setCurrentConferenceID(item?.id);
        setCurrentFolderID(selectedFolder?.id);
        setFolderDelete(false);
        setDeleteModal(true);
      }
    },
    [setCurrentFolderID, setFolderDelete, setDeleteModal, setCurrentConferenceID, selectedFolder],
  );

  const handleFilterApply = useCallback(async () => {
    if (!hasLoadedTable) return;

    MixpanelActions.track("Call Library Filters Applied", {
      time_frame: selectedTimeframe,
      rep_filter_set: selectedReps?.map((rep) => rep.value),
      team_filter_set: selectedTeams?.map((team) => team.value),
      person_spoken_to: selectedPersonSpokenTo?.map((person) => person.value),
      call_result: selectedCallResults.flatMap((result) =>
        result.value === "ProspectDropped" ? ["ProspectDropped", "PropsectDropped"] : [result.value],
      ),
      call_type: selectedCallTypes?.map((type) => type.value),
      recording_track: selectedRecordingTracks?.map((track) => track.value),
      duration_filter_set: Boolean(calculatedDurationMin + calculatedDurationMax),
    });

    await fetchConferences({
      variables: {
        userIds: selectedReps?.map((rep) => rep.value),
        teamIds: selectedTeams?.map((team) => team.value),
        callType: selectedCallTypes?.map((type) => type.value),
        callResult: selectedCallResults.flatMap((result) =>
          result.value === "ProspectDropped" ? ["ProspectDropped", "PropsectDropped"] : [result.value],
        ),
        recordingTrack: selectedRecordingTracks?.map((track) => track.value),
        personSpokenTo: selectedPersonSpokenTo?.map((person) => person.value),
        topics: [],
        trackers: [],
        durationMin: calculatedDurationMin,
        durationMax: calculatedDurationMax,
        skip: 0,
        take: callsPerPage,
        lowerboundDate: selectedTimeframe?.value?.[0],
        upperboundDate: selectedTimeframe?.value?.[1],
        sortBy: tableSortBy,
      },
    });

    await fetchConferencesCount({
      variables: {
        userIds: selectedReps?.map((rep) => rep.value),
        teamIds: selectedTeams?.map((team) => team.value),
        callType: selectedCallTypes?.map((type) => type.value),
        callResult: selectedCallResults.flatMap((result) =>
          result.value === "ProspectDropped" ? ["ProspectDropped", "PropsectDropped"] : [result.value],
        ),
        recordingTrack: selectedRecordingTracks?.map((track) => track.value),
        personSpokenTo: selectedPersonSpokenTo?.map((person) => person.value),
        topics: [],
        trackers: [],
        durationMin: calculatedDurationMin,
        durationMax: calculatedDurationMax,
        lowerbound_date: selectedTimeframe?.value?.[0],
        upperbound_date: selectedTimeframe?.value?.[1],
      },
    });

    setPage(1);
    setHasLoadedTable(false);
  }, [
    hasLoadedTable,
    selectedReps,
    selectedTeams,
    selectedCallTypes,
    selectedCallResults,
    duration,
    fetchConferences,
    fetchConferencesCount,
    callsPerPage,
    selectedTimeframe,
    selectedPersonSpokenTo,
    selectedRecordingTracks,
  ]);

  if (loadingFolders) return <Loading />;
  if (errorFolders) return <AppErrorText>Error loading Call Library information.</AppErrorText>;

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured at the Call Library page"}>
      <Main>
        <PhoenixStyledTooltip id="call-library-tooltip" />

        <Header>
          <AppText fontSize={22} fontWeight={500} lineHeight={28}>
            Call Library
          </AppText>

          <PhoenixAppButton
            buttonType="secondary"
            variant="brand"
            onClick={() => {
              setCurrentFolderID("");
              setCurrentFolderName("");
              setFolderUpdateModal(true);
            }}
          >
            <AppText fontSize={10} fontWeight={600} letterSpacing={1} uppercase color={theme.buttontext.brand.default}>
              New Folder
            </AppText>
          </PhoenixAppButton>
        </Header>

        <HeaderNav>
          <HeaderNavItemFill width={"24px"} />
          <HeaderNavItem selected={selectedTab === "allCalls"} onClick={() => setSelectedTab("allCalls")}>
            <AppText
              fontSize={10}
              fontWeight={600}
              lineHeight={16}
              color={selectedTab === "allCalls" ? theme.text.neutral.primary : theme.text.neutral.secondary}
              letterSpacing={1}
              uppercase
              noWrap
            >
              All Calls
            </AppText>
          </HeaderNavItem>
          <HeaderNavItem selected={selectedTab === "savedCalls"} onClick={() => setSelectedTab("savedCalls")}>
            <AppText
              fontSize={10}
              fontWeight={600}
              lineHeight={16}
              color={selectedTab === "savedCalls" ? theme.text.neutral.primary : theme.text.neutral.secondary}
              letterSpacing={1}
              uppercase
              noWrap
            >
              Saved Calls
            </AppText>
          </HeaderNavItem>
          <HeaderNavItemFill />
        </HeaderNav>

        <BodyWrap>
          <Body selectedTab={selectedTab} callCount={conferences.length}>
            {selectedTab === "savedCalls" && !dataFolders.fetchViewableFolders.length && !loadingFolders && (
              <NoResultsContainer
                direction="column"
                align="center"
                gap={8}
                style={{ width: "387px", textAlign: "center", paddingTop: "24px" }}
              >
                <AppText fontSize={16} fontWeight={400} lineHeight={22}>
                  No folders saved.
                </AppText>
                <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.text.neutral.secondary}>
                  Click 'New Folder' to get started.
                </AppText>
              </NoResultsContainer>
            )}
            {selectedTab === "savedCalls" ? (
              dataFolders.fetchViewableFolders
                .slice()
                .sort((a: any, b: any) =>
                  sortOrder === "ascending"
                    ? b.name.toLowerCase().localeCompare(a.name.toLowerCase())
                    : a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
                )
                ?.map((item: any) => (
                  <FolderWrapper>
                    <Folder
                      key={item.id}
                      data={item}
                      handleRenameClick={handleRenameClick}
                      handleDeleteClick={handleDeleteClick}
                      setMoveCall={setMoveCall}
                      setCurrentConferenceID={setCurrentConferenceID}
                      setSelectedFolder={setSelectedFolder}
                    />
                  </FolderWrapper>
                ))
            ) : loadingConferences && !hasLoadedTable ? (
              <Loading />
            ) : !conferences.length && !loadingConferences ? (
              <NoResultsContainer
                direction="column"
                align="center"
                gap={8}
                style={{ width: "387px", textAlign: "center", paddingTop: "24px" }}
              >
                <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.text.neutral.secondary}>
                  No search results
                </AppText>
                <AppText fontSize={16} fontWeight={400} lineHeight={22}>
                  Your search criteria returned zero results. Reset your filters or start a new search.
                </AppText>
              </NoResultsContainer>
            ) : (
              <>
                <div style={{ position: "relative", height: "100vh", overflowY: "auto" }}>
                  {loadingConferences ? (
                    <div style={{ width: "1360px", paddingTop: "128px" }}>
                      <Loading />
                    </div>
                  ) : (
                    <AllCallsTable
                      data={conferences}
                      setCurrentConferenceID={setCurrentConferenceID}
                      handleSort={async (sortBy: string) => {
                        await fetchConferences({
                          variables: {
                            userIds: selectedReps?.map((rep) => rep.value),
                            teamIds: selectedTeams?.map((team) => team.value),
                            callType: selectedCallTypes?.map((type) => type.value),
                            callResult: selectedCallResults.flatMap((result) =>
                              result.value === "ProspectDropped"
                                ? ["ProspectDropped", "PropsectDropped"]
                                : [result.value],
                            ),
                            recordingTrack: selectedRecordingTracks?.map((track) => track.value),
                            personSpokenTo: selectedPersonSpokenTo?.map((person) => person.value),
                            topics: [],
                            trackers: [],
                            durationMin: calculatedDurationMin,
                            durationMax: calculatedDurationMax,
                            skip: 0,
                            take: callsPerPage,
                            lowerboundDate: selectedTimeframe?.value?.[0],
                            upperboundDate: selectedTimeframe?.value?.[1],
                            sortBy: sortBy,
                          },
                        });
                        setPage(1);
                      }}
                      sortOrder={tableSortOrder}
                      setSortOrder={setTableSortOrder}
                      tableSort={tableSort}
                      setTableSort={setTableSort}
                    />
                  )}
                </div>
                <TableFooter>
                  <FlexDiv align="center" gap={16}>
                    <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                      Calls Per Page
                    </AppText>

                    <FlexDiv align="center" gap={8}>
                      <CallCountText selected={callsPerPage === 25} onClick={() => handleCallCountChange(25)}>
                        25
                      </CallCountText>
                      <CallCountText selected={callsPerPage === 50} onClick={() => handleCallCountChange(50)}>
                        50
                      </CallCountText>
                      <CallCountText selected={callsPerPage === 75} onClick={() => handleCallCountChange(75)}>
                        75
                      </CallCountText>
                      <CallCountText selected={callsPerPage === 100} onClick={() => handleCallCountChange(100)}>
                        100
                      </CallCountText>
                    </FlexDiv>
                  </FlexDiv>

                  <FlexDiv align="center" gap={16}>
                    <AppText fontSize={12} fontWeight={400}>
                      Page {page} of {!loadingConferencesCount && pageCount}
                    </AppText>
                    {loadingConferencesCount && <SkeletonBlock borderRadius={4} width={32} height={16} />}
                    <FlexDiv align="center" gap={8}>
                      <PhoenixIcon
                        svg={arrow_left}
                        size={24}
                        variant={page === 1 ? "neutral" : "brand"}
                        pointer={page !== 1}
                        onClick={() => setPage((prev) => Math.max(1, prev - 1))}
                      />
                      <PhoenixIcon
                        svg={arrow_right}
                        size={24}
                        variant={conferences.length < callsPerPage || pageCount <= page ? "neutral" : "brand"}
                        pointer={conferences.length === callsPerPage}
                        onClick={() =>
                          conferences.length === callsPerPage && setPage((prev) => Math.min(pageCount || 100, prev + 1))
                        }
                      />
                    </FlexDiv>
                  </FlexDiv>
                </TableFooter>
              </>
            )}
          </Body>

          {selectedTab === "allCalls" && (
            <FlexDiv direction="column">
              <FilterContainer>
                <FlexDiv justify="space-between" align="center" style={{ marginBottom: "8px" }}>
                  <AppText
                    fontSize={10}
                    fontWeight={600}
                    lineHeight={16}
                    letterSpacing={1}
                    uppercase
                    color={theme.text.neutral.secondary}
                  >
                    Filter
                  </AppText>

                  <FlexDiv align="center" gap={16}>
                    <FlexDiv
                      align="center"
                      gap={4}
                      style={{ cursor: disabledResetFilters ? "default" : "pointer" }}
                      onClick={async () => {
                        if (disabledResetFilters) return;

                        resetFilters();

                        await fetchConferences({
                          variables: {
                            userIds: [],
                            teamIds: [],
                            callType: [],
                            callResult: [],
                            personSpokenTo: [],
                            recordingTrack: [],
                            topics: [],
                            trackers: [],
                            durationMin: null,
                            durationMax: null,
                            skip: 0,
                            take: callsPerPage,
                            sortBy: tableSortBy,
                          },
                        });

                        await fetchConferencesCount({
                          variables: {
                            userIds: [],
                            teamIds: [],
                            callType: [],
                            callResult: [],
                            personSpokenTo: [],
                            recordingTrack: [],
                            topics: [],
                            trackers: [],
                            durationMin: null,
                            durationMax: null,
                          },
                        });

                        setPage(1);
                        setHasLoadedTable(false);
                      }}
                    >
                      <PhoenixIcon
                        svg={refresh}
                        size={12}
                        pointer={!disabledResetFilters}
                        variant={disabledResetFilters ? "neutral" : "brand"}
                        hoverColor={disabledResetFilters ? theme.icon.neutral.default : theme.icon.brand.default}
                      />
                      <AppText
                        fontSize={10}
                        fontWeight={600}
                        lineHeight={14}
                        color={disabledResetFilters ? theme.text.neutral.secondary : theme.text.brand.primary}
                      >
                        Reset Filters
                      </AppText>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>

                <FlexDiv direction="column" gap={8}>
                  <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                    Time Frame
                  </AppText>
                  <PhoenixMultiSelect
                    name="time_frame"
                    width={352}
                    options={CALL_LIBRARY_TIME_FRAME_OPTIONS}
                    placeholder="Filter by Timeframe"
                    value={selectedTimeframe}
                    onChange={(e: { label: string; value: [any, any] | null }) => setSelectedTimeframe(e)}
                    isMulti={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    marginBottom={false}
                  />
                  {selectedTimeframe.label === "Custom Time Frame" && (
                    <PhoenixDateRange
                      lowerbound_date={selectedTimeframe?.value?.[0] || null}
                      upperbound_date={selectedTimeframe?.value?.[1] || null}
                      onDateChange={({ startDate, endDate }) =>
                        setSelectedTimeframe({
                          label: "Custom Time Frame",
                          value: [startDate?.utc()?.startOf("day"), endDate?.utc()?.endOf("day")],
                        })
                      }
                    />
                  )}
                </FlexDiv>

                <FlexDiv direction="column" gap={8}>
                  <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                    Rep
                  </AppText>
                  <PhoenixMultiSelect
                    name="rep"
                    width={352}
                    maxHeight={120}
                    maxMenuHeight={240}
                    options={
                      loadingReps
                        ? [{ label: "Loading...", value: "", disabled: true }]
                        : dataReps?.fetchReps
                            ?.slice()
                            ?.sort((a: Rep, b: Rep) => a.full_name.localeCompare(b.full_name))
                            ?.map((rep: Rep) => ({
                              label: rep.full_name,
                              value: rep.id,
                            }))
                    }
                    isOptionDisabled={(option: any) => option.disabled}
                    showCheckbox={true}
                    placeholder="Filter by Reps"
                    value={selectedReps}
                    onChange={(e: OptionItem[]) => setSelectedReps(e)}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    marginBottom={false}
                  />
                </FlexDiv>
                <FlexDiv direction="column" gap={8}>
                  <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                    Team
                  </AppText>
                  <PhoenixMultiSelect
                    name="team"
                    width={352}
                    maxHeight={120}
                    maxMenuHeight={200}
                    options={
                      loadingTeams
                        ? [{ label: "Loading...", value: "", disabled: true }]
                        : dataTeams?.fetchTeams
                            ?.slice()
                            ?.sort((a: Team, b: Team) => a.name?.localeCompare(b.name))
                            ?.map((team: Team) => ({
                              label: team.name,
                              value: team.id,
                            }))
                    }
                    isOptionDisabled={(option: any) => option.disabled}
                    showCheckbox={true}
                    placeholder="Filter by Teams"
                    value={selectedTeams}
                    onChange={(e: OptionItem[]) => setSelectedTeams(e)}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    marginBottom={false}
                  />
                </FlexDiv>

                <FlexDiv direction="column" gap={8}>
                  <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                    Person Spoken To
                  </AppText>
                  <PhoenixMultiSelect
                    name="person_spoken_to"
                    width={352}
                    maxHeight={120}
                    options={personSpokenToOptions}
                    placeholder="Filter by Person Spoken To"
                    value={selectedPersonSpokenTo}
                    onChange={(e: OptionItem[]) => setSelectedPersonSpokenTo(e)}
                    hideSelectedOptions={false}
                    isClearable={false}
                    marginBottom={false}
                    closeMenuOnSelect={false}
                    showCheckbox={true}
                  />
                </FlexDiv>

                <FlexDiv direction="column" gap={8}>
                  <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                    Call Result
                  </AppText>
                  <PhoenixMultiSelect
                    name="call_result"
                    width={352}
                    maxHeight={120}
                    options={
                      loadingCallResultOptions
                        ? [{ label: "Loading...", value: "", disabled: true }]
                        : dataCallResultOptions?.getCallResultOptions
                            ?.slice()
                            ?.filter((cr: OptionItem) => cr.value !== "PropsectDropped")
                    }
                    isOptionDisabled={(option: any) => option.disabled}
                    placeholder="Filter by Call Result"
                    value={selectedCallResults}
                    onChange={(e: OptionItem[]) => setSelectedCallResults(e)}
                    hideSelectedOptions={false}
                    isClearable={false}
                    marginBottom={false}
                    closeMenuOnSelect={false}
                    showCheckbox={true}
                  />
                </FlexDiv>
                <FlexDiv direction="column" gap={8}>
                  <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                    Call Type
                  </AppText>
                  <PhoenixMultiSelect
                    name="call_type"
                    width={352}
                    maxHeight={120}
                    options={CALL_LIBRARY_CALL_TYPE_OPTIONS}
                    placeholder="Filter by Call Type"
                    value={selectedCallTypes}
                    onChange={(e: OptionItem[]) => setSelectedCallTypes(e)}
                    hideSelectedOptions={false}
                    isClearable={false}
                    marginBottom={false}
                    closeMenuOnSelect={false}
                    showCheckbox={true}
                  />
                </FlexDiv>
                <FlexDiv direction="column" gap={8}>
                  <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                    Recording Track
                  </AppText>
                  <PhoenixMultiSelect
                    name="recording_track"
                    width={352}
                    maxHeight={120}
                    options={CALL_LIBRARY_RECORDING_TRACK_OPTIONS}
                    placeholder="Filter by Recording Track"
                    value={selectedRecordingTracks}
                    onChange={(e: OptionItem[]) => setSelectedRecordingTracks(e)}
                    hideSelectedOptions={false}
                    isClearable={false}
                    marginBottom={false}
                    closeMenuOnSelect={false}
                    showCheckbox={true}
                  />
                </FlexDiv>
                <FlexDiv direction="column" gap={8}>
                  <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                    Duration
                  </AppText>
                  <FlexDiv align="center" gap={16}>
                    <FlexDiv align="center" gap={4}>
                      <DurationInput
                        name="min_duration_hr"
                        value={duration.min.hr}
                        onChange={(e: string) => {
                          setDuration({
                            ...duration,
                            min: { ...duration.min, hr: e },
                          });
                        }}
                        label="Hr"
                      />
                      <AppText style={{ paddingBottom: "16px" }}>:</AppText>
                      <DurationInput
                        name="min_duration_min"
                        value={duration.min.min}
                        onChange={(e: string) => {
                          setDuration({
                            ...duration,
                            min: { ...duration.min, min: e },
                          });
                        }}
                        label="Min"
                      />
                      <AppText style={{ paddingBottom: "16px" }}>:</AppText>
                      <DurationInput
                        name="min_duration_sec"
                        value={duration.min.sec}
                        onChange={(e: string) => {
                          setDuration({
                            ...duration,
                            min: { ...duration.min, sec: e },
                          });
                        }}
                        label="Sec"
                      />
                    </FlexDiv>
                    <AppText style={{ paddingBottom: "16px" }}>to</AppText>
                    <FlexDiv align="center" gap={4}>
                      <DurationInput
                        name="max_duration_hr"
                        value={duration.max.hr}
                        onChange={(e: string) => {
                          setDuration({
                            ...duration,
                            max: { ...duration.max, hr: e },
                          });
                        }}
                        label="Hr"
                      />
                      <AppText style={{ paddingBottom: "16px" }}>:</AppText>
                      <DurationInput
                        name="max_duration_min"
                        value={duration.max.min}
                        onChange={(e: string) => {
                          setDuration({
                            ...duration,
                            max: { ...duration.max, min: e },
                          });
                        }}
                        label="Min"
                      />
                      <AppText style={{ paddingBottom: "16px" }}>:</AppText>
                      <DurationInput
                        name="max_duration_sec"
                        value={duration.max.sec}
                        onChange={(e: string) => {
                          setDuration({
                            ...duration,
                            max: { ...duration.max, sec: e },
                          });
                        }}
                        label="Sec"
                      />
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </FilterContainer>

              <FilterFooter>
                <PhoenixAppButton
                  variant="brand"
                  buttonType="secondary"
                  onClick={() => {
                    if (filtersHaventChanged) return;
                    setAppliedFilters({
                      selectedReps,
                      selectedTeams,
                      selectedCallTypes,
                      selectedCallResults,
                      selectedRecordingTracks,
                      duration,
                      selectedTimeframe,
                      selectedPersonSpokenTo,
                    });
                    handleFilterApply();
                  }}
                >
                  Apply Filters
                </PhoenixAppButton>
              </FilterFooter>
            </FlexDiv>
          )}
        </BodyWrap>
      </Main>

      <DeleteCallFolderPopup
        blinds={deleteModal}
        setBlinds={setDeleteModal}
        deleteFolder={folderDelete}
        onDeleteFolder={async () =>
          await deleteOneFolder({
            variables: {
              folder_id: currentFolderID,
            },
          })
        }
        onDeleteCall={async () =>
          await deleteOneCall({
            variables: {
              folder_id: currentFolderID,
              conference_id: currentConferenceID,
            },
          })
        }
      />
      <UpdateFolderNamePopup
        blinds={folderUpdateModal}
        setBlinds={setFolderUpdateModal}
        folderID={currentFolderID}
        nameText={currentFolderName}
      />
      <ShareFolderModal blinds={shareFolderModal} setBlinds={setShareFolderModal} folderID={currentFolderID} />
      {moveToAnotherFolderModal && (
        <SaveToFolderModal
          blinds={moveToAnotherFolderModal}
          setBlinds={setMoveToAnotherFolderModal}
          conferenceID={currentConferenceID}
          moveCall={moveCall}
          moveSrcFolder={selectedFolder.id}
        />
      )}
    </Sentry.ErrorBoundary>
  );
};

const Main = styled.div`
  margin: 24px;

  min-width: fit-content;
  height: 92vh;

  overflow: hidden;
  border: 16px;
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 24px 40px;

  background-color: ${theme.WHITE_COLOR};
`;

const HeaderNav = styled.div`
  display: flex;
`;

const HeaderNavItemFill = styled.div<{ width?: string }>`
  background-color: ${theme.WHITE_COLOR};
  border-bottom: 1px solid ${theme.border.neutral.primary};

  width: ${(props) => props.width ?? "100%"};
  height: 32px;
`;

const HeaderNavItem = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;

  height: 32px;
  padding: 8px 16px;

  background-color: ${theme.WHITE_COLOR};
  border-bottom: ${(props) => (props.selected ? "2px" : "1px")} solid
    ${(props) => (props.selected ? theme.border.brand.primary : theme.border.neutral.primary)};

  cursor: pointer;

  transition: border-bottom 0.1s ease-in-out;

  & > p {
    transition: color 0.1s ease-in-out;
  }
`;

const BodyWrap = styled.div`
  display: flex;
  width: 100%;
  height: 80vh;
`;

const Body = styled.div<{ selectedTab: "savedCalls" | "allCalls"; callCount: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => (props.selectedTab === "savedCalls" ? "24px" : "0px")};

  width: 100%;
  min-width: fit-content;
  height: 100%;
  padding: ${(props) => (props.selectedTab === "savedCalls" ? "24px 24px 158px 24px" : "0px")};

  overflow-y: auto;

  background-color: ${theme.surface.brand.secondary};
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 400px;
  height: 80vh;
  padding: 24px 24px 32px 24px;
  border-left: 1px solid ${theme.border.neutral.secondary};
  background-color: ${theme.fill.neutral.primary};
  overflow-y: auto;
`;

const TableFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  min-height: 52px;
  padding: 32px 24px 48px 24px;

  border-top: 1px solid ${theme.border.neutral.secondary};
  background-color: ${theme.surface.brand.secondary};
`;

const FilterFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 104px;
  padding: 16px 0px 40px 0px;

  border-top: 1px solid ${theme.border.neutral.secondary};
  border-left: 1px solid ${theme.border.neutral.secondary};
  background-color: ${theme.fill.neutral.primary};
`;

const CallCountText = styled(AppText)<{ selected: boolean }>`
  font-size: 12px;
  font-weight: ${(props) => (props.selected ? 600 : 400)};

  color: ${(props) => (props.selected ? theme.text.brand.primary : theme.text.neutral.primary)};
  line-height: 18px;

  cursor: pointer;
  border-bottom: none;

  &:not(:last-child) {
    padding-right: 8px;
    border-right: 1px solid ${theme.border.neutral.primary};
  }

  transition: font-weight 0.2s ease, color 0.2s ease;
`;

const NoResultsContainer = styled(FlexDiv)`
  animation: ${theme.fadeIn} 0.3s ease;
`;

const FolderWrapper = styled.div`
  position: relative;
  width: 75%;
  max-width: 1024px;
  min-width: 800px;
`;

interface DurationInputProps {
  name: string;
  value?: string;
  onChange: (e: string) => void;
  label: string;
}

const DurationInput: React.FC<DurationInputProps> = ({ name, value, onChange, label }) => {
  return (
    <FlexDiv direction="column" gap={4}>
      <PhoenixInput
        name={name}
        variant="primary"
        width={44}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          let newVal = e.target.value;

          if (isNaN(Number(e.target.value))) return;

          if (newVal.length > 2) {
            newVal = "99";
          }

          onChange(newVal);
        }}
        displayNoContextText={true}
        placeholder="00"
        textAlign="center"
        style={{ padding: "0px 8px 0px 8px" }}
      />

      <AppText fontSize={10} fontWeight={500} lineHeight={14} color={theme.text.neutral.secondary}>
        {label}
      </AppText>
    </FlexDiv>
  );
};

export { CallLibraryPageV2 };
