import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { useState, useContext, useEffect, useRef } from "react";
import { AppButton, AppText, Loading, AppSidebarCard, AppErrorText, AppInput } from "../../UI";
import { AppTimePicker } from "../../UI/AppTimePicker";
import { AppCheckbox } from "../../UI/AppCheckbox";
import { NewAppButton } from "../../UI/NewAppButton";
import { theme } from "../../../utils/theme";
import { useMutation, useQuery, gql, useLazyQuery } from "@apollo/client";
import { Link, useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { appToast } from "../../../utils/toast";
import {
  iconArrowBackNew,
  iconNewMoveRow,
  newCloseModal,
  searchIcon,
  iconButtonArrowGray,
  iconRefreshSmall,
  tempIconUpDown,
} from "../../../images";
import { Formik, FormikProps, FieldArray } from "formik";
import * as Yup from "yup";
import { FormSelectField, InputField, TimeField } from "../../Field";
import { loggedInUser } from "../../../apollo/cache";
import moment from "moment";
import Switch from "react-switch";
import { OutsideEvent } from "../../Dumb";
import { formatTypeName } from "../../../utils/format";
import { StyledTooltip } from "../../Dumb";
import { DeleteRoutingRuleModal } from "../../modal";
import CreatableSelect from "react-select/creatable";
import { ReactDatesWrapper } from "../../UI/ReactDatesWrapper";
import { SingleDatePicker } from "react-dates";
import { TIME_FORMAT } from "../../../utils/format";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { sanitizeURL } from "../../../utils/misc";

interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
  togglePageTitle?: () => void;
  rule_id?: string;
}

const FETCH_ORG_RULES = gql`
  query fetchRules {
    fetchRules {
      id
      priority
      name
      updated_at
      active
      distribution_method
      rep_selection_method
      deferral_date_time
      conditions {
        id
        field
        operator
        value
        type
      }
      organization {
        id
        send_lead_routing_email
        lead_routing_email_list
      }
      routings {
        rep_id
        rule_id
      }
    }
  }
`;

const FETCH_ONE_ROUTING_RULE = gql`
  query FetchOneLeadRoutingRule($rule_id: String!) {
    fetchOneLeadRoutingRule(rule_id: $rule_id) {
      id
      updated_at
      name
      priority
      active
      rule_type
      rank_sort_type
      date_range
      entity
      metric
      deferral_date_time
      rep_priority_rule
      lead_creation_status
      distribution_method
      rep_selection_method
      los_rules
      entity_type
      conditions {
        id
        field
        operator
        value
        type
      }
      organization {
        id
        send_lead_routing_email
        lead_routing_email_list
      }
      routings {
        rep_id
      }
    }
  }
`;

const REMOVE_ROUTING_RULE = gql`
  mutation removeRoutingRule($rule_id: String!) {
    removeRoutingRule(rule_id: $rule_id)
  }
`;

const UPDATE_RULE_ORDER = gql`
  mutation updateRuleOrder($rule_ids: [String!]!) {
    updateRuleOrder(rule_ids: $rule_ids)
  }
`;

const FETCH_AVAILABLE_CONDITION_OPTIONS = gql`
  query fetchAvaliableConditionOption {
    fetchAvaliableConditionOption
  }
`;

const FETCH_REP_ORDER = gql`
  query fetchRepOrder($user_ids: [String!]!) {
    fetchRepOrder(user_ids: $user_ids) {
      id
      full_name
    }
  }
`;

const UPDATE_ROUTING_RULE_STATUS = gql`
  mutation updateRoutingRuleStatus($rule_id: String!, $active: Boolean!) {
    updateRoutingRuleStatus(rule_id: $rule_id, active: $active) {
      active
      name
      priority
    }
  }
`;

const CREATE_OR_UPDATE_ROUTING_RULE = gql`
  mutation createOrUpdateLeadRoutingRule(
    $rule_id: String
    $name: String!
    $conditions: [RoutingCondition!]!
    $rep_ids: [String!]!
    $distribution_method: DISTRIBUTION_METHOD!
    $rep_selection_method: REP_SELECTION_METHOD!
    $deferral_date_time: DateTime
    $los_rules: [LOS!]!
    $lead_creation_status: LeadCreationStatus
  ) {
    createOrUpdateLeadRoutingRule(
      rule_id: $rule_id
      name: $name
      conditions: $conditions
      rep_ids: $rep_ids
      distribution_method: $distribution_method
      rep_selection_method: $rep_selection_method
      deferral_date_time: $deferral_date_time
      los_rules: $los_rules
      lead_creation_status: $lead_creation_status
    ) {
      id
      priority
      name
      updated_at
      active
      distribution_method
      rep_selection_method
      deferral_date_time
      los_rules
      lead_creation_status
      conditions {
        field
        operator
        value
        type
      }
      organization {
        id
        send_lead_routing_email
        lead_routing_email_list
      }
      routings {
        rep_id
      }
    }
  }
`;

const FETCH_ORGANIZATION = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      Reps {
        id
        full_name
        id
        first_name
        last_name
        revenue_per_day
        email
      }
    }
  }
`;

const SET_LEAD_ROUTING_EMAIL_TOGGLE = gql`
  mutation setLeadRoutingEmailToggle($send_lead_routing_email: Boolean!) {
    setLeadRoutingEmailToggle(send_lead_routing_email: $send_lead_routing_email) {
      send_lead_routing_email
      id
    }
  }
`;

const SET_LEAD_ROUTING_EMAIL_LIST = gql`
  mutation setLeadRoutingEmailList($lead_routing_email_list: [String!]!) {
    setLeadRoutingEmailList(lead_routing_email_list: $lead_routing_email_list) {
      lead_routing_email_list
      id
    }
  }
`;

interface ICondition {
  field: string;
  operator: string;
  value: string;
  type: string;
}

interface IRouting {
  rep: string;
  rule: string;
  rout_count: number;
  rank: number;
}

enum IDistributionMethod {
  RoundRobin = "RoundRobin",
  Ranking = "Ranking",
}
enum IRepSelectionMethod {
  FirstRep = "FirstRep",
  BestRep = "BestRep",
}

interface MyFormikProps {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  priority: number;
  active: boolean;
  distribution_method: string;
  rep_selection_method: string;
  conditions: ICondition[];
  // routings: IRouting[];
  rep_ids: string[];
  emails_list: string[];
  email_updates: boolean;
  deferral_date_time: string | null;
  lead_creation_status: string;
  los_rules: string[];
  available_fields_search: string;
}

const testForArray = (value: string) => {
  try {
    const array = JSON.parse(value);
    if (Array.isArray(array) && array.length > 1) {
      return true;
    } else return false;
  } catch (error: any) {
    return false;
  }
};

const testForArrayWithoutLen = (value: string) => {
  try {
    const array = JSON.parse(value);
    if (Array.isArray(array)) {
      return true;
    } else return false;
  } catch (error: any) {
    return false;
  }
};

const testIfFieldsAreUnique = (array: any[]) => {
  const arrayFields = array?.map((item: any) => item.field);
  const uniqueFields = [...new Set(arrayFields)];

  return arrayFields.length === uniqueFields.length;
};

const yupRoutingRules = Yup.object().shape({
  id: Yup.string().notRequired(),
  created_at: Yup.string().notRequired(),
  updated_at: Yup.string().notRequired(),
  name: Yup.string().notRequired(),
  priority: Yup.number().notRequired(),
  active: Yup.boolean().notRequired(),
  distribution_method: Yup.string().notRequired(),
  rep_selection_method: Yup.string().notRequired(),
  conditions: Yup.array()
    .test("unique fields", "Each field can only be used once", (value) => testIfFieldsAreUnique(value ?? []))
    .of(
      Yup.object().shape({
        field: Yup.string()
          .required("Field is required")
          .test("Field is required", "Field is required", (value) => value !== "0"),
        operator: Yup.string()
          .required("Operator is required")
          .test("Operator is required", "Operator is required", (value) => value !== "0" && value !== ""),
        value: Yup.string()
          .when("operator", {
            is: (value) => value === "In" || value === "NotIn",
            then: Yup.string().test("value", "Requires multiple values", (value) => testForArray(value ?? "")),
          })
          .when("operator", {
            is: (value) => value !== "IsNull" || value !== "NotNull",
            then: Yup.string().test("value", "Value is required", (value) => value !== ""),
          }),
        type: Yup.string(),
      }),
    ),
  // routings: Yup.string().notRequired(),
  rep_ids: Yup.array().of(Yup.string()),
  emails_list: Yup.array().of(Yup.string().email("Email addresses must be valid")),
  email_updates: Yup.boolean(),
});

// ' The "In" and "Not In" operators can only be used when checking for multiple values. To specify multiple values, wrap the options with squared brackets, write each option inside double quotes, and separate the options with commas. For example: ["option 1", "option 2"]'

const optionsOperators = [
  { label: "In", value: "In" },
  { label: "Not In", value: "NotIn" },
  { label: "Is", value: "Is" },
  { label: "Not", value: "Not" },
  { label: "Contain", value: "Contain" },
  { label: "Not Contain", value: "NotContain" },
  { label: "Greater Than", value: "GreaterThan" },
  { label: "Less Than", value: "LessThan" },
  { label: "Equal", value: "Equal" },
  { label: "Not Equal", value: "NotEqual" },
  { label: "Is Null", value: "IsNull" },
  { label: "Not Null", value: "NotNull" },
];

const arraysAreEqual = (a: string[], b: string[]) => {
  const aSorted = a.slice().sort();
  const bSorted = b.slice().sort();
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    aSorted.every((val, index) => val === bSorted[index])
  );
};

const LEAD_STATUSES = [
  { title: "Unassigned", value: "Unassigned" },
  { title: "Assigned", value: "Assigned" },
  { title: "Owned", value: "Owned" },
  { title: "Resting", value: "Resting" },
  { title: "Retired", value: "Retired" },
  { title: "Customer", value: "Customer" },
];

const StepRoutingRules: React.FC<DisappearingDivProps> = ({
  step,
  blinds,
  setBlinds,
  isOnboarding,
  togglePageTitle,
  rule_id,
}) => {
  const history = useHistory();

  const formikRef = useRef() as any;

  const [isClicked, setIsClicked] = useState(false);
  const [sortAlphabetically, setSortAlphabetically] = useState(true);

  const [deleteModal, setDeleteModal] = useState(false);
  const [focused, setFocused] = useState(null as any);

  const { data: dataRules, loading: loadingRules, error: errorRules, refetch: refetchRules } = useQuery(
    FETCH_ORG_RULES,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
    // {
    //   fetchPolicy: "no-cache",
    // },
  );

  const {
    data: dataSelectedRule,
    loading: loadingSelectedRule,
    error: errorSelectedRule,
    refetch: refetchSelectedRule,
  } = useQuery(
    FETCH_ONE_ROUTING_RULE,
    {
      fetchPolicy: "network-only",
      skip: !rule_id || (!!rule_id && rule_id === "new"),
      variables: { rule_id: rule_id },
      onCompleted({ fetchOneLeadRoutingRule }) {
        if (!fetchOneLeadRoutingRule) {
          return;
        }
        setSelectedRule({
          id: fetchOneLeadRoutingRule?.id,
          created_at: fetchOneLeadRoutingRule?.created_at,
          updated_at: fetchOneLeadRoutingRule?.updated_at,
          name: fetchOneLeadRoutingRule?.name,
          priority: fetchOneLeadRoutingRule?.priority,
          active: fetchOneLeadRoutingRule?.active,
          distribution_method: fetchOneLeadRoutingRule?.distribution_method,
          rep_selection_method: fetchOneLeadRoutingRule?.rep_selection_method,
          conditions: fetchOneLeadRoutingRule?.conditions?.map((el: ICondition) => ({
            field: el?.field,
            operator: el?.operator,
            value: el?.value,
            type: el?.type,
          })),
          rep_ids: fetchOneLeadRoutingRule?.routings?.map((el: any) => el.rep_id) || [],
          deferral_date_time: fetchOneLeadRoutingRule?.deferral_date_time,
          email_updates: fetchOneLeadRoutingRule?.organization?.send_lead_routing_email,
          emails_list: fetchOneLeadRoutingRule?.organization?.lead_routing_email_list,
          lead_creation_status: fetchOneLeadRoutingRule?.lead_creation_status,
          los_rules: fetchOneLeadRoutingRule?.los_rules,
        });
      },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
    // {
    //   fetchPolicy: "no-cache",
    // },
  );

  const defaultRuleTemplate = {
    id: "",
    created_at: "",
    updated_at: "",
    name: "",
    priority: !!dataRules ? dataRules.fetchRules.length : 0,
    active: false,
    distribution_method: "RoundRobin",
    rep_selection_method: "FirstRep",
    conditions: [{ field: "0", operator: "", value: "", type: "" }],
    // routings: [],
    rep_ids: [],
    email_updates: false,
    emails_list: [],
    deferral_date_time: null,
    lead_creation_status: "Create",
    los_rules: [],
  };

  const [selectedRule, setSelectedRule] = useState(defaultRuleTemplate);

  // const [searchTerm, setSearchTerm] = useState("");

  const [selectedReps, setSelectedReps] = useState({
    included_list: [] as string[],
    ranked_list: [] as string[],
  });

  const [createOrUpdateLeadRoutingRule, { data: updateRule, loading: loadingUpdate }] = useMutation(
    CREATE_OR_UPDATE_ROUTING_RULE,
    {
      onCompleted({ createOrUpdateLeadRoutingRule }) {
        if (!createOrUpdateLeadRoutingRule) {
          return;
        }
        if (rule_id !== createOrUpdateLeadRoutingRule?.id) {
          history.push(`/system-config/routing-rules/${createOrUpdateLeadRoutingRule?.id}`);
        }
        setSelectedRule({
          id: createOrUpdateLeadRoutingRule?.id,
          created_at: createOrUpdateLeadRoutingRule?.created_at,
          updated_at: createOrUpdateLeadRoutingRule?.updated_at,
          name: createOrUpdateLeadRoutingRule?.name,
          priority: createOrUpdateLeadRoutingRule?.priority,
          active: createOrUpdateLeadRoutingRule?.active,
          distribution_method: createOrUpdateLeadRoutingRule?.distribution_method,
          rep_selection_method: createOrUpdateLeadRoutingRule?.rep_selection_method,
          conditions: createOrUpdateLeadRoutingRule?.conditions?.map((el: ICondition) => ({
            field: el?.field,
            operator: el?.operator,
            value: el?.value,
            type: el?.type,
          })),
          rep_ids: createOrUpdateLeadRoutingRule?.routings?.map((el: any) => el.rep_id) || [],
          deferral_date_time: createOrUpdateLeadRoutingRule?.deferral_date_time,
          email_updates: createOrUpdateLeadRoutingRule?.organization?.send_lead_routing_email,
          emails_list: createOrUpdateLeadRoutingRule?.organization?.lead_routing_email_list,
          los_rules: createOrUpdateLeadRoutingRule?.los_rules,
          lead_creation_status: createOrUpdateLeadRoutingRule?.lead_creation_status,
        });
        appToast("Success! Updated rules");
        // setSelectedRule(defaultRuleTemplate);
        // setSelectedReps({
        //   included_list: [],
        //   ranked_list: [],
        // });
      },
      onError({ message }) {
        console.log("Error in createOrUpdateLeadRoutingRule: ", message);
        appToast(message);
        Sentry.captureEvent({
          message: `createOrUpdateLeadRoutingRule GraphQL Error: ${message}`,
        });
      },
      refetchQueries: ["fetchRules"],
    },
  );

  const [removeRoutingRule, { data: deleteRule, loading: loadingRemoveRule }] = useMutation(REMOVE_ROUTING_RULE, {
    onCompleted({ removeRoutingRule }) {
      if (!removeRoutingRule) {
        return;
      }
      history.push(`/system-config/routing-rules`);
      appToast("Deleted routing route");
      // setSelectedRule(defaultRuleTemplate);
    },
    onError({ message }) {
      console.log("Error in removeRoutingRule: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `removeRoutingRule GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchRules"],
  });

  const [updateRuleOrder] = useMutation(UPDATE_RULE_ORDER, {
    onCompleted({ updateRuleOrder }) {
      if (!updateRuleOrder) {
        return;
      }
      appToast("Updated order");
    },
    onError({ message }) {
      console.log("Error in updateRuleOrder: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateRuleOrder GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchRules"],
  });

  const [updateRoutingRuleStatus] = useMutation(UPDATE_ROUTING_RULE_STATUS, {
    onCompleted({ updateRoutingRuleStatus }) {
      if (!updateRoutingRuleStatus) {
        return;
      }
      appToast("Updated active status");
    },
    onError({ message }) {
      console.log("Error in updateRoutingRuleStatus: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateRoutingRuleStatus GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchRules"],
  });

  const [setLeadRoutingEmailToggle] = useMutation(SET_LEAD_ROUTING_EMAIL_TOGGLE, {
    onCompleted({ setLeadRoutingEmailToggle }) {
      setLeadRoutingEmailToggle.send_lead_routing_email
        ? appToast("Email updates will now be sent")
        : appToast("Email updates will NOT be sent");
    },
    onError({ message }) {
      console.log("Error in setLeadRoutingEmailToggle", message);
      appToast(`Setting email updates error: ${message}`);
    },
  });

  const [setLeadRoutingEmailList] = useMutation(SET_LEAD_ROUTING_EMAIL_LIST, {
    onCompleted({ setLeadRoutingEmailList }) {
      appToast("Email list updated");
    },
    onError({ message }) {
      console.log("Error in setLeadRoutingEmailList", message);
      appToast(`Update email list error: ${message}`);
    },
  });

  // const { data: dataReps, loading: loadingOrg, error: errorOrg } = useQuery(FETCH_REPS_FROM_ORG, {
  //   variables: {
  //     org_id: loggedInUser().organization_id,
  //   },
  //   fetchPolicy: "network-only",
  //   onError({ message, name }) {
  //     Sentry.captureEvent({
  //       message: `${name} GraphQL Error: ${message}`,
  //     });
  //     console.log(`Error in ${name}: `, message);
  //   },
  // });

  const { data: dataOrg, loading: loadingOrg, error: errorOrg } = useQuery(FETCH_ORGANIZATION, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [
    fetchRepOrder,
    { data: dataOrder, loading: loadingOrder, error: errorOrder, called: calledOrder },
  ] = useLazyQuery(FETCH_REP_ORDER, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      !!dataOrder &&
      !!dataOrder.fetchRepOrder &&
      !!dataOrder.fetchRepOrder.length &&
      !!formikRef.current &&
      !!formikRef.current.setFieldValue &&
      !!formikRef.current.values
    ) {
      const sortedIDs = dataOrder.fetchRepOrder?.map((item: any) => item.id);
      if (arraysAreEqual(sortedIDs, formikRef.current.values.rep_ids)) {
        formikRef.current.setFieldValue("rep_ids", sortedIDs);
      }
    }
  }, [dataOrder]);

  const { data: dataOptions, loading: loadingOptions, error: errorOptions } = useQuery(
    FETCH_AVAILABLE_CONDITION_OPTIONS,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const filterRateAndPercentage = (type: string) => {
    if (type === "Percentage" || type === "Rate") {
      return "Number";
    }
    return type;
  };

  const findTypeFromFieldKey = (value: string) => {
    return !!value &&
      !!dataOptions?.fetchAvaliableConditionOption?.fields &&
      !!dataOptions.fetchAvaliableConditionOption.fields.slice().filter((item: any) => item.key === value).length
      ? filterRateAndPercentage(
          dataOptions.fetchAvaliableConditionOption.fields.slice().filter((item: any) => item.key === value)[0].type,
        )
      : "";
  };

  const formatMultipleTypeNames = (item: string) => {
    switch (item) {
      case "In":
        return "Equals Multiple";
      case "NotIn":
        return "Does Not Equal Multiple";
      default:
        return formatTypeName(item);
    }
  };

  const findOperatorsFromFieldType = (value: string) => {
    const val = value === "Rate" || value === "Percentage" ? "Number" : value;

    return !!value &&
      !!dataOptions?.fetchAvaliableConditionOption?.operations &&
      !!dataOptions.fetchAvaliableConditionOption.operations[val]
      ? value === "Text"
        ? [
            ...dataOptions.fetchAvaliableConditionOption.operations[val]?.map((item: string) => ({
              label: formatTypeName(item),
              value: item,
            })),
            ...dataOptions.fetchAvaliableConditionOption.operations["List"]?.map((item: string) => ({
              label: formatMultipleTypeNames(item),
              value: item,
            })),
          ]
        : dataOptions.fetchAvaliableConditionOption.operations[val]?.map((item: string) => ({
            label: formatTypeName(item),
            value: item,
          }))
      : [{ label: "Select field first", value: "" }];
  };

  const findDropdownOptionsFromField = (value: string) => {
    return !!value &&
      !!dataOptions?.fetchAvaliableConditionOption?.fields &&
      !!dataOptions.fetchAvaliableConditionOption.fields.slice().filter((item: any) => item.key === value).length
      ? dataOptions.fetchAvaliableConditionOption.fields
          .slice()
          .filter((item: any) => item.key === value)[0]
          .options?.map((item: string) => ({
            label: item,
            value: item,
          }))
      : [{ label: "Select field first", value: "" }];
  };

  if (loadingRules) return <Loading />;
  if (errorRules) return <AppErrorText>Error loading Priority Lead Routing Rules</AppErrorText>;

  const reorderRules = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    updateRuleOrder({
      variables: {
        rule_ids: result,
      },
    });
  };

  const onDragEndRules = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    reorderRules(
      dataRules.fetchRules?.map((item: any) => item.id),
      result.source.index,
      result.destination.index,
    );
  };

  const returnRepData = (repId: string) => {
    const data =
      !!dataOrg?.fetchOrganization?.Reps &&
      dataOrg?.fetchOrganization?.Reps.slice().filter((item: any) => repId === item.id);
    return data[0] ?? {};
  };

  const isRepValid = (repId: string) => {
    const data =
      !!dataOrg?.fetchOrganization?.Reps &&
      dataOrg?.fetchOrganization?.Reps.slice().filter((item: any) => repId === item.id);
    return data.length > 0;
  };

  return (
    <DisappearingDiv>
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          id: selectedRule.id,
          created_at: selectedRule.created_at,
          updated_at: selectedRule.updated_at,
          name: selectedRule.name,
          priority: selectedRule.priority + 1 || defaultRuleTemplate.priority + 1,
          active: selectedRule.active,
          distribution_method: selectedRule.distribution_method,
          rep_selection_method: selectedRule.rep_selection_method,
          conditions: selectedRule.conditions,
          // routings: selectedRule.routings,
          rep_ids: selectedRule.rep_ids.filter((id) => isRepValid(id)),
          email_updates: selectedRule.email_updates,
          emails_list: selectedRule.emails_list || defaultRuleTemplate.emails_list,
          deferral_date_time: selectedRule.deferral_date_time,
          lead_creation_status: selectedRule.lead_creation_status,
          los_rules: selectedRule.los_rules,
          available_fields_search: "",
        }}
        validationSchema={yupRoutingRules}
        onSubmit={async (values) => {
          console.log("valuesssss", values.lead_creation_status);
          if (values.email_updates !== selectedRule.email_updates) {
            await setLeadRoutingEmailToggle({
              variables: {
                send_lead_routing_email: values.email_updates,
              },
            });
          }

          if ([...values?.emails_list].sort().toString() !== [...selectedRule.emails_list].sort().toString()) {
            await setLeadRoutingEmailList({
              variables: {
                lead_routing_email_list: values.emails_list,
              },
            });
          }

          await createOrUpdateLeadRoutingRule({
            variables: {
              rule_id: rule_id === "new" ? undefined : rule_id,
              name: values.name,
              conditions: values.conditions?.map((item: ICondition) => ({
                field: item.field,
                operator: item.operator,
                value: item.value,
                type: findTypeFromFieldKey(item.field),
              })),
              rep_ids: values.rep_ids,
              distribution_method: values.distribution_method,
              rep_selection_method: values.rep_selection_method,
              deferral_date_time: values.deferral_date_time || undefined,
              lead_creation_status: values.lead_creation_status,
              los_rules: values.los_rules,
            },
          });
        }}
      >
        {({ submitForm, values, setFieldValue, errors, isValid }: FormikProps<MyFormikProps>) => {
          const reorderReps = (list: string[], startIndex: number, endIndex: number) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            setFieldValue("rep_ids", result);
          };

          const onDragEndReps = (result: any) => {
            // dropped outside the list
            if (!result.destination) {
              return;
            }

            reorderReps(values.rep_ids, result.source.index, result.destination.index);
          };

          const handleChangeEmails = (newValue: any, actionMeta: any) => {
            const emailsList = newValue?.map((item: any) => item.value);
            setFieldValue("emails_list", emailsList);
            console.group("Value Changed");
            console.log(newValue);
            console.log(`action: ${actionMeta.action}`);
            console.groupEnd();
          };

          return (
            <>
              <TopAddDiv>
                <AddButton
                  variant={"primary"}
                  onClick={() => {
                    // setSelectedRule(defaultRuleTemplate);
                    history.push(`/system-config/routing-rules/new`);
                  }}
                >
                  Add Routing Rule
                </AddButton>
              </TopAddDiv>
              <StyledTooltip
                multiline
                place="bottom"
                effect="solid"
                css={{
                  fontFamily: theme.PRIMARY_FONT,
                }}
                backgroundColor={theme.PRIMARY800}
                id="step-routing-rules-tooltip"
                getContent={(dataTip) => (
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "10px",
                      lineHeight: "14px",
                    }}
                  >
                    {dataTip}
                  </span>
                )}
              />
              {/* <div>
                  <AppText>This rep will not be prompted to call this lead until this date and time passes.</AppText>
                </div>
              </StyledTooltip> */}
              {deleteModal && (
                <DeleteRoutingRuleModal
                  blinds={deleteModal}
                  setBlinds={setDeleteModal}
                  loadingDelete={loadingRemoveRule}
                  deleteFunction={async () => {
                    await removeRoutingRule({
                      variables: {
                        rule_id: values.id,
                      },
                    });
                  }}
                />
              )}
              <TopAlignDiv>
                {!!rule_id ? (
                  <>
                    <BackButton
                      // borderless
                      onClick={() => {
                        setSelectedReps({
                          included_list: [],
                          ranked_list: [],
                        });
                        history.push(`/system-config/routing-rules/`);
                        !!togglePageTitle && togglePageTitle();
                      }}
                    >
                      <img style={{ marginRight: "8px" }} src={iconArrowBackNew} alt="Back" />
                      Back to priority Lead Routing Rules
                    </BackButton>

                    <SectionTitle>Name & Priority</SectionTitle>

                    <InputLabel>Routing Rule Name</InputLabel>
                    <RuleInput name="name" />

                    <InputLabel style={{ opacity: 0.5 }}>Priority</InputLabel>
                    <RuleInput gray disabled name="priority" />
                    <AppText style={{ fontSize: "10px", lineHeight: 1.5, opacity: 0.5, marginBottom: "32px" }}>
                      Routing rule can be prioritized in the dashboard after being created
                    </AppText>

                    <SectionTitle>If Lead Meets These Criteria</SectionTitle>

                    <FieldArray name="conditions">
                      {({ remove, push }) => (
                        <>
                          {loadingOptions ? (
                            <Loading />
                          ) : errorOptions ? (
                            <AppErrorText>Error loading options</AppErrorText>
                          ) : (
                            values?.conditions?.map((item: any, index: number) => (
                              <>
                                {index > 0 && <OperationBox>AND</OperationBox>}
                                <CriteriaGrid>
                                  <div>
                                    <InputLabel>Fields</InputLabel>
                                    <RulesSelect
                                      textAlign="left"
                                      name={`conditions[${index}].field`}
                                      placeholder="Select one"
                                      allowSelectPlaceholder
                                      options={dataOptions.fetchAvaliableConditionOption.fields?.map((item: any) => ({
                                        label: item.label,
                                        value: item.key,
                                        type: item.type,
                                      }))}
                                    />
                                  </div>
                                  <div>
                                    <InputLabel>Operator</InputLabel>
                                    <RulesSelect
                                      textAlign="left"
                                      placeholder="Select one"
                                      allowSelectPlaceholder
                                      name={`conditions[${index}].operator`}
                                      options={findOperatorsFromFieldType(
                                        findTypeFromFieldKey(values.conditions[index].field),
                                      )}
                                    />
                                  </div>
                                  {values.conditions[index].operator !== "IsNull" &&
                                  values.conditions[index].operator !== "NotNull" ? (
                                    <div>
                                      <InputLabel>Value</InputLabel>
                                      {findTypeFromFieldKey(values.conditions[index].field) === "Dropdown" &&
                                      !!values.conditions[index].field ? (
                                        <RulesSelect
                                          textAlign="left"
                                          placeholder="Select one"
                                          allowSelectPlaceholder
                                          name={`conditions[${index}].value`}
                                          options={findDropdownOptionsFromField(values.conditions[index].field)}
                                        />
                                      ) : findTypeFromFieldKey(values.conditions[index].field) === "Boolean" &&
                                        !!values.conditions[index].field ? (
                                        <RulesSelect
                                          textAlign="left"
                                          placeholder="Select one"
                                          allowSelectPlaceholder
                                          name={`conditions[${index}].value`}
                                          options={[
                                            { label: "True", value: "true" },
                                            { label: "False", value: "false" },
                                          ]}
                                        />
                                      ) : !!values.conditions[index].operator &&
                                        ["In", "NotIn"].includes(values.conditions[index].operator) &&
                                        !!values.conditions[index].field ? (
                                        <>
                                          <CreatableSelect
                                            isMulti={true}
                                            onChange={(newValue: any, actionMeta: any) => {
                                              const emailsList = newValue?.map((item: any) => item.value);
                                              setFieldValue("conditions", [
                                                ...values.conditions.slice()?.map((item, i) =>
                                                  i !== index
                                                    ? item
                                                    : {
                                                        field: values.conditions[index].field,
                                                        operator: values.conditions[index].operator,
                                                        value: JSON.stringify(emailsList),
                                                        type: values.conditions[index].type,
                                                      },
                                                ),
                                              ]);
                                            }}
                                            placeholder="Enter multiple options..."
                                            defaultValue={
                                              testForArrayWithoutLen(values.conditions[index].value)
                                                ? JSON.parse(values.conditions[index].value)?.map((item: string) => ({
                                                    label: item,
                                                    value: item,
                                                  }))
                                                : []
                                            }
                                          />
                                          {`${JSON.stringify(errors.conditions)}`.includes(
                                            "Requires multiple values",
                                          ) && (
                                            <AppErrorText style={{ marginBottom: "12px" }}>
                                              The "Equals Multiple" and "Does Not Equal Multiple" operators can only be
                                              used when checking for multiple values. Please specify more than one, or
                                              select another operator.
                                            </AppErrorText>
                                          )}
                                        </>
                                      ) : (
                                        <RuleInput name={`conditions[${index}].value`} />
                                      )}
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        width: "100%",
                                      }}
                                    />
                                  )}
                                  <div>
                                    {(index > 0 || values.conditions.length > 1) && (
                                      <CloseDiv onClick={() => remove(index)}>
                                        <img src={newCloseModal} alt="delete" width="14px" />
                                      </CloseDiv>
                                    )}
                                  </div>
                                </CriteriaGrid>
                              </>
                            ))
                          )}
                          {`${JSON.stringify(errors.conditions)}`.includes("Each field can only be used once") && (
                            <AppErrorText style={{ marginBottom: "12px" }}>
                              Each field can only be used once
                            </AppErrorText>
                          )}
                          <AddConditionButton
                            variant="secondary"
                            onClick={() => push({ field: "", operator: "", value: "", type: "" })}
                          >
                            Add Condition
                          </AddConditionButton>
                        </>
                      )}
                    </FieldArray>

                    <SectionTitle>Lead Creation Status</SectionTitle>
                    <ButtonsDiv>
                      <RadioBorder
                        onClick={() => setFieldValue("lead_creation_status", "Create")}
                        selected={values.lead_creation_status === "Create"}
                      >
                        <RadioButton selected={values.lead_creation_status === "Create"} />
                      </RadioBorder>
                      <RadioText>Only apply rule when a lead is newly created</RadioText>
                    </ButtonsDiv>

                    <ButtonsDiv>
                      <RadioBorder
                        onClick={() => setFieldValue("lead_creation_status", "Update")}
                        selected={values.lead_creation_status === "Update"}
                      >
                        <RadioButton selected={values.lead_creation_status === "Update"} />
                      </RadioBorder>
                      <RadioText>Apply rule only when an existing lead is updated</RadioText>
                    </ButtonsDiv>

                    <ButtonsDiv>
                      <RadioBorder
                        onClick={() => setFieldValue("lead_creation_status", "Both")}
                        selected={values.lead_creation_status === "Both"}
                      >
                        <RadioButton selected={values.lead_creation_status === "Both"} />
                      </RadioBorder>
                      <RadioText>
                        Apply rule both when a lead is newly created or when an existing lead is updated
                      </RadioText>
                    </ButtonsDiv>

                    <SectionTitle>Lead Status</SectionTitle>
                    <FlexColDiv>
                      {LEAD_STATUSES?.map((item: any) => {
                        const checked = values.los_rules.includes(item.value);
                        return (
                          <AppCheckbox
                            title={item.title}
                            checked={checked}
                            onClick={() => {
                              !!checked
                                ? setFieldValue(
                                    "los_rules",
                                    values.los_rules.filter((rule: string) => rule != item.value),
                                  )
                                : setFieldValue("los_rules", [...values.los_rules, item.value]);
                            }}
                          />
                        );
                      })}
                    </FlexColDiv>
                    <SectionTitle>Then Distribute to Reps According To These Rules</SectionTitle>
                    <RepListsGrid>
                      <SelectRepsContainer>
                        <AppText>Select Reps to Include in This Rule</AppText>
                        <RepsListContainer>
                          <SpaceBetweenDiv>
                            <SearchRepsInput
                              name="available_fields_search"
                              // value={searchTerm}
                              // onChange={(e: any) => setSearchTerm(e.target.value)}
                              placeholder="Search Reps"
                            />
                            <OutsideEvent onOutsideClick={() => setIsClicked(false)}>
                              <ContainerDivSort>
                                <div
                                  onClick={() => {
                                    setIsClicked(!isClicked);
                                  }}
                                >
                                  <IconButton style={{ marginLeft: "8px" }}>
                                    <img src={tempIconUpDown} height="32px" alt="Add selected reps as assigned" />
                                  </IconButton>
                                </div>
                                {isClicked && (
                                  <OptionsDiv>
                                    <Option
                                      selected={sortAlphabetically}
                                      onClick={() => {
                                        setSortAlphabetically(true);
                                      }}
                                    >
                                      Alphabetically
                                    </Option>
                                    <Option
                                      selected={!sortAlphabetically}
                                      onClick={() => {
                                        setSortAlphabetically(false);
                                      }}
                                    >
                                      Rev / Rep / Day
                                    </Option>
                                  </OptionsDiv>
                                )}
                              </ContainerDivSort>
                            </OutsideEvent>
                          </SpaceBetweenDiv>
                          <RepsScrollDiv>
                            {!!loadingOrg ? (
                              <Loading />
                            ) : errorOrg ? (
                              <AppErrorText>Error loading reps</AppErrorText>
                            ) : (
                              !!dataOrg?.fetchOrganization?.Reps &&
                              dataOrg?.fetchOrganization?.Reps.slice()
                                .filter(
                                  (item: any) =>
                                    !values.available_fields_search ||
                                    `${item.first_name} ${item.last_name}`
                                      .toLowerCase()
                                      .includes(values.available_fields_search.toLowerCase()),
                                )
                                .sort((a: any, b: any) =>
                                  sortAlphabetically
                                    ? `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`)
                                    : b.revenue_per_day - a.revenue_per_day,
                                )
                                ?.map((item: any) => (
                                  <AppCheckbox
                                    title={`${item.first_name} ${item.last_name} ${
                                      !sortAlphabetically ? `(${item.revenue_per_day.toFixed(2)})` : ""
                                    }`}
                                    checked={selectedReps.included_list.includes(item.id)}
                                    onClick={() => {
                                      selectedReps.included_list.includes(item.id)
                                        ? setSelectedReps({
                                            ranked_list: selectedReps.ranked_list,
                                            included_list: selectedReps.included_list
                                              .slice()
                                              .filter((li: string) => li !== item.id),
                                          })
                                        : setSelectedReps({
                                            ranked_list: selectedReps.ranked_list,
                                            included_list: [...selectedReps.included_list, item.id],
                                          });
                                    }}
                                  />
                                ))
                            )}
                          </RepsScrollDiv>
                        </RepsListContainer>
                      </SelectRepsContainer>

                      <FlexColDiv>
                        <IconButton
                          style={{ marginBottom: "8px" }}
                          onClick={() => {
                            setFieldValue("rep_ids", [...new Set([...values.rep_ids, ...selectedReps.included_list])]);
                            setSelectedReps({
                              included_list: [],
                              ranked_list: selectedReps.ranked_list,
                            });
                          }}
                        >
                          <img src={iconButtonArrowGray} alt="Add selected reps as assigned" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setFieldValue(
                              "rep_ids",
                              values.rep_ids.filter((item: string) => !selectedReps.ranked_list.includes(item)),
                            );
                            setSelectedReps({
                              included_list: selectedReps.included_list,
                              ranked_list: [],
                            });
                          }}
                        >
                          <img
                            style={{ transform: "rotate(180deg)" }}
                            src={iconButtonArrowGray}
                            alt="Remove selected reps from assigned"
                          />
                        </IconButton>
                      </FlexColDiv>

                      <SelectRepsContainer>
                        <SpaceBetweenDiv>
                          <AppText>Rank Selected Reps</AppText>
                          {values?.rep_ids?.length > 1 && (
                            <SpaceBetweenDiv
                              onClick={() => {
                                fetchRepOrder({
                                  variables: {
                                    user_ids: values.rep_ids,
                                  },
                                });
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <AppText style={{ color: theme.PRIMARY500, fontWeight: 600 }}>
                                Sellfire Suggested Rank
                              </AppText>
                              <img style={{ marginLeft: "7px" }} src={iconRefreshSmall} alt="Auto rank" />
                            </SpaceBetweenDiv>
                          )}
                        </SpaceBetweenDiv>
                        <RepsListContainer>
                          <DragDropContext onDragEnd={onDragEndReps}>
                            <Droppable droppableId="droppablereps">
                              {(provided, snapshot) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                  <DragElements>
                                    {!!loadingOrg ? (
                                      <Loading />
                                    ) : errorOrg ? (
                                      <AppErrorText>Error loading reps</AppErrorText>
                                    ) : (
                                      values.rep_ids
                                        ?.map((item) => returnRepData(item))
                                        ?.map((item: any, index: number) => (
                                          <Draggable key={`rep_${item.id}`} draggableId={item.id} index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                key={`$rep_checkbox_${item.id}`}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <RepDraggableDiv>
                                                  <img
                                                    style={{ marginRight: "8px", marginTop: "6px" }}
                                                    src={iconNewMoveRow}
                                                    height="12px"
                                                    alt="Drag me"
                                                  />
                                                  <AppCheckbox
                                                    title={`${item.first_name} ${item.last_name}`}
                                                    checked={selectedReps.ranked_list.includes(item.id)}
                                                    onClick={() => {
                                                      selectedReps.ranked_list.includes(item.id)
                                                        ? setSelectedReps({
                                                            included_list: selectedReps.included_list,
                                                            ranked_list: selectedReps.ranked_list
                                                              .slice()
                                                              .filter((li: string) => li !== item.id),
                                                          })
                                                        : setSelectedReps({
                                                            included_list: selectedReps.included_list,
                                                            ranked_list: [...selectedReps.ranked_list, item.id],
                                                          });
                                                    }}
                                                  />
                                                </RepDraggableDiv>
                                              </div>
                                            )}
                                          </Draggable>
                                        ))
                                    )}
                                  </DragElements>
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </RepsListContainer>
                      </SelectRepsContainer>
                    </RepListsGrid>

                    <RadioText style={{ marginTop: "32px", marginBottom: "24px" }}>
                      How should we determine if a Rep is available to take a Priority Lead?
                    </RadioText>

                    <ButtonsDiv>
                      <RadioBorder
                        onClick={() => setFieldValue("rep_selection_method", "FirstRep")}
                        selected={values.rep_selection_method === "FirstRep"}
                      >
                        <RadioButton selected={values.rep_selection_method === "FirstRep"} />
                      </RadioBorder>
                      <RadioText>Rep is not on any call (Emphasize Speed to Dial)</RadioText>
                    </ButtonsDiv>

                    <ButtonsDiv>
                      <RadioBorder
                        onClick={() => setFieldValue("rep_selection_method", "BestRep")}
                        selected={values.rep_selection_method === "BestRep"}
                      >
                        <RadioButton selected={values.rep_selection_method === "BestRep"} />
                      </RadioBorder>
                      <RadioText>
                        Rep may be on a call, but is not in a Scheduled Call Back, Demo, or Decision Call (Emphasize
                        Getting Leads to Best Reps)
                      </RadioText>
                    </ButtonsDiv>

                    <RadioText style={{ marginTop: "32px", marginBottom: "24px" }}>
                      Do you want to prioritize equal lead distribution or company performance?
                    </RadioText>

                    <ButtonsDiv>
                      <RadioBorder
                        onClick={() => setFieldValue("distribution_method", "RoundRobin")}
                        selected={values.distribution_method === "RoundRobin"}
                      >
                        <RadioButton selected={values.distribution_method === "RoundRobin"} />
                      </RadioBorder>
                      <RadioText>Round Robin (Even Distribution)</RadioText>
                    </ButtonsDiv>

                    <ButtonsDiv>
                      <RadioBorder
                        selected={values.distribution_method === "Ranking"}
                        onClick={() => setFieldValue("distribution_method", "Ranking")}
                      >
                        <RadioButton selected={values.distribution_method === "Ranking"} />
                      </RadioBorder>
                      <RadioText>By Rank (Always Prioritize Leads to Your Top Ranked Reps)</RadioText>
                    </ButtonsDiv>
                    <Horizontal>
                      <InputLabel>Start Date</InputLabel>
                      <AiOutlineInfoCircle
                        data-for="step-routing-rules-tooltip"
                        data-tip="This rep will not be prompted to call this lead until this date and time passes. This is an optional value. If not specified, the lead will be available to call immediately."
                        size={14}
                        style={{ marginTop: 18 }}
                      />
                    </Horizontal>

                    <ReactDatesWrapper>
                      <SingleDatePicker
                        id="step-routing--date-picker"
                        required
                        date={moment(values?.deferral_date_time || undefined)}
                        onDateChange={(date) => setFieldValue("deferral_date_time", date)}
                        focused={focused}
                        onFocusChange={({ focused }) => setFocused(focused)}
                        numberOfMonths={1}
                        showDefaultInputIcon
                        inputIconPosition={"after"}
                      />
                    </ReactDatesWrapper>

                    <EmailsSwitchDiv>
                      <Switch
                        onChange={(checked) => {
                          setFieldValue("email_updates", checked);
                        }}
                        onColor={theme.PRIMARY500}
                        offColor={theme.NEUTRAL200}
                        checked={values.email_updates}
                        height={32}
                        width={54}
                        handleDiameter={24}
                        checkedIcon={false}
                        uncheckedIcon={false}
                      />
                      <RadioText>Send email updates when this rule has been applied</RadioText>
                    </EmailsSwitchDiv>
                    {values.email_updates &&
                      (loadingOrg ? (
                        <Loading />
                      ) : errorOrg ? (
                        <AppErrorText>Error loading reps</AppErrorText>
                      ) : (
                        !!dataOrg &&
                        !!dataOrg?.fetchOrganization &&
                        !!dataOrg.fetchOrganization?.Reps && (
                          <div style={{ maxWidth: "732px" }}>
                            <CreatableSelect
                              isClearable
                              isMulti
                              onChange={handleChangeEmails}
                              placeholder="Enter email addresses..."
                              options={dataOrg?.fetchOrganization?.Reps?.map((item: any) => ({
                                label: `${item.email} (${item.first_name} ${item.last_name})`,
                                value: item.email,
                              }))}
                              defaultValue={dataOrg?.fetchOrganization?.Reps.filter((item: any) =>
                                values?.emails_list.includes(item.email),
                              )?.map((item: any) => ({
                                label: `${item.email} (${item.first_name} ${item.last_name})`,
                                value: item.email,
                              }))}
                            />
                            {!!`${JSON.stringify(errors.emails_list)}`.includes("Email addresses must be valid") && (
                              <AppErrorText style={{ marginBottom: "12px" }}>
                                Please make sure the email addresses are valid
                              </AppErrorText>
                            )}
                          </div>
                        )
                      ))}

                    <ButtonsDiv2>
                      <SaveButton variant={"primary"} type="submit" onClick={submitForm}>
                        Save Rule
                      </SaveButton>
                      {!!values.id && (
                        <SaveButton
                          style={{ color: theme.ATTENTION700 }}
                          // disabled={loadingRemoveRule}
                          onClick={() => {
                            setDeleteModal(true);
                          }}
                        >
                          Delete Rule
                        </SaveButton>
                      )}
                    </ButtonsDiv2>
                  </>
                ) : (
                  <>
                    <AppSidebarCard title="Lead Routing Rules">
                      <CampaignTable>
                        <TableHeadingText>Order</TableHeadingText>
                        <TableHeadingText>Rule Name</TableHeadingText>
                        <TableHeadingText>Modified</TableHeadingText>
                        <TableHeadingText>Active</TableHeadingText>
                        <TableHeadingText>Manage</TableHeadingText>
                      </CampaignTable>
                      {loadingRules ? (
                        <Loading />
                      ) : errorRules ? (
                        <AppErrorText>Error loading routing rules</AppErrorText>
                      ) : !!dataRules?.fetchRules?.length ? (
                        <DragDropContext onDragEnd={onDragEndRules}>
                          <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                              <div {...provided.droppableProps} ref={provided.innerRef}>
                                {dataRules.fetchRules
                                  .slice()
                                  .sort((a: any, b: any) => a.priority - b.priority)
                                  ?.map((item: any, index: number) => (
                                    <Draggable key={`table_${item.id}`} draggableId={item.id} index={index}>
                                      {(provided, snapshot) => (
                                        <CampaignTable
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <TableText>
                                            <img
                                              style={{ marginRight: "16px", cursor: "pointer" }}
                                              src={iconNewMoveRow}
                                              height="10px"
                                              alt="Drag me"
                                            />
                                            {item.priority + 1}
                                          </TableText>
                                          <TableText>{item.name}</TableText>
                                          <TableText>
                                            {!!item.updated_at && moment(item.updated_at).format("MM/DD/YYYY")}
                                          </TableText>
                                          <TableDiv>
                                            <Switch
                                              onChange={(checked) => {
                                                updateRoutingRuleStatus({
                                                  variables: {
                                                    rule_id: item.id,
                                                    active: checked,
                                                  },
                                                });
                                              }}
                                              onColor={theme.PRIMARY500}
                                              offColor={theme.NEUTRAL200}
                                              checked={item.active}
                                              height={24}
                                              width={40}
                                              handleDiameter={18}
                                              checkedIcon={false}
                                              uncheckedIcon={false}
                                            />
                                          </TableDiv>
                                          <TableText>
                                            <span
                                              style={{ color: theme.PRIMARY500, fontWeight: 600, cursor: "pointer" }}
                                              onClick={() => {
                                                !!togglePageTitle && togglePageTitle();

                                                const santizedURL = sanitizeURL(
                                                  `/system-config/routing-rules/${item.id}`,
                                                );

                                                history.push(santizedURL);
                                              }}
                                            >
                                              Edit
                                            </span>
                                          </TableText>
                                        </CampaignTable>
                                      )}
                                    </Draggable>
                                  ))}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      ) : (
                        <AppText style={{ padding: "32px" }}>
                          No rules have been added. Adding some will display them here.
                        </AppText>
                      )}
                    </AppSidebarCard>
                  </>
                )}
              </TopAlignDiv>
              {isOnboarding === true && (
                <CenterDiv>
                  <SaveAndContinueButton type="submit" onClick={() => history.push(`/onboarding?step=${step + 1}`)}>
                    Continue
                  </SaveAndContinueButton>
                </CenterDiv>
              )}
            </>
          );
        }}
      </Formik>
    </DisappearingDiv>
  );
};

const EmailsSwitchDiv = styled.div`
  display: grid;
  grid-template-columns: 75px 1fr;
  margin-top: 28px;
  margin-bottom: 20px;
  align-items: center;
`;

const OptionsDiv = styled.div`
  position: absolute;
  right: 0px;
  top: 48px;
  width: 171px;
  background-color: ${theme.WHITE_COLOR};
  /* box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15); */
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 4px;
  z-index: 99;
`;

interface SelectedProp {
  selected: boolean;
}

const Option = styled.div<SelectedProp>`
  display: flex;
  font-weight: ${(props) => (props.selected ? 600 : "normal")};
  align-items: center;
  height: 40px;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  padding-left: 12px;
  background-color: ${theme.WHITE_COLOR};
  :first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  :hover {
    background-color: ${theme.NEUTRAL200};
  }
`;

const ContainerDivSort = styled.div`
  min-width: 100%;
  height: 40px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RepDraggableDiv = styled.div`
  display: flex;
`;

const DragElements = styled.div`
  max-height: 208px;
  min-height: 208px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const IconButton = styled(NewAppButton)`
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RepListsGrid = styled.div`
  display: grid;
  grid-template-columns: 330px 40px 330px;
  grid-gap: 16px;
`;

const FlexColDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const RepsScrollDiv = styled.div`
  max-height: calc(100% - 60px);
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const SearchRepsInput = styled(InputField)`
  background-color: ${theme.NEUTRAL100};
  margin: 0px;
  /* margin-top: 16px; */
  padding-left: 40px;
  z-index: 10;
  background: url(${searchIcon}) no-repeat scroll 15px 10px;
`;

const RepsListContainer = styled.div`
  border: 1px solid ${theme.NEUTRAL200};
  height: 240px;
  overflow: hidden;
  padding: 16px;
  background-color: ${theme.WHITE_COLOR};
  margin-top: 12px;
  border-radius: 2px;
`;

const SelectRepsContainer = styled.div`
  width: 330px;
`;

const RadioText = styled(AppText)`
  font-size: 14px;
  line-height: 21px;
`;

interface SelectedProp {
  selected: boolean;
}

const RadioBorder = styled.div<SelectedProp>`
  border: 1px solid ${(props) => (props.selected ? theme.PRIMARY500 : theme.NEUTRAL200)};
  border-radius: 50%;
  margin-right: 8px;
`;

const RadioButton = styled.div<SelectedProp>`
  width: 18px;
  height: 18px;
  margin: 3px;
  border-radius: 50%;
  background-color: ${(props) => (props.selected ? theme.PRIMARY500 : theme.NEUTRAL200)};
`;

const SaveButton = styled(NewAppButton)`
  width: 140px;
  height: 48px;
  margin-right: 12px;
`;

const ButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 12px;
`;
const ButtonsDiv2 = styled.div`
  display: flex;
  align-items: center;
  margin-top: 52px;
`;

const OperationBox = styled(AppText)`
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: ${theme.NEUTRAL400};
  padding: 12px 18px;
  border: 1px solid ${theme.NEUTRAL400};
  border-radius: 99px;
  /* margin-top: 16px; */
  margin-bottom: 16px;
  margin-left: 391px;
  background-color: ${theme.WHITE_COLOR};
`;

const AddConditionButton = styled(NewAppButton)`
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  text-transform: uppercase;
  height: 40px;
  width: 140px;
  margin-bottom: 32px;
`;

const RulesSelect = styled(FormSelectField)`
  background-color: ${theme.WHITE_COLOR};
`;

const CloseDiv = styled.div`
  border-radius: 50%;
  border: 1.5px solid ${theme.NEUTRAL400};
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-left: 6px;
  cursor: pointer;
  background-color: ${theme.WHITE_COLOR};
`;

const CriteriaGrid = styled.div`
  display: grid;
  grid-template-columns: 330px 139px 330px 42px;
  grid-gap: 8px;
`;

interface GrayProp {
  gray?: boolean;
}

const RuleInput = styled(InputField)<GrayProp>`
  margin: 0px;
  width: 330px;
  height: 40px;
  background-color: ${(props) => (props.gray ? "transparent" : theme.WHITE_COLOR)};
`;

const InputLabel = styled(AppText)`
  margin-bottom: 8px;
  margin-top: 8px;
`;

const SectionTitle = styled(AppText)`
  font-weight: 600;
  margin-bottom: 24px;
  margin-top: 16px;
`;

const BackButton = styled(NewAppButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 340px;
  margin-bottom: 24px;
`;

const AddButton = styled(NewAppButton)`
  height: 40px;
  width: 150px;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.2px;
  text-transform: capitalize;
`;

const TopAddDiv = styled.div`
  padding: 0px 24px 14px 24px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

interface IOpacity {
  translucent?: boolean;
}

const TableText = styled(AppText)<IOpacity>`
  font-size: 13px;
  opacity: ${(props) => (props.translucent ? 0.5 : 1)};
  padding-left: 16px;
  padding-right: 16px;
  border-right: 1px solid ${theme.NEUTRAL200};
  padding-top: 11px;
  width: 100%;
`;

const TableDiv = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  border-right: 1px solid ${theme.NEUTRAL200};
  padding-top: 8px;
  width: 100%;
`;

const TableHeadingText = styled(AppText)`
  font-weight: 600;
  padding: 11px 16px;
  width: 100%;
  height: 100%;
  vertical-align: center;
  background-color: ${theme.NEUTRAL200};
  border-right: 1px solid ${theme.NEUTRAL200};
  :last-child {
    border-right: none;
  }
`;

const CampaignTable = styled.div`
  display: grid;
  width: calc(100%);
  overflow-x: hidden;
  grid-template-columns: 147px 1fr 200px 147px 147px;
  grid-template-rows: 40px;
  align-items: stretch;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  border-top: 1px solid ${theme.NEUTRAL200};
  margin-top: -1px;
  /* margin-left: 1px; */
  background-color: ${theme.WHITE_COLOR};
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const TopAlignDiv = styled.div`
  width: 100%;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SaveAndContinueButton = styled(AppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export { StepRoutingRules };
