import { reverse, round } from "lodash";
import moment from "moment";
import "moment-timezone";
import { loggedInUser } from "../apollo/cache";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { findTimezoneShorthand } from "../static/timezones";
import { Quill as QuillType } from "quill";
import { IIntegrations } from "../types";
import {
  user_white,
  chevron_up,
  phone_call,
  chevron_down,
  mail,
  chat,
  settings,
  info,
  clock,
  calendar,
  edit,
  sequences,
} from "./../images/NewDesign";

export const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);
export const DATE_FORMAT = "MM/DD/YYYY";
export const IMPORT_DATE_FORMAT = "MM/DD/YYYY hh:mm A";
export const TIME_FORMAT = "h:mm a";
export const DATE_TIME_FORMAT = "MM/DD/YYYY, hh:mm a";

export const INCOMING_CALL_DATE_FORMAT = "MMM Do, hh:mm A";
export const ALL_LEADS_DATE_TIME_FORMAT = "MMM Do YYYY, hh:mm A";

export const SYSTEM_FIELD_CHAR_LIMIT = 100;

export const SYSTEM_FIELD_EMAIL_CHAR_LIMIT = 254;

export const convertDate = (date: any) => {
  try {
    const temp = moment(date).toDate();
    if (temp.toString() === "Invalid Date") {
      const temp2 = moment(parseInt(date)).toDate();
      if (temp2.toString() === "Invalid Date") {
        return undefined;
      }
    }
    return temp;
  } catch (e) {
    try {
      return moment(parseInt(date)).toDate();
    } catch (e) {
      return undefined;
    }
  }
};

export const calculateTimeDifference = (currentTime: any, updated_at: any) => {
  if (!currentTime) {
    return ``;
  }
  const minSec = moment.utc(moment(currentTime).diff(moment(updated_at))).format("mm:ss");
  const hours = moment(currentTime).diff(updated_at, "hours");
  if (!!hours) {
    return `${hours}:${minSec}`;
  }
  return `${minSec}`;
};

export const calculateTimeDifferenceNum = (currentTime: any, updated_at: any) => {
  if (!currentTime) {
    return 0;
  }
  return Math.abs(moment(currentTime).diff(moment(updated_at)));
};

export const calculatePercentage = (numerator: number, denominator: number) => {
  if (!!denominator) {
    return numerator / denominator > 1 ? 1 : numerator / denominator;
  }
  return 0;
};

export const formatDateIncomingCall = (date: string) => moment(date).format(INCOMING_CALL_DATE_FORMAT);

export const formatDate = (date: string) => moment(date).format(DATE_FORMAT);
export const formatDateTime = (date: string) => moment(date).format(DATE_TIME_FORMAT);
export const formatUnixDateTime = (date: number) => moment(date).format(DATE_TIME_FORMAT);
export const formatDateTimeAllLeads = (date: string) => moment(date).format(ALL_LEADS_DATE_TIME_FORMAT);

export const formatRestingTime = (numDays: number): string =>
  numDays > 2 ? `${numDays} days` : `${Math.round(24 * numDays)} hours`;

export const formatBackInQueueOnDate = (numDays: number): string => {
  const hours = Math.floor(numDays * 24);
  return `This will cycle back into your Active Queue on ${moment()
    .add(hours, "hours")
    .format("dddd, MMMM Do YYYY [at] h:mm A")}`;
};

const resting_phases = ["Resting", "LongResting", "RestingColdCall", "LongRestingColdCall"];
const retired_phases = ["RetiredColdCall", "Retired"];

export const returnDispositionInfo = (item: any) => {
  if (resting_phases.includes(item?.next_phase)) {
    return `This will remove your ownership of the lead and put it into a resting phase.`;
  }
  if (retired_phases.includes(item?.next_phase)) {
    return `This will put the lead into a retired phase, which will stop future assignment of this lead.`;
  }
  return formatBackInQueueOnDate(item?.resting_day);
};

export const formatTime = (numSeconds: number) => {
  const minutes = Math.floor(numSeconds / 60);
  const seconds = Math.floor(numSeconds % 60);
  const lzero_seconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${minutes}:${lzero_seconds}`;
};

export const formatTimeFullText = (numSeconds: number) => {
  const minutes = Math.floor(numSeconds / 60);
  const seconds = Math.floor(numSeconds % 60);
  return minutes > 0
    ? `${minutes} ${minutes > 1 ? "minutes" : "minute"} ${seconds} ${seconds > 1 ? "seconds" : "second"}`
    : `${seconds} ${seconds > 1 ? "seconds" : "second"}`;
};

export const formatCallDuration = (numSeconds: number) => {
  const hours = Math.floor(numSeconds / 3600);
  const lzero_hours = hours >= 10 ? `${hours}` : hours > 0 ? `0${hours}` : "00";
  const minutes = Math.floor((numSeconds - hours * 3600) / 60);
  const lzero_minutes = minutes >= 10 ? `${minutes}` : minutes > 0 ? `0${minutes}` : "00";
  const seconds = numSeconds % 60;
  const lzero_seconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${lzero_hours}:${lzero_minutes}:${lzero_seconds}`;
};

export const formatCallDurationShortened = (numSeconds: number) => {
  const hours = Math.floor(numSeconds / 3600);
  const lzero_hours = hours >= 10 ? `${hours}` : hours > 0 ? `0${hours}` : "00";
  const minutes = Math.floor((numSeconds - hours * 3600) / 60);
  const lzero_minutes = minutes >= 10 ? `${minutes}` : minutes > 0 ? `0${minutes}` : "00";
  const seconds = numSeconds % 60;
  const lzero_seconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  if (hours > 0) {
    return `${lzero_hours}:${lzero_minutes}:${lzero_seconds}`;
  }

  return `${minutes}:${lzero_seconds}`;
};

export const formatDateToTime = (date: string) => moment(date).format(TIME_FORMAT);

export const formatImportDate = (date: string) => moment(date).format(IMPORT_DATE_FORMAT);

export const formatUnixImportDate = (timestamp: number) => moment(timestamp).format(IMPORT_DATE_FORMAT);

export const roundNumber = (value: number) => Math.round(value * 10) / 10;

export const daysFromCreatedDate = (date: string) => {
  const currentDate = moment(new Date());
  const createdDate = moment(date);

  if (currentDate.diff(createdDate, "hours") < 1) {
    return "< 1 hour ago";
  }
  if (currentDate.diff(createdDate, "hours") < 24) {
    return currentDate.diff(createdDate, "hours") === 1
      ? currentDate.diff(createdDate, "hours") + " hour ago"
      : currentDate.diff(createdDate, "hours") + " hours ago";
  }
  if (currentDate.diff(createdDate, "hours") >= 24) {
    return currentDate.diff(createdDate, "days") === 1
      ? currentDate.diff(createdDate, "days") + " day ago"
      : currentDate.diff(createdDate, "days") + " days ago";
  }
};

export const daysFromCreatedDateShort = (date: string) => {
  const currentDate = moment(new Date());
  const createdDate = moment(date);

  if (currentDate.diff(createdDate, "hours") < 1) {
    return "< 1 hour ago";
  }
  if (currentDate.diff(createdDate, "hours") < 24) {
    return currentDate.diff(createdDate, "hours") + "h";
  }
  if (currentDate.diff(createdDate, "hours") >= 24) {
    return currentDate.diff(createdDate, "days") + "d";
  }
};

export const messageTimeStampFormat = (timestamp: string) => {
  if (moment(timestamp).isSame(moment(), "day")) {
    return "h:mm A";
  } else {
    return "h:mm A - MM/DD";
  }
};

export const messageTimeStampFormat2 = (timestamp: string) => {
  if (moment(timestamp).isSame(moment(), "day")) {
    return "[Today] h:mm A";
  } else {
    return "MM/DD/YYYY h:mm A";
  }
};
export const messageTimeStampFormatEmail = (timestamp: string) => {
  if (moment(timestamp).isSame(moment(), "day")) {
    return "h:mm A";
  } else {
    return "MM/DD/YYYY";
  }
};

export const timeFromDate = (date: string, unitOfTime: moment.unitOfTime.Diff) => {
  const currentDate = moment(new Date());
  const createdDate = moment(date);

  const timeDifference = createdDate.diff(currentDate, unitOfTime);
  return timeDifference;
};

// export const formatPhoneNumber = (number: string) => {
//   // const digits = ("" + number).replace(/\D/g, "");
//   // const phone = digits.slice(0, 10);
//   // const match = phone.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
//   // if (match) {
//   //   return ["(", match[2], ") ", match[3], "-", match[4]].join("").slice(0, 14);
//   // }
//   // return undefined;
//   let ret = number;
//   if (!number) {
//     return "";
//   }
//   if (number.includes("+")) {
//     ret = formatPhoneNumberIntl(number);
//   } else {
//     ret = formatPhoneNumberIntl(`+${number}`);
//   }

//   return ret || number;
// };

/**
 * formats phone number into readable format
 */
export const formatPhoneNumber = (phoneNumberString?: string | null) => {
  if (!phoneNumberString) {
    return ``;
  }
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  if (cleaned.length > 10) {
    return `+${cleaned.slice(0, cleaned.length - 10)} ${cleaned.slice(cleaned.length - 10)}`;
  }
  return `+${cleaned}`;
};

export const formatUSD = (number: number) => {
  if (number >= 1_000_000_000) {
    return `${new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 0 }).format(
      number / 1_000_000,
    )}M`;
  }
  // if (number >= 1_000) {
  //   return `${new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 1 }).format(
  //     number / 1_000,
  //   )}K`;
  // }
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(
    number,
  );
};

export const formatUSDFromString = (numberAsString: string) => {
  try {
    const new_number = parseFloat(numberAsString);
    return formatUSD(new_number);
  } catch (e) {
    return formatUSD(0);
  }
};

export const formatUSDOld = (number: number) => {
  if (number >= 1_000_000) {
    return `${new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 1 }).format(
      number / 1_000_000,
    )}M`;
  }
  if (number >= 1_000) {
    return `${new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 1 }).format(
      number / 1_000,
    )}K`;
  }
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 1 }).format(
    number,
  );
};

export const formatUSDRaw = (number: number) => {
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(
    number,
  );
};

export const secondsToTime = (seconds: number) => {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
};
export const flipNameFormat = (name: string) => {
  if (!!!name) return;
  const flipped = name.split(" ").reverse();
  if (flipped.length < 2) {
    return;
  }
  const firstName = flipped[0].replace(/[^A-Za-z]/g, "");
  const lastName = flipped[1].replace(/[^A-Za-z]/g, "");
  return firstName + " " + lastName;
};

export const formatUserTimezone = (time: string) => {
  const timezone = loggedInUser().timezone;
  if (!timezone) {
    return moment(time).format();
  }
  return moment(time).tz(timezone).format();
};

export const toTitleCase = (name: string) => {
  if (!!!name) return;
  return name.replace(/\w\S*/g, function (name) {
    return name.charAt(0).toUpperCase() + name.substr(1).toLowerCase();
  });
};

export const camelToProperSplit = (string: string) => {
  const stringArray = string.split(/(?=[A-Z])/).join(" ");
  console.log(stringArray);
  stringArray[0].toUpperCase();
  return stringArray;
};

export const camelToProperSplitWithAbbrs = (string: string) => {
  return string.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
};

/**
 * Strips out special characters from phone numbers.
 * @returns number on version of phone_number
 * @param phone_number - phone number
 */
export const extractNumber = (phone_number?: string) => {
  if (!phone_number) {
    return "";
  }
  const number_without_schars = phone_number.match(/\d/g)?.join("");
  if (!number_without_schars) {
    return "";
  }
  return number_without_schars;
};

export const onlyDigits = (str: string) => {
  return str?.match(/\d/g)?.join("") || "";
};

/**
 * Strips out special characters from phone numbers.
 * @returns number on version of phone_number
 * @param phone_number - phone number
 */
export const extractNumberWithCountry = (phone_number?: string, country?: string) => {
  if (!phone_number) {
    return "";
  }
  const number_without_schars = onlyDigits(phone_number);
  if (!number_without_schars) {
    return "";
  }
  // if US based number, only take last 10 digits.
  if (country === "US") {
    if (number_without_schars.length >= 10) {
      console.log("US number", number_without_schars.slice(number_without_schars.length - 10));
      return number_without_schars.slice(number_without_schars.length - 10);
    }
  }
  return number_without_schars;
};

export const validateZipCode = (country_code: any) => {
  //iso2,
  console.log("validating country for form", country_code);
  switch (country_code) {
    case "US":
      return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
    case "CA":
      return /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
    default:
      return /^(?:[A-Z0-9]+([- ]?[A-Z0-9]{3,9})*)?$/;
  }
};

export const leadHistoryTimeFormat = (time: string) => {
  const momentTime = moment(time);
  if (momentTime.year === moment().year) {
    return `${momentTime.format("dddd, MMMM Do [at] h:mm A")} ${
      !!findTimezoneShorthand(moment.tz.guess())
        ? findTimezoneShorthand(moment.tz.guess())
        : moment().tz(moment.tz.guess()).format("z")
    }`;
  } else return momentTime.format("dddd, M/D/YYYY [at] h:mm A");
};

export const leadNotesTimeFormat = (time: string) => {
  const momentTime = moment(time);
  if (momentTime.year === moment().year) {
    return `${momentTime.format("MM/DD/YYYY hh:mm A")} ${
      !!findTimezoneShorthand(moment.tz.guess())
        ? findTimezoneShorthand(moment.tz.guess())
        : moment().tz(moment.tz.guess()).format("z")
    }`;
  } else return momentTime.format("MM/DD/YYYY hh:mm A");
};
export const formatSpokeWith = (name: string, onlyAddSpaces: boolean = false) => {
  if (!!name) {
    if (name === "DM") {
      return onlyAddSpaces ? "DM" : "Decision Maker";
    }
    if (name === "NDM") {
      return onlyAddSpaces ? "NDM" : "Non-Decision Maker";
    }
    if (name === "NoContact") {
      return "No Contact";
    }
    if (name === "DMviaNDM") {
      return onlyAddSpaces ? "DM via NDM" : "Decision Maker via NDM";
    }
  }
  return "";
};

export const formatTypeName = (name: string) => {
  if (!name) {
    return "";
  }
  switch (name) {
    case "DMviaNDM":
      return "DM via NDM";
    case "DMColdCalls":
      return "DM Cold Calls";
    case "NDMColdCalls":
      return "NDM Cold Calls";
    case "TTPerRepPerDay":
      return "TT / Rep / Day";
    case "CCPerRepPerDay":
      return "CC / Rep / Day";
    case "TTPerHour":
      return "TT / CC Connect";
    case "NDMRate":
      return "NDM Rate";
    case "DMRate":
      return "DM Rate";
    case "CCPerSet":
      return "Sets Per Rep Per Day";
    case "OTF":
      return "On The Fly Rate";
    case "LTHoldRate":
      return "LT Hold Rate";
    case "TTPerDemo":
      return "TT / Demo";
    case "LTCloseRate":
      return "LT Close Rate";
    case "RVPerSale":
      return "RV / Sale";
    case "RVPerDay":
      return "RV / Day";
    case "Row/Col":
      return "Row/Col";
    case "Pipeline":
      return "Initial Demo Events";
    case "ClosingOpportunitiesPipeline":
      return "Closing Opportunities Events";
    case "ClosingOpportunitiesScheduledRate":
      return "Closing Opp. Sched %";
    case "TotalRecurringRevenue":
      return "MRR";
    case "TotalRevenue":
      return "MRR + One-Time Fees";
    case "TotalRevenueValue":
      return "Revenue Value";
    case "TTPerHour":
      return "Revenue Value";
    case "HoldPerRepPerDay":
      return "Holds / Rep / Day";
    case "Undialed":
      return "Not Dialed This Sales Cycle";
    case "TransferCallAnswered":
      return "Transfer Call Answered";
    case "TransferCallIgnored":
      return "Transfer Call Ignored";
    case "TransferCallAnsweredDuration":
      return "Transfer Calls Answered w/ 10+ min TT";
    case "SDRTransfer":
      return "SDR Transfer";
    case "SDRHold":
      return "SDR All Holds";
    case "SDRSale":
      return "SDR All Sales";
    case "SDRLiveTransferHold":
      return "Live Transfer Hold";
    case "SDRLiveTransferSale":
      return "Live Transfer Sale";
    case "RevenuefromSDR":
      return "SDR MRR + One-Time Fees";
    case "PercentOfTransferCallAnswered":
      return "% Of Transfer Call Answered";
    case "PercentOfTransferCallAnsweredDuration":
      return "% Transfer Calls Answered w/ 10+ min TT";
    case "PercentOfHoldfromSDR":
      return "% of All Holds from SDR Demos";
    case "HoldTransferfromSDR":
      return "% of Live Transfer Calls Answered that Held";
    case "SaleTransferfromSDR":
      return "% of Live Transfer Calls Answered that Sold";
    case "PercentOfSDRTransfersHeld":
      return "% of SDR Transfers that eventually Held";
    case "PercentOfSDRTransfersSold":
      return "% of SDR Transfers that eventually Sold";
    case "PercentOfSetsRescheduled":
      return "% of Sets Rescheduled";
    case "CloseRateSDRDemo":
      return "Close Rate for SDR Demos";
    case "CloseRateLiveTransfer":
      return "Close Rate for Live Transfer SDR Demos";
    case "PercentOfSalefromSDR":
      return "% of All Sales from SDR Demos";
    case "PercentOfTotalRevenuefromSDR":
      return "% of MRR + One-Time Fees from SDR Demos";
    case "AvgRingTimeAEAnswer":
      return "Average Ring Time AE Answer";
    case "AvgAEDialedBeforeAnswer":
      return "Average AE Dialed Before Answer";
    case "AvgHoldPerTransfer":
      return "Average Hold / Transfer";
    case "AvgCloseRate":
      return "Average Close Rate";
    case "AvgSalePerTransfer":
      return "Average Sale / Transfer";
    case "AvgLiveTransferHoldsOverTransfers":
      return "Average Live Transfer Hold / Transfer";
    case "AvgLiveTransferCloseRate":
      return "Average Live Transfer Close Rate";
    case "AvgLiveTransferSalesOverTransfers":
      return "Average Live Transfer Sales / Transfer";
    case "SetRate":
      return "DM Set Rate";
    case "ScheduledCallBack":
      return "Callback";
    case "HoursRepPerDay":
      return "Hour / Rep / Day";
    case "DialsPerRepPerDay":
      return "Dials / Rep / Day";
    case "WinPerRepPerDay":
      return "Win / Rep / Day";
    case "SetsPerRepPerDay":
      return "Sets / Rep / Day";
    case "SalesPerDay":
      return "Sales / Day";
    case "RevenuePerSale":
      return "Revenue / Sale";
    case "FollowUpCloseRate":
      return "Follow-up Close Rate";
    default:
      const array = name.match(/[A-Z][a-z]+/g);
      return array?.join(" ") ?? name;
  }
};

export const formatMultipleTypeNames = (item: string) => {
  switch (item) {
    case "In":
      return "In List";
    case "NotIn":
      return "Not In List";
    case "IsNull":
      return "Is Not Known";
    case "NotNull":
      return "Is Known";
    default:
      // maybe this should just return the item.
      return formatTypeName(item);
  }
};

export enum ConditionTypes {
  Text = "Text",
  Number = "Number",
  Date = "Date",
  List = "List",
  Boolean = "Boolean",
  Rate = "Rate",
  Percentage = "Percentage",
  Dropdown = "Dropdown",
  MultiDropdown = "MultiDropdown",
}

export const handleRateAndPercentage = (type: ConditionTypes | string) => {
  if (type === "Percentage" || type === "Rate") {
    return ConditionTypes.Number;
  }
  return type;
};

const special = [
  "zeroth",
  "first",
  "second",
  "third",
  "fourth",
  "fifth",
  "sixth",
  "seventh",
  "eighth",
  "ninth",
  "tenth",
  "eleventh",
  "twelfth",
  "thirteenth",
  "fourteenth",
  "fifteenth",
  "sixteenth",
  "seventeenth",
  "eighteenth",
  "nineteenth",
];
const deca = ["twent", "thirt", "fort", "fift", "sixt", "sevent", "eight", "ninet"];

export const stringifyNumber = (n: number) => {
  if (n < 20) return special[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + "ieth";
  return deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
};

/**
 *  formats the number in a cell. Includes dashboard, stack rank chart and a few others
 * @param value
 * @param type
 * @returns
 */
export const formatCellData = (value?: any, type?: string) => {
  if (!value) {
    return `0`;
  }
  switch (type) {
    case "Dollar":
      return formatUSD(value);
    case "Percentage":
      return `${serializeLargeValues(Math.round(value * 100 * 10) / 10)}%`;
    case "Number":
      return serializeLargeValues(Math.round(value * 100) / 100);
    // return value;
    case "Ordinal":
      return formatOrdinalSuffix(parseInt(value));
    case "Date":
      return moment(value).format("MM/DD/YYYY h:mm A");
    default:
      return serializeLargeValues(Math.floor(value * 100) / 100);
    // return value;
  }
};

export const formatCellDataOld = (value?: any, type?: string, rank?: any) => {
  if (rank) {
    return `${value}`;
  }
  if (!value) {
    return `0`;
  }
  switch (type) {
    case "Dollar":
      return formatUSDOld(value);
    case "Percentage":
      return `${serializeLargeValuesOld(Math.round(value * 100 * 10) / 10)}%`;
    case "Number":
      return serializeLargeValuesOld(Math.round(value * 100) / 100);
    // return value;
    case "Date":
      return moment(value).format("MM/DD/YYYY h:mm A");
    default:
      return serializeLargeValuesOld(Math.floor(value * 100) / 100);
    // return value;
  }
};

const serializeLargeValues = (value: number) => {
  if (value >= 100_000_000_000) {
    //only add M to 12 digits and above
    return `${roundNumber(value / 1_000_000).toLocaleString()}M`;
  }
  // if (value > 1_000) {
  //   return `${roundNumber(value / 1_000).toLocaleString()}K`;
  // }
  return `${value.toLocaleString()}`;
};

const serializeLargeValuesOld = (value: number) => {
  if (value >= 1_000_000) {
    return `${roundNumber(value / 1_000_000).toLocaleString()}M`;
  }
  if (value > 1_000) {
    return `${roundNumber(value / 1_000).toLocaleString()}K`;
  }
  return `${roundNumber(value).toLocaleString()}`;
};

//transform label in ManagerGrid and SimpleGrid
export const transformColumnLabel = (header: string) => {
  switch (header) {
    // From Metric Definitions

    case "WIN/Hour":
      return "Hours of TT";
    case "Hours of TT":
      return "WIN/Hour";

    case "CC/Hour":
      return "Dials";
    case "Dials":
      return "CC/Hour";

    case "Connect Rate":
      return "Cold Calls";
    case "Cold Calls":
      return "Connect Rate";

    case "TT/CC Connect":
      return "Connects";
    case "Connects":
      return "TT/CC Connect";

    case "NDM Success Rate":
      return "NDM Connects";
    case "NDM Connects":
      return "NDM Success Rate";

    case "DM Rate":
      return "DM Connects";
    case "DM Connects":
      return "DM Rate";

    case "Set Rate":
      return "Initial Sets";
    case "Initial Sets":
      return "Set Rate";

    case "Sets/Rep/Day":
      return "Rescheduled Sets";
    case "Rescheduled Sets":
      return "Sets/Rep/Day";

    case "On the Fly Rate":
      return "All Sets";
    case "All Sets":
      return "On the Fly Rate";

    case "Days Set Out":
      return "Pipeline";
    case "Pipeline":
      return "Days Set Out";

    case "Scheduled Hold Rate":
      return "OTFs";
    case "OTFs":
      return "Scheduled Hold Rate";

    case "Initial Hold Rate":
      return "Initial Holds";
    case "Initial Holds":
      return "Initial Hold Rate";

    case "% of Sets Rescheduled":
      return "Rescheduled Holds";
    case "Rescheduled Holds":
      return "% of Sets Rescheduled";

    case "Rescheduled Hold Rate":
      return "All Holds";
    case "All Holds":
      return "Rescheduled Hold Rate";

    case "LT Hold Rate":
      return "All Sales";
    case "All Sales":
      return "LT Hold Rate";

    case "Total Recurring Revenue":
      return "Total Recurring Revenue";
    case "Total Recurring Revenue":
      return "Total Recurring Revenue";

    case "Total Revenue Value":
      return "Total Revenue Value";
    case "Total Revenue Value":
      return "Total Revenue Value";

    case "Close Rate":
      return "% to Plan (Actual)";
    case "% to Plan (Actual)":
      return "Close Rate";

    case "Sales/Day":
      return "% to Plan (Pacing)";
    case "% to Plan (Pacing)":
      return "Sales/Day";

    // From Metric Orders

    case "WIN/Rep/Hour":
      return "Hours";
    case "Hours":
      return "WIN/Rep/Hour";

    case "TT/Rep/Hour":
      return "Dials";
    case "Dials":
      return "TT/Rep/Hour";

    case "CC/Rep/Hour":
      return "Cold Calls";
    case "Cold Calls":
      return "CC/Rep/Hour";

    case "Connect Rate":
      return "Connects";
    case "Connects":
      return "Connect Rate";

    case "NDM Success Rate":
      return "NDM Cold Calls";
    case "NDM Cold Calls":
      return "NDM Success Rate";

    case "DM Cold Calls":
      return "DM Rate";
    case "DM Rate":
      return "DM Cold Calls";

    case "Set Rate":
      return "All Sets";
    case "All Sets":
      return "Set Rate";

    case "CC/Set":
      return "Initial Sets";
    case "Initial Sets":
      return "CC/Set";

    case "% of Sets Rescheduled":
      return "Rescheduled Sets";
    case "Rescheduled Sets":
      return "% of Sets Rescheduled";

    case "Scheduled Hold Rate":
      return "Scheduled Hold Rate";
    case "Pipeline":
      return "Initial Demo Events";

    case "Initial Hold Rate":
      return "Initial Holds";
    case "Initial Holds":
      return "Initial Hold Rate";

    case "Rescheduled Hold Rate":
      return "Rescheduled Holds";
    case "Rescheduled Holds":
      return "Rescheduled Hold Rate";

    case "LT Hold Rate":
      return "All Holds";
    case "All Holds":
      return "LT Hold Rate";

    case "Close Rate":
      return "All Sales";
    case "All Sales":
      return "Close Rate";

    case "Sales/Rep/Day":
      return "Total Recurring Revenue";
    case "Total Recurring Revenue":
      return "Sales/Rep/Day";

    case "Total Revenue":
      return "Total Revenue";
    case "Total Revenue":
      return "Total Revenue";

    case "RV/Sale":
      return "Total Revenue Value";
    case "Total Revenue Value":
      return "RV/Sale";

    case "RV/Rep/Day":
      return "Days";
    case "Days":
      return "RV/Rep/Day";

    default:
      return header;
  }
};

export const formatOrdinalSuffix = (i: number) => {
  let j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
};

export const getIntegrationLabel = (integration: string) => {
  switch (integration) {
    case "SalesForce":
      return "Salesforce";
    case "HubSpot":
      return "HubSpot";
    case "PandaDoc":
      return "PandaDoc";
    default:
      return integration;
  }
};

/* This function takes a HTML string and removes any specificed colors from all elements.

  @param {string} html - The HTML string to remove colors from.
  @param {string[]} colorArray - An array of colors to remove from the HTML string.
  @param {boolean} removeAllColors - If true, all colors will be removed from the HTML string regardless of the colorArray.
  @returns {string} - The modified HTML string with the colors removed.
*/
export function removeColorFormattingFromHtml({
  html,
  colorArray = [],
  removeAllColors = false,
}: {
  html: string;
  colorArray: string[];
  removeAllColors?: boolean;
}): string {
  // Create a new instance of the DOMParser class.
  const parser = new DOMParser();

  // Parse the input HTML string into a Document object.
  const doc = parser.parseFromString(html, "text/html");

  // Retrieve all elements in the parsed Document object.
  const allElements = doc.querySelectorAll("*");

  for (let i = 0; i < allElements.length; i++) {
    const element = allElements[i];
    if (element instanceof HTMLElement) {
      if (removeAllColors || colorArray.includes(element.style.color)) {
        element.style.color = "";
      }
    }
  }
  return doc.body.innerHTML;
}

/* removes the \n at the end of a string if it exists 

this was needed because quill adds a \n at the end of the text by default

@param {string} text - the string to remove the \n from
@returns {string} - the modified string
*/
export const removeNewLineAtEndOfString = (text: string) => {
  // check for a \n at the end of the text
  if (text?.length > 0 && text[text.length - 1] === "\n") {
    // remove the \n
    return text?.slice(0, text.length - 1);
  } else {
    return text;
  }
};

/*
  Ensures that the text that is being formatted is within the bounds of the text in the quill editor.

  @param {any} quill - The quill editor object.
  @param {number} index - The index of the text to format.
  @param {number} length - The length of the text to format.
  @param {string} format - The format to apply to the text e.g. "color"
  @param {string} value - The value of the format e.g. "red"
*/

export function safeFormatText(quill: QuillType, index: number, length: number, format: string, value: string): void {
  const textLength = quill.getLength() || 0;

  if (index < textLength && index + length <= textLength) {
    quill.formatText(index, length, format, value);
  }
}
/**
Applies blue formatting to tokens in a Quill editor based on the provided array of TEMPLATE_TOKENS.

it gets the text using the quill editor's getText() method and splits it into an array of words and spaces and \n characters

it formats the text using the quill editor's formatText() method

Additionally, it sets one-bracket words to black and ignores two-bracket words that are not in TEMPLATE_TOKENS.

@param {object} quill - The Quill editor instance to apply formatting to.
@param {string} source - The source of the event that triggered this function (e.g. "paste" or "user").
@param {string[]} TEMPLATE_TOKENS - An array of token strings to be formatted in blue.
@returns {void}
*/

export function applyBlueFormatingToTokensInQuill(quill: QuillType, source: string, TEMPLATE_TOKENS: string[]): void {
  if (source !== "user") {
    return;
  }

  const fullText = quill.getText() || "";
  const fullHTML = quill.root.innerHTML || "";

  // split the text into an array of words and spaces and \n characters
  let textArray: string[] = fullText?.match(/\[.*?\]|\S+|\s/g) || [];

  // split those words into smaller words if they contain a token so that way we can format the token even if it is in the middle of text
  textArray = textArray?.flatMap((text: string) => {
    const match = text.match(/\[.*?\]/g);
    if (!!match && text.length !== match[0].length) {
      const splitIndex = text.indexOf(match[0]);
      return [text.slice(0, splitIndex), text.slice(splitIndex)];
    }
    return text;
  });

  let index = 0;

  // look for all images and update the globalImageOffset from the html string
  const globalImageOffset = fullHTML?.match(/<img/g)?.length || 0;

  textArray.forEach((text: string) => {
    let localImageOffset = 0;

    // if there are images in the text before this word, update the index to account for the images
    if (globalImageOffset > 0) {
      // find the number of images in the text BEFORE this word

      // if there are multiple instances of the word in the text, we need to find the index of the word in the html string

      const numberOfInstancesOfText = fullText.split(text).length - 1;

      let indexInHTMLOFText = 0;

      // if there is only one instance of the word in the text, we can just use the index of the word in the text
      if (numberOfInstancesOfText === 1) {
        indexInHTMLOFText = fullHTML.indexOf(text);
      } else {
        // if there are multiple instances of the word in the text, we need to find the index of the word in the html string closest to the current index
        indexInHTMLOFText = fullHTML.indexOf(text, index);
      }

      // const indexInHTMLOFText = fullHTML.indexOf(text);

      const textBefore = fullHTML.slice(0, indexInHTMLOFText);

      const numberOfImagesBefore = textBefore?.match(/<img/g)?.length || 0;

      localImageOffset = numberOfImagesBefore;
    }

    // if the word is a token, set it to blue
    if (TEMPLATE_TOKENS.includes(text)) {
      safeFormatText(quill, index + localImageOffset, text.length + 1, "color", "#3e65ff");
    }

    // if the word has only one bracket, set it to black
    if ((text.startsWith("[") && !text.endsWith("]")) || (text.endsWith("]") && !text.startsWith("["))) {
      safeFormatText(quill, index + localImageOffset, text.length, "color", "black");
    }

    // if the word has two brackets but is not a token, set it to black
    if (text.startsWith("[") && text.endsWith("]") && !TEMPLATE_TOKENS.includes(text)) {
      safeFormatText(quill, index + localImageOffset, text.length, "color", "black");
    }

    // update the index

    // as a failsafe for the case where we have a rendering issue with the useEffect
    // this should be covered by the safeFormatText function, but it's here just in case
    index = fullText.length > index + text.length ? index + text.length : index;

    // set the following cursor text to black if it was blue (to avoid the following text being blue)
    if (quill.getFormat(index)["color"] === "#3e65ff") {
      safeFormatText(quill, index + localImageOffset, 1, "color", "black");
    }
  });
}

/*
 * Computes a name from a first and last name
 *
 * This is useful in sitations that you
 * 1. don't know whether you will have first or last and
 * 2. Don't want to be locked into the BE's full_name sytntax
 *  EX: (AE's <First Last> and lead's syntax of <Last, First> which will sometimes be ", first" if last name is not present)
 *
 * @param first_name - The first name.
 * @param last_name - The last name.
 * @param flipName - Optional. Whether to flip the order of the name to <Last, First> Defaults to false.
 * @returns The computed name.
 */
export const computeNameFromFirstAndLast = ({
  first_name,
  last_name,
  flipName = false,
}: {
  first_name?: string;
  last_name?: string;
  flipName?: boolean;
}): string => {
  if (!!first_name && !!last_name) {
    return flipName ? `${last_name}, ${first_name}` : `${first_name} ${last_name}`;
  }
  if (!!first_name) {
    return first_name;
  }

  if (!!last_name) {
    return last_name;
  }
  return "NA";
};

/*
 *
 *check if the email is valid
 *
 *@param {string} email - The string to check.
 *
 *@returns {boolean} - Whether the email is valid.
 *
 */
export const isValidEmail = (email: string): boolean => {
  // Regular expression pattern for email validation
  const pattern: RegExp = /^[\w\.-]+@[\w\.-]+\.\w+$/;

  return pattern.test(email);
};

export const METRIC_TYPES_WITHOUT_SPACE = [
  { label: "Set Rate", value: "SetRate" },
  { label: "Close Rate", value: "CloseRate" },
  { label: "Hold Rate", value: "InitialHoldRate" },
];

export const DATE_RANGES_WITHOUT_SPACE = [
  { label: "Today", value: "Today" },
  { label: "Yesterday", value: "Yesterday" },
  { label: "This Week", value: "LastWeek" },
  { label: "Month To Date", value: "MonthToDate" },
  { label: "Last Month", value: "LastMonth" },
  { label: "Last Three Months", value: "LastThreeMonths" },
  { label: "Year To Date", value: "YearToDate" },
  { label: "Last Year", value: "LastYear" },
];

/*
  format a time difference between two moments and returns a string of a single delination (ex: "1 year" or "2 days")

  @param biggestDelinationAllowed - the biggest delination allowed in the string this will be the start of the check
  @param smallestDelinationAllowed - the smallest delination allowed in the string this will be the end of the check
  @param specificDelination - the delination we want to always use (ex: "days" or "hours") this will override the biggest and smallest delination allowed
  @param startingTimeMoment - the moment to start the time difference from
  @param endingTimeMoment - the moment to end the time difference from
  @param showEndingText - whether to show the ending text (ex: "years")
  @param roundDown - whether to round down to a whole delination
  @param roundUp - whether to round up to a whole delination
  @param roundNumber - whether to round the number to a whole number
 

  @returns a string representing the time difference between the two moments

  @example
  formatTimeDifferenceByDelineation({
    biggestDelinationAllowed: "year",
    smallestDelinationAllowed: "minute",
    startingTimeMoment: moment().subtract(1, "year"),
    endingTimeMoment: moment(),
    showEndingText: true,
    roundDown: false,
    roundUp: false,
    roundNumber: true,
  });
  // returns "1 year"

  @example
 formatTimeDifferenceByDelineation({
    biggestDelinationAllowed: "day",
    smallestDelinationAllowed: "minute",
    startingTimeMoment: moment().subtract(1, "year"),
    endingTimeMoment: moment(),
    showEndingText: true,
    roundDown: false,
    roundUp: false,
    roundNumber: true,
    delination: undefined,
  });
  // returns "365 days"
    
*/

enum TimeDelineation {
  Year = "year",
  Day = "day",
  Hour = "hour",
  Minute = "minute",
  Second = "second",
}

export const formatTimeDifferenceByDelineation = ({
  startingTimeMoment,
  endingTimeMoment = moment(),
  biggestDelinationAllowed = TimeDelineation.Day,
  smallestDelinationAllowed = TimeDelineation.Minute,
  specificDelination,
  showEndingText = false,
  roundDown = false,
  roundUp = false,
  roundNumber = true,
}: {
  startingTimeMoment: moment.Moment;
  endingTimeMoment?: moment.Moment;
  biggestDelinationAllowed?: TimeDelineation;
  smallestDelinationAllowed?: TimeDelineation;
  specificDelination?: TimeDelineation;
  showEndingText?: boolean;
  roundDown?: boolean;
  roundUp?: boolean;
  roundNumber?: boolean;
}): string => {
  // Input validation
  if (!startingTimeMoment || !startingTimeMoment?.isValid()) {
    console.error("startingTimeMoment is required in calculateTimeDifferenceV2");
    return "NA";
  }

  if (!endingTimeMoment || !endingTimeMoment?.isValid()) {
    console.error("endingTimeMoment is required in calculateTimeDifferenceV2");
    return "NA";
  }

  // Check if startingTimeMoment is after endingTimeMoment and swap if necessary

  let swappedTimes = false;
  if (startingTimeMoment.isAfter(endingTimeMoment)) {
    swappedTimes = true;
    [startingTimeMoment, endingTimeMoment] = [endingTimeMoment, startingTimeMoment];
  }

  // Main logic
  let currentDelineation = specificDelination || biggestDelinationAllowed;
  let result = "";

  while (currentDelineation !== undefined) {
    const timeDifference = endingTimeMoment.diff(startingTimeMoment, currentDelineation, true);
    const greaterThanOneUnit = timeDifference >= 1;
    const specificDelinationSpecified = !!specificDelination;

    if (greaterThanOneUnit || currentDelineation === smallestDelinationAllowed || specificDelinationSpecified) {
      let finalNumber = 1;

      if (roundDown) {
        finalNumber = Math.floor(timeDifference);
      } else if (roundUp) {
        finalNumber = Math.ceil(timeDifference);
      } else if (roundNumber) {
        finalNumber = Math.round(timeDifference);
      } else {
        // round to nearest 100th place
        finalNumber = Math.round(timeDifference * 100) / 100;
      }

      result += `${finalNumber}`;

      if (showEndingText) {
        result += ` ${currentDelineation}${finalNumber > 1 ? "s" : finalNumber === 0 ? "s" : ""}`;
        if (swappedTimes) {
          result += " in the future.";
        } else {
          result += " ago.";
        }
      }

      return result;
    } else {
      // Go to the next delineation
      const delineations = Object.values(TimeDelineation);
      const currentIndex = delineations.indexOf(currentDelineation);

      // if the next delineation is undefined break out of the loop
      if (currentIndex + 1 >= delineations.length) {
        break;
      }

      currentDelineation = delineations[currentIndex + 1] as TimeDelineation;
    }
  }

  return "NA";
};

export const DAYS_OF_THE_WEEK = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const METRIC_TYPES = [
  { label: "Set Rate", value: "SetRate" },
  { label: "Close Rate", value: "CloseRate" },
  { label: "Hold Rate", value: "InitialHoldRate" },
];

export const DATE_RANGES = [
  { label: "Today", value: "Today" },
  { label: "Yesterday", value: "Yesterday" },
  { label: "This Week", value: "LastWeek" },
  { label: "Month To Date", value: "MonthToDate" },
  { label: "Last Month", value: "LastMonth" },
  { label: "Last Three Months", value: "LastThreeMonths" },
  { label: "Year To Date", value: "YearToDate" },
  { label: "Last Year", value: "LastYear" },
];

interface generateCommunicationToggleTooltipProps {
  type: "sms" | "email";
  toggled: boolean;
  leadLeadAction?: boolean;
  isAdmin: boolean;
}

export const generateCommunicationToggleTooltip = ({
  type,
  toggled,
  leadLeadAction,
}: generateCommunicationToggleTooltipProps) => {
  if (toggled) {
    return `Lead is subscribed to ${type} communications.`;
  }

  // Lead Unsubscribed case
  if (leadLeadAction) {
    switch (type) {
      case "sms":
        return `This prospect messaged "STOP" and can only be opted back in to ${type} communications if they message "START"`;
      case "email":
        return `This prospect unsubscribed from ${type} communications through the unsubscribe link in an email. Sellfire Enablement users are able to opt them back in.`;
      default:
        return `This prospect unsubscribed from ${type} communications.`;
    }
  }

  // Sellfire Unsubscribed case
  return `Lead was opted out of ${type} communications. Sellfire Enablement users are able to opt them back in.`;
};

export enum CommunicationStates {
  "Subscribed" = "Subscribed",
  "OPSIQUnsubscribed" = "OPSIQUnsubscribed",
  "LeadUnsubscribed" = "LeadUnsubscribed",
}

export const EMAIL_UNSUBSCRIBED_REP_TOOLTIP = "Lead is currently unsubscribed from email communications.";
export const SMS_UNSUBSCRIBED_REP_TOOLTIP = "Lead is currently unsubscribed from SMS communications.";

export const convertSyncRuleToLabel = (label: string, integration_label: string) => {
  switch (label) {
    case "ExternalToOpsiq":
      return `Always use ${integration_label}`;
    case "OpsiqToExternal":
      return `Always use Sellfire`;
    case "Both":
      return `Two-way`;
    case "None":
      return `Don't sync`;
    default:
      return "No rule set";
  }
};

/**
 * Formats the business name, returning "N/A" if the name is not provided or is empty.
 * @param {string} [name] - The business name to format.
 * @returns {string} - The formatted business name or "N/A" if the name is not provided or is empty.
 *
 * @example
 * formatBusinessName("Some Business Name")
 * // returns "Some Business Name"
 *
 * @example
 * formatBusinessName("")
 * // returns "N/A"
 */

export const formatBusinessName = (name?: string): string => {
  if (!name || !name.trim()) {
    return "N/A";
  }
  return name;
};

// these are the types of contacts that a lead can have
//if we update this please update the enum and the getContactLabel function

export enum IChannel {
  "Phone" = "Phone",
  "Email" = "Email",
  "Conference" = "Conference",
}
/**
 * @param {string} channel
 *
 * @returns {string} the label for the channel type (contact type. e.g. Phone, Email, Conference)
 *
 * @description mainly for the screen sharing number Conference -> Screen Sharing Number
 *
 * @example findChannelLabel("Conference") -> "Screen Sharing Number"
 *
 * @example findChannelLabel("Phone") -> "Phone"
 *
 **/
export const getChannelLabel = (channel: IChannel) => {
  switch (channel) {
    case IChannel.Conference:
      return "Screen Sharing Number";
    default:
      return channel;
  }
};

export const formatSequenceAction = (action: string): { icon: string; text: string } => {
  let icon: string;
  let text: string;
  switch (action) {
    case "manualCall":
      icon = phone_call;
      text = "Phone Call";
      break;
    case "manualEmail":
      icon = mail;
      text = "Email";
      break;
    case "manualSms":
      icon = chat;
      text = "SMS";
      break;
    case "customTask":
      icon = settings;
      text = "Custom Task";
      break;
    case "requestManagerFeedback":
      icon = info;
      text = "Request Manager Feedback";
      break;
    default:
      icon = phone_call;
      text = "Phone Call";
      break;
  }
  return { icon, text };
};

export const getTitle = (header: string, mrrLabel: any) => {
  switch (header) {
    case "MRR":
      return mrrLabel?.getMrrLabel || "MRR";
    case "CloseDate":
      return "Close Date";
    case "BusinessName":
      return "Business Name";
    case "FirstName":
      return "First Name";
    case "LastName":
      return "Last Name";
    case "SubIndustry":
      return "Sub Industry";
    case "LeadSource":
      return "Lead Source";
    case "LastCallResult":
      return "Last Call Result";
    case "LastCallResultRep":
      return "Last Call Result Rep";
    case "LastCallResultTime":
      return "Last Call Result Time";
    case "LeadCreationSource":
      return "Lead Creation Source";
    case "LeadCreationDate":
      return "Lead Creation Date";
    case "PrimaryEmail":
      return "Primary Email";
    case "PrimaryPhone":
      return "Primary Phone";
    case "ZipCode":
      return "Zip Code";
    case "LastLeadAssignmentOriginTime":
      return "Last Lead Assignment Origin Time";
    case "NextScheduledEvent":
      return "Next Scheduled Event";
    case "AssociateCount":
      return "Associate Count";
    case "channels":
      return "Channel";
    default:
      return header;
  }
};

/* generate the embeddable html for the IBC widget for the user to copy to their website

@param {string} session_token - the session token for the widget (this is fetched from the BE)

@returns {string} - the html code for the user to copy to their website
*/

export const generateIBCEmbeddableHTML = (session_token: string) => {
  // set the html code for the user to copy to their website

  // set the html code for the user to copy to their website
  // the current version of the widget. this is only used for cache busting in the case of major changes
  const currentWidgetVersion = "1.0.1";

  // the current environment bas
  const BASE_URL = process.env.REACT_APP_WIDGET_CODE_BASE_URL;

  return `<!-- INBOUND CONCIERGE WIDGET -->  <link href="${BASE_URL}/index.css" rel="stylesheet" />  <script src="${BASE_URL}/index.js?version=${currentWidgetVersion}" defer></script>  <div class="sellfire_widget" data-session-id="${session_token}"></div>`;
};

/* generate the embeddable html for the Sellfire Form for the user to copy to their website

@param {string} session_token - the session token for the widget (this is fetched from the BE)

@returns {string} - the html code for the user to copy to their website
*/
export const generateOPSIQFormsEmbeddableHTML = (session_token: string) => {
  // set the html code for the user to copy to their website
  // the current version of the form. this is only used for cache busting in the case of major changes
  const currentWidgetVersion = "1.0.1";

  // the current environment base url
  const BASE_URL = process.env.REACT_APP_FORMS_CODE_BASE_URL;

  return `<!-- SELLFIRE FORMS WIDGET -->  <link href="${BASE_URL}/index.css" rel="stylesheet" />  <script src="${BASE_URL}/index.js?version=${currentWidgetVersion}" defer></script>  <div class="sellfire_form" data-session-id="${session_token}"></div>`;
};

export const LEAD_DATA_TO_NEVER_DISPLAY = ["id", "hidden_status", "hidden_rep_id"];

/* 
  formats the SMS type label for displaying in the lead detail sms tab
  @param {string} type - The type of SMS message.
  @returns {string} - The formatted SMS type label.
*/
export const formatSMSTypeLabel = (type: string) => {
  switch (type) {
    case "SMS Received":
      return "Manual SMS";
    case "SMS Sent":
      return "Manual SMS";
    default:
      return type;
  }
};
