import React, { useContext } from "react";
import styled from "styled-components";
import { useMutation, gql } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Link } from "react-router-dom";

import { Formik, FormikProps } from "formik";
import * as Yup from "yup";

import { appToast } from "src/utils/toast";
import { CallContext } from "src/context";
import { initMixpanel } from "src/services/mixpanel";
import { isLoggedInVar, loggedInUser } from "src/apollo/cache";
import { mobileSize } from "src/utils/breakpoints";
import { Loading, FlexDiv, GradientButton, GhostField, AppText } from "src/Components/UI";
import { restAPI } from "src/apollo";
import { setSentry } from "src/services/sentry";
import { theme } from "src/utils/theme";
import { TOKEN_PERSIST_KEY, USER_PERSIST_KEY } from "src/utils/variables";
import { ReactComponent as SellfireLogo } from "src/images/NewDesign/sellfire_logo.svg";

interface MyFormikProps {
  username: string;
  password: string;
}

const loginSchema = Yup.object().shape({
  username: Yup.string().required("Username or email is required"),
  password: Yup.string().required("Please specify a password").max(50, "* Too Long!"),
});

export const LOGIN_USER = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      email
      first_name
      last_name
      session_token
      role
      status
      organization_id
      team_id
      team_name
      manager_id
      manager {
        id
        first_name
        last_name
      }
      phone_number
      timezone
      profile_image
      organization {
        id
        name
        onboardComplete
        international
        mrr_label
        enable_rep_to_connect_customers
        allow_external_transfer
      }
      joinMe_connected
      created_at
      timekit_id
      timekit_type
      panda_doc_access
      dashboard_2_access
      visible_all_leads_sm
      allow_external_transfer
    }
  }
`;

const Login: React.FC = () => {
  const { refreshToken } = useContext(CallContext);
  const [login, { loading, error }] = useMutation(LOGIN_USER, {
    fetchPolicy: "no-cache",
    onCompleted: async ({ login }) => {
      console.log("login: ", login);
      if (!login) {
        return;
      }
      localStorage.setItem(TOKEN_PERSIST_KEY, login.session_token as string);
      localStorage.setItem("timezone", login.timezone as string);
      localStorage.setItem(USER_PERSIST_KEY, JSON.stringify(login));
      localStorage.setItem("user_id", login.id as string);
      localStorage.setItem("profile_image", login.profile_image as string);
      localStorage.setItem("user_full_name", (login.first_name + " " + login.last_name) as string);
      localStorage.setItem("organization_id", login.organization_id as string);
      localStorage.setItem("international", login.organization.international as string);
      localStorage.setItem("userStatusLocal", JSON.stringify("IDLE"));
      restAPI.defaults.headers = { authorization: login.session_token };

      // loggedInUser(login);
      await refreshToken();
      isLoggedInVar(true);
      loggedInUser(login);
      setSentry();
      initMixpanel();
      // initLogRocket();
    },
    onError({ message }) {
      console.log("Error logging in: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `login GraphQL Error: ${message}`,
      });
    },
  });

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured on the Login page"}>
      <Main>
        <Formik
          initialValues={{ username: "", password: "" }}
          validationSchema={loginSchema}
          onSubmit={({ username, password }) => {
            login({ variables: { email: username, password: password } });
          }}
        >
          {({ submitForm }: FormikProps<MyFormikProps>) => {
            return (
              <FormContainer
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <FlexDiv direction="column" align="center" gap={24}>
                  <LogoWrapper>
                    <SellfireLogo />
                  </LogoWrapper>

                  <HeaderText>
                    OPSIQ is now <span>Sellfire</span>
                  </HeaderText>
                </FlexDiv>

                <FlexDiv direction="column" gap={40} width="100%">
                  <FlexDiv direction="column" gap={24} width="100%">
                    <GhostField label="Email" placeholder="Enter your email address" name="username" />
                    <GhostField label="Password" placeholder="Enter your password" name="password" type="password" />
                  </FlexDiv>

                  <FlexDiv direction="column" align="center" justify="center" gap={16} width="100%">
                    {loading ? (
                      <Loading />
                    ) : (
                      <>
                        <GradientButton onClick={submitForm}>Login</GradientButton>
                        <FlexDiv direction="column" align="center" justify="center" gap={16}>
                          <ForgotPasswordText to="/forgot-password/">Forgot password?</ForgotPasswordText>
                          <AppText fontSize={16} fontWeight={600}>
                            <a
                              style={{ border: "none", textDecoration: "none", color: theme.text.neutral.inverse }}
                              href="https://www.sellfire.com/privacy"
                              target="_blank"
                            >
                              Privacy Policy
                            </a>
                          </AppText>
                        </FlexDiv>
                      </>
                    )}
                  </FlexDiv>
                </FlexDiv>
              </FormContainer>
            );
          }}
        </Formik>
      </Main>
    </Sentry.ErrorBoundary>
  );
};

const Main = styled.div`
  align-items: center;
  background-color: #08122e;
  background-image: url(${require("src/images/login-bg.jpeg")});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  overflow-y: auto;
  padding: 24px;
  width: 100%;

  @media ${mobileSize} {
    background-image: url(${require("src/images/login-bg-mobile.jpeg")});
  }
`;

const FormContainer = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  max-width: 560px;
  width: 100%;
`;

const LogoWrapper = styled.div`
  align-items: center;
  border-radius: 15px;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 80px;
  background: linear-gradient(90deg, #0282f926 0%, #1aabff26 100%);
`;

const HeaderText = styled.p`
  color: ${theme.WHITE_COLOR};
  font-family: ${theme.PRIMARY_FONT};
  font-size: 64px;
  text-align: center;
  white-space: nowrap;

  span {
    background: linear-gradient(90deg, #0282f9 0%, #1aabff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  @media ${mobileSize} {
    font-size: 35px;
  }
`;

const ForgotPasswordText = styled(Link)`
  color: ${theme.text.neutral.inverse};
  font-size: 16px;
  font-weight: 600;
  margin-top: 16px;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
`;

export { Login };
