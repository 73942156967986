import styled from "styled-components";
import * as React from "react";
import { AppButton, AppText, AppInput, AppSelect, Loading, AppErrorText } from "../UI";
import { Modal } from "./Modal";
import { theme } from "../../utils/theme";
import * as Yup from "yup";
import * as Sentry from "@sentry/react";
import { useState, useContext, useEffect } from "react";
import { NewAppButton } from "../UI/NewAppButton";
import { ModalContext } from "../../context";
import { newCloseModal, iconDashesStroke } from "../../images";
import { Formik, FormikProps, setIn } from "formik";
import { FormMultiSelectField, FormSelectField, InputField } from "../Field";
import { useMutation, useQuery, useLazyQuery, gql } from "@apollo/client";
import { appToast, errorToast } from "../../utils/toast";
import { IIntegrations } from "../../types";

interface FieldsData {
  id: string;
  external_object: string;
  external_field: string;
  external_field_label: string;
  opsiq_object: string;
  opsiq_field: string;
  opsiq_field_label: string;
  sync_rule: string;
  trigger_events: {
    label: string;
    value: string;
  }[];
  mapping_type: string;
}
interface DisappearingDivProps {}

const ADD_OR_UPDATE_INTEGRATION_MAPPING = gql`
  mutation addorUpdateIntegrationMapping(
    $mapping_id: String
    $integration_type: INTEGRATION_TYPE!
    $mapping: IntegrationMappingInput!
  ) {
    addorUpdateIntegrationMapping(mapping_id: $mapping_id, integration_type: $integration_type, mapping: $mapping) {
      id
      opsiq_object
      opsiq_field
      opsiq_field_label
      opsiq_field_type
      external_object
      external_field
      external_field_label
      sync_rule
      trigger_events
      mapping_type
      integration_type
      organization_id
      created_at
      updated_at
    }
  }
`;

const REMOVE_INTEGRATION_MAPPING = gql`
  mutation removeIntegrationMapping($mapping_id: String!) {
    removeIntegrationMapping(mapping_id: $mapping_id)
  }
`;

const FETCH_INTEGRATION_MAPPING_OPTIONS = gql`
  query fetchIntegrationMappingOptions(
    $integration: INTEGRATION_TYPE!
    $template_id: String
    $event_types: [SYSTEM_EVENT!]!
  ) {
    fetchIntegrationMappingOptions(integration: $integration, template_id: $template_id, event_types: $event_types)
  }
`;
const FETCH_INTEGRATION_MAPPING_DATA = gql`
  query fetchIntegrationMappingData($integration: INTEGRATION_TYPE!, $external_object: String!) {
    fetchIntegrationMappingData(integration: $integration, external_object: $external_object) {
      field_label
      field_id
      type
      custom
    }
  }
`;

const IntegrationMapRules: React.FC<DisappearingDivProps> = ({}) => {
  const {
    showIntegrationMapRulesModal,
    setShowIntegrationMapRulesModal,
    mapStateData,
    selectedIntegration,
    selectedIntegrationLabel,
    editIntegrationType,
    templateId,
    resetMapStateData,
  } = useContext(ModalContext);
  const [step, setStep] = useState(
    // if pandadoc is selected, then we need to skip the first step
    selectedIntegration === "PandaDoc" || selectedIntegration === "HubSpot" ? 1 : 0,
  );
  const [deleteModal, setDeleteModal] = useState(false);
  const [externalField, setExternalField] = useState("");
  const [eventTypes, seteventTypes] = useState(mapStateData.trigger_events);

  const [addorUpdateIntegrationMapping, { loading }] = useMutation(ADD_OR_UPDATE_INTEGRATION_MAPPING, {
    onCompleted({ addorUpdateIntegrationMapping }) {
      if (!addorUpdateIntegrationMapping) {
        appToast("Error: Something went wrong!");
        return;
      }
      setShowIntegrationMapRulesModal(false);
      appToast("Updated mappings");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `addorUpdateIntegrationMapping GraphQL Error: ${message}`,
      });
      console.log("Error in addorUpdateIntegrationMapping: ", message);
    },
    refetchQueries: ["fetchIntegrationMappings"],
  });

  const [removeIntegrationMapping, { loading: loadingDelete }] = useMutation(REMOVE_INTEGRATION_MAPPING, {
    onCompleted({ removeIntegrationMapping }) {
      if (!removeIntegrationMapping) {
        appToast("Error: Something went wrong!");
        return;
      }
      setDeleteModal(false);
      setShowIntegrationMapRulesModal(false);
      appToast("Mapping deleted successfully");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `removeIntegrationMapping GraphQL Error: ${message}`,
      });
      console.log("Error in removeIntegrationMapping: ", message);
    },
    refetchQueries: ["fetchIntegrationMappings"],
  });

  const [fetchOptions, { data: dataOptions, loading: loadingOptions, error: errorOptions }] = useLazyQuery(
    FETCH_INTEGRATION_MAPPING_OPTIONS,
    {
      variables: {
        integration: selectedIntegration,
        template_id: templateId,
        event_types: eventTypes,
      },
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const [
    fetchDataFieldOptions,
    { data: dataFieldOptions, loading: loadingFieldOptions, error: errorFieldOptions },
  ] = useLazyQuery(FETCH_INTEGRATION_MAPPING_DATA, {
    fetchPolicy: "network-only",
    onError({ message, name }) {},
  });

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    mapDataField(externalField || mapStateData.external_object);
  }, [externalField]);

  const mapSchema = Yup.object().shape({
    id: Yup.string(),
    external_object: Yup.string(),
    external_field: Yup.string(),
    external_field_label: Yup.string(),
    opsiq_object: Yup.string(),
    opsiq_field: Yup.string(),
    opsiq_field_label: Yup.string(),
    opsiq_field_type: Yup.string(),
    sync_rule: Yup.string(),
    trigger_events: Yup.array().of(Yup.string()),
    mapping_type: Yup.string(),
  });

  const findMappingTypeFromObjectField = (
    integration_type: string,
    external_object: string,
    external_field: string,
  ) => {
    try {
      if (integration_type === "SalesForce") {
        return dataFieldOptions?.fetchIntegrationMappingData
          ?.slice()
          .filter((item: any) => item.field_id === external_field)[0]?.type;
      }
      const type = dataOptions?.fetchIntegrationMappingOptions?.external[external_object]
        ?.slice()
        .filter((item: any) => item.field_id === external_field)[0]?.type;
      return type;
    } catch (error) {
      return "";
    }
  };

  const mapDataField = (external_object: string) => {
    try {
      fetchDataFieldOptions({ variables: { external_object, integration: selectedIntegration } });
    } catch (error) {}
  };

  const getDataField = (values: any) => {
    if (dataFieldOptions?.fetchIntegrationMappingData?.length) {
      const data_field_options = dataFieldOptions?.fetchIntegrationMappingData
        ?.map((field: any) => ({ label: field.field_label, value: field.field_id }))
        .sort((a: any, b: any) => (a.label < b.label ? -1 : 1));

      if (!data_field_options.length) {
        return [{ label: "No Options Found", value: "" }];
      }
      return data_field_options;
    }
    return !!values.external_object
      ? !!dataOptions?.fetchIntegrationMappingOptions?.external[values.external_object]?.length
        ? dataOptions?.fetchIntegrationMappingOptions?.external[values.external_object]
            ?.map((field: any) => ({ label: field.field_label, value: field.field_id }))
            ?.sort((a: any, b: any) => (a.label < b.label ? -1 : 1))
        : [{ label: "No Options Found", value: "" }]
      : [{ label: `Select ${selectedIntegration} Object first`, value: "" }];
  };

  const findMappingType = (editIntegrationType: string) => {
    switch (editIntegrationType) {
      case "System":
        return "SystemField";
      case "Custom":
        return "CustomField";
      case "Static":
        return "Static";
      default:
        return "";
    }
  };

  const optionsRules = [
    {
      enum: "ExternalToOpsiq",
      rule: `Always use ${selectedIntegrationLabel} unless blank`,
      description: `Sellfire will always be updated with the most recent value from ${selectedIntegrationLabel}.`,
    },
    {
      enum: "OpsiqToExternal",
      rule: `Always use Sellfire`,
      description: `${selectedIntegrationLabel} will always be updated with the most recent value from Sellfire.`,
    },
    {
      enum: "Both",
      rule: "Two-way",
      description: `Both Sellfire and ${selectedIntegrationLabel} will always use the most recent value available.`,
    },
    {
      enum: "None",
      rule: "Don’t Sync",
      description: `Changes to this property in either Sellfire or ${selectedIntegrationLabel} will not be detected or updated in the corresponding system.`,
    },
  ];

  const findFieldLabelFromIDExternal = (id: string, object: string) => {
    try {
      if (dataFieldOptions?.fetchIntegrationMappingData.length) {
        const field_data = [...dataFieldOptions?.fetchIntegrationMappingData];
        const field_type = field_data.filter((item: any) => item.field_id === id);
        return field_type[0].field_label;
      }
      const type = dataOptions?.fetchIntegrationMappingOptions?.external[object]
        ?.slice()
        .filter((item: any) => item.field_id === id)[0]?.field_label;
      return type;
    } catch (error) {
      return "";
    }
  };

  const findFieldLabelFromIDOpsiq = (id: string, object: string) => {
    try {
      const type = dataOptions?.fetchIntegrationMappingOptions?.opsiq[object]
        ?.slice()
        .filter((item: any) => item.field_id === id)[0]?.field_label;
      return type || "";
    } catch (error) {
      return "";
    }
  };

  const findFieldTypeFromOpsiqFields = (id: string, object: string) => {
    try {
      const type = dataOptions?.fetchIntegrationMappingOptions?.opsiq[object]
        ?.slice()
        .filter((item: any) => item.field_id === id)[0]?.type;
      return type || "";
    } catch (error) {
      return "";
    }
  };

  const returnIfExtraStep = (trigger_events: { label: string; value: string }[]) => {
    return (
      trigger_events.findIndex((event) => event.value === "NewLead") !== -1 ||
      trigger_events.findIndex((event) => event.value === "HoldActivity") !== -1
    );
  };

  const renderNumberOfSteps = (trigger_events: { label: string; value: string }[]) => {
    switch (selectedIntegration) {
      case "SalesForce":
        return returnIfExtraStep(trigger_events) ? "3" : "2";
      case "HubSpot":
        return "2";
      case "PandaDoc":
        return "1";
      default:
        return "UNRECOGNIZED INTEGRATION";
    }
  };

  const getTriggerEventLabel = (event: string) => {
    switch (event) {
      case "Sale":
        return "New Sale";
      case "NewLead":
        return "Lead Create/Update";
      case "InboundHold":
        return "Inbound Hold";
      default:
        return event;
    }
  };

  const handleSyncRule = ({
    values,
    item,
    setFieldValue,
  }: {
    values: FieldsData;
    item: { enum: string; rule: string; description: string };
    setFieldValue: Function;
  }) => {
    if (values.opsiq_field === "lead_ownership_status" || values.opsiq_field === "last_call_result") {
      if (item.enum === "OpsiqToExternal") {
        return setFieldValue("sync_rule", "OpsiqToExternal");
      }
      errorToast("Selected Sellfire field is only supported for outbound sync (Always use Sellfire)");
    } else {
      return setFieldValue("sync_rule", item.enum);
    }
  };

  const fetchOPSIQObjectOptions = (values: FieldsData) => {
    return dataOptions?.fetchIntegrationMappingOptions?.opsiq
      ? Object.keys(dataOptions?.fetchIntegrationMappingOptions?.opsiq)
          .filter((name) => {
            if (values.external_object === "Opportunity") {
              return true;
            }
            return name !== "Reference";
          })
          ?.map((name: string) => ({ label: name, value: name }))
          .sort((a, b) => (a.label < b.label ? -1 : 1))
      : [];
  };

  return (
    <Sentry.ErrorBoundary fallback={"An error has in skip lead modal"}>
      <Formik
        initialValues={{
          id: mapStateData?.id,
          external_object: mapStateData.external_object,
          external_field: mapStateData.external_field,
          external_field_label: mapStateData.external_field_label,
          opsiq_object: mapStateData.opsiq_object,
          opsiq_field: mapStateData.opsiq_field,
          opsiq_field_label: mapStateData.opsiq_field_label,
          sync_rule: mapStateData.sync_rule,
          trigger_events: mapStateData.trigger_events.length
            ? mapStateData.trigger_events?.map((event) => ({
                label: getTriggerEventLabel(event),
                value: event,
              }))
            : [],
          mapping_type: mapStateData?.mapping_type,
          opsiq_field_type: mapStateData.opsiq_field_type,
        }}
        onSubmit={async (values) => {
          const test = findFieldLabelFromIDExternal(values.external_field, values.external_object);
          await addorUpdateIntegrationMapping({
            variables: {
              mapping_id: !!values?.id ? values.id : undefined,
              integration_type: selectedIntegration,
              mapping: {
                external_object: values.external_object,
                external_field: values.external_field,
                external_field_label: findFieldLabelFromIDExternal(values.external_field, values.external_object),
                opsiq_object: values.opsiq_object,
                opsiq_field: editIntegrationType === "Static" ? values.opsiq_field_label : values.opsiq_field,
                opsiq_field_label:
                  editIntegrationType === "Static"
                    ? values.opsiq_field_label
                    : findFieldLabelFromIDOpsiq(values.opsiq_field, values.opsiq_object),
                opsiq_field_type: findFieldTypeFromOpsiqFields(values.opsiq_field, values.opsiq_object),
                sync_rule:
                  // pandadoc is alwasy OpsiqToExternal and the default for when we don't set a value is opsIQToExternal
                  selectedIntegration === "PandaDoc"
                    ? "OpsiqToExternal"
                    : values.sync_rule
                    ? values.sync_rule
                    : "OpsiqToExternal",
                events: selectedIntegration === "SalesForce" ? values.trigger_events?.map((event) => event.value) : [],
                mapping_type: !!values.id ? values?.mapping_type : findMappingType(editIntegrationType),
                external_field_type: findMappingTypeFromObjectField(
                  selectedIntegration as IIntegrations,
                  values.external_object,
                  values.external_field,
                ),
                external_template_id: templateId,
              },
            },
          });
        }}
        validationSchema={mapSchema}
      >
        {({ submitForm, setFieldValue, isValid, isSubmitting, values }: FormikProps<FieldsData>) => {
          return (
            <PopupContainerDiv>
              {deleteModal ? (
                <Modal open={showIntegrationMapRulesModal} onClose={() => setShowIntegrationMapRulesModal(false)}>
                  <PaddingDeleteDiv>
                    <DeleteText>Are you sure you want to cancel?</DeleteText>
                    <DeleteText style={{ fontWeight: "normal", marginTop: "4px" }}>
                      This will delete the mapping of {values.opsiq_field_label} and {values.external_field_label}.
                    </DeleteText>
                  </PaddingDeleteDiv>
                  <SubmitDiv>
                    <DeleteMapButton
                      style={{ color: theme.PRIMARY500, marginRight: "12px" }}
                      onClick={() => setDeleteModal(false)}
                    >
                      No, Go Back
                    </DeleteMapButton>
                    <DeleteMapButton
                      disabled={loadingDelete}
                      onClick={async () =>
                        await removeIntegrationMapping({
                          variables: {
                            mapping_id: values.id,
                          },
                        })
                      }
                    >
                      Yes, Cancel Mapping
                    </DeleteMapButton>
                  </SubmitDiv>
                </Modal>
              ) : (
                <Modal open={showIntegrationMapRulesModal} onClose={() => setShowIntegrationMapRulesModal(false)}>
                  <TitleDiv>
                    <TitleText>
                      {!!values.id ? `Edit ${editIntegrationType} Field Mapping` : `Map a ${editIntegrationType} Field`}
                    </TitleText>
                  </TitleDiv>
                  <PaddingDiv>
                    <SectionTitle>
                      {step === 0 && "Select an Action for Integration Mapping"}
                      {step === 1 && `Map OPSIQ field to ${selectedIntegrationLabel} field`}
                      {step === 2 && "Select Sync Rule"}
                    </SectionTitle>
                    {step === 0 && (
                      <DropdownsContainer>
                        {loadingOptions ? (
                          <Loading />
                        ) : errorOptions ? ( // <AppErrorText>Error loading options</AppErrorText>
                          <ErrorDiv>
                            <AppErrorText>ERROR</AppErrorText>
                            <AppText style={{ margin: "1rem 0" }}>
                              We were unable to fetch field mappings from {selectedIntegrationLabel} at this time.
                              Please wait a moment to try again.
                            </AppText>
                            <ErrorButton variant={"primary"} onClick={() => fetchOptions()}>
                              {loadingOptions ? <Loading /> : "Try Again"}
                            </ErrorButton>
                          </ErrorDiv>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "300px",
                              }}
                            >
                              <DropdownLabel>Action</DropdownLabel>
                              <FormMultiSelectField
                                name="trigger_events"
                                options={
                                  dataOptions?.fetchIntegrationMappingOptions?.events
                                    ? dataOptions?.fetchIntegrationMappingOptions?.events
                                        ?.map((event: { label: string; value: string }) => ({
                                          label: event.label,
                                          value: event.value,
                                        }))
                                        .sort((a: any, b: any) => (a.label < b.label ? -1 : 1))
                                    : []
                                }
                                onChange={(e: any) => {
                                  if (e?.length && e?.map((el: any) => el.value).includes("InboundHold")) {
                                    setFieldValue(`opsiq_object`, "HoldActivity");
                                  }
                                  setFieldValue(`trigger_events`, e);
                                  seteventTypes(e?.map((el: any) => el.value));
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </DropdownsContainer>
                    )}
                    {step === 1 &&
                      (loadingOptions ? (
                        <Loading />
                      ) : errorOptions ? (
                        // <AppErrorText>Error loading options</AppErrorText>
                        <ErrorDiv>
                          <AppErrorText>ERROR</AppErrorText>
                          <AppText style={{ margin: "1rem 0" }}>
                            We were unable to fetch field mappings from {selectedIntegrationLabel} at this time. Please
                            wait a moment to try again.
                          </AppText>
                          <ErrorButton variant={"primary"} onClick={() => fetchOptions()}>
                            {loadingOptions ? <Loading /> : "Try Again"}
                          </ErrorButton>
                        </ErrorDiv>
                      ) : (
                        <DropdownsContainer>
                          <DropdownsGrid>
                            <div>
                              <DropdownLabel>Sellfire Object</DropdownLabel>
                              <FormSelectField name="opsiq_object" options={fetchOPSIQObjectOptions(values)} />
                            </div>
                            <div>
                              <img src={iconDashesStroke} alt="Maps to" />
                            </div>
                            <div>
                              <DropdownLabel>{selectedIntegrationLabel} Field Category</DropdownLabel>
                              <FormSelectField
                                allowSelectPlaceholder
                                placeholder="Select field category"
                                name="external_object"
                                onChange={(e: any) => {
                                  setExternalField(e.target.value);
                                  setFieldValue("external_object", e.target.value);
                                }}
                                options={
                                  dataOptions?.fetchIntegrationMappingOptions?.external
                                    ? Object.keys(dataOptions?.fetchIntegrationMappingOptions?.external)
                                        ?.map((name: string) => ({ label: name, value: name }))
                                        .sort((a, b) => (a.label < b.label ? -1 : 1))
                                    : []
                                }
                              />
                            </div>
                            <div>
                              <DropdownLabel>Sellfire Field</DropdownLabel>
                              {editIntegrationType === "Static" ? (
                                <MapInputField name="opsiq_field_label" />
                              ) : (
                                <FormSelectField
                                  name="opsiq_field"
                                  allowSelectPlaceholder
                                  placeholder="Select field"
                                  disabled={!values.opsiq_object}
                                  options={
                                    !!values.opsiq_object &&
                                    !!dataOptions?.fetchIntegrationMappingOptions?.opsiq[values.opsiq_object]?.length
                                      ? dataOptions?.fetchIntegrationMappingOptions?.opsiq[values.opsiq_object]
                                          .slice()
                                          .filter((item: any) =>
                                            editIntegrationType === "Custom" ? item.custom : !item.custom,
                                          )
                                          // .sort((a: any, b: any) => (a.field_label > b.field_label ? 1 : -1))
                                          ?.map((field: any) => ({ label: field.field_label, value: field.field_id }))
                                          .sort((a: any, b: any) => (a.label < b.label ? -1 : 1))
                                          ?.map((item: any) => JSON.stringify(item))
                                          .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i)
                                          ?.map((item: any) => JSON.parse(item))
                                      : [{ label: "Select OPSIQ Object first", value: "" }]
                                  }
                                />
                              )}
                            </div>
                            <div>
                              <img src={iconDashesStroke} alt="Maps to" />
                            </div>
                            <div>
                              <DropdownLabel>{selectedIntegration} Field</DropdownLabel>
                              {loadingFieldOptions ? (
                                <Loading />
                              ) : (
                                <FormSelectField
                                  name="external_field"
                                  disabled={
                                    !(
                                      values.external_object &&
                                      (dataFieldOptions?.fetchIntegrationMappingData?.length ||
                                        dataOptions?.fetchIntegrationMappingOptions?.external[values.external_object]
                                          ?.length)
                                    )
                                  }
                                  allowSelectPlaceholder
                                  placeholder={`Select ${selectedIntegration} Object first`}
                                  options={getDataField(values)}
                                />
                              )}
                            </div>
                          </DropdownsGrid>
                        </DropdownsContainer>
                      ))}
                    {step === 2 && (
                      <OptionsContainer>
                        {optionsRules?.map((item) => (
                          <OptionContainer
                            selected={item.enum === values.sync_rule}
                            disabled={
                              values.opsiq_field === "lead_ownership_status" ||
                              values.opsiq_field === "last_call_result"
                            }
                            is_outbound={item.enum === "OpsiqToExternal"}
                            onClick={() => {
                              handleSyncRule({ item, setFieldValue, values });
                            }}
                          >
                            <CenterDiv>
                              <RadioBorder selected={item.enum === values.sync_rule}>
                                <RadioButton selected={item.enum === values.sync_rule} />
                              </RadioBorder>
                            </CenterDiv>
                            <div style={{ padding: "24px", paddingLeft: "0px" }}>
                              <OptionTitleText>{item.rule}</OptionTitleText>
                              <OptionDescriptionText>{item.description}</OptionDescriptionText>
                            </div>
                          </OptionContainer>
                        ))}
                      </OptionsContainer>
                    )}
                  </PaddingDiv>
                  {selectedIntegration === "PandaDoc" ? (
                    <SubmitDiv>
                      <AppText></AppText>
                      <FlexDiv>
                        {values.id && <DeleteButton onClick={() => setDeleteModal(true)}>Delete</DeleteButton>}
                        <ConfirmButton
                          type="submit"
                          disabled={loading}
                          variant={"primary"}
                          onClick={() => {
                            submitForm();
                            setShowIntegrationMapRulesModal(false);
                            resetMapStateData();
                          }}
                        >
                          Save
                        </ConfirmButton>
                      </FlexDiv>
                    </SubmitDiv>
                  ) : (
                    <SubmitDiv>
                      <AppText>
                        {/* not ideal should refactor ASAP */}
                        {selectedIntegration === "SalesForce"
                          ? step + 1
                          : selectedIntegration === "HubSpot"
                          ? step
                          : step - 1}{" "}
                        of {renderNumberOfSteps(values.trigger_events)}
                      </AppText>
                      {step === 0 ||
                      (selectedIntegration === "HubSpot"
                        ? step === 1 // Saleforce integration has an extra step if trigger_events includes "NewLead" or "InboundLead"
                        : step === 1 && returnIfExtraStep(values.trigger_events)) ? (
                        <FlexDiv>
                          {values.id && <DeleteButton onClick={() => setDeleteModal(true)}>Delete</DeleteButton>}
                          <ConfirmButton type="submit" variant={"primary"} onClick={() => setStep(step + 1)}>
                            Next
                          </ConfirmButton>
                        </FlexDiv>
                      ) : (
                        <FlexDiv>
                          {values.id && <DeleteButton onClick={() => setDeleteModal(true)}>Delete</DeleteButton>}
                          <ConfirmButton
                            type="submit"
                            variant={"primary"}
                            disabled={loading}
                            onClick={() => submitForm()}
                          >
                            Save
                          </ConfirmButton>
                        </FlexDiv>
                      )}
                    </SubmitDiv>
                  )}
                </Modal>
              )}
            </PopupContainerDiv>
          );
        }}
      </Formik>
    </Sentry.ErrorBoundary>
  );
};

const MapInputField = styled(InputField)`
  margin: 0px;
`;

const DeleteText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  width: 100%;
`;

const DeleteMapButton = styled(NewAppButton)`
  height: 40px;
  width: 100%;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  color: ${theme.ATTENTION700};
`;

const PaddingDeleteDiv = styled.div`
  padding: 48px 58px;
`;

const FlexDiv = styled.div`
  display: flex;
`;

const DropdownsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 70px);
  min-width: 500px;
`;

interface SelectedProp {
  selected: boolean;
}

const RadioBorder = styled.div<SelectedProp>`
  border: 1px solid ${(props) => (props.selected ? theme.PRIMARY500 : theme.NEUTRAL200)};
  border-radius: 50%;
`;

const RadioButton = styled.div<SelectedProp>`
  width: 18px;
  height: 18px;
  margin: 3px;
  border-radius: 50%;
  background-color: ${(props) => (props.selected ? theme.PRIMARY500 : "transparent")};
`;

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  margin-top: 24px;
  /* margin-bottom: 12px; */
`;

const OptionTitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
`;

const OptionDescriptionText = styled(AppText)`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
`;

interface OptionContainerProp {
  selected: boolean;
  disabled: boolean;
  is_outbound: boolean;
}

const OptionContainer = styled.div<OptionContainerProp>`
  border: 1px solid ${(props) => (props.selected ? theme.PRIMARY500 : theme.NEUTRAL200)};
  border-radius: 4px;
  cursor: ${(props) => {
    if (props.is_outbound) {
      return "pointer";
    } else if (!props.disabled) {
      return "pointer";
    }
    return "cursor";
  }};
  display: grid;
  grid-template-columns: 88px 1fr;
`;

const DropdownLabel = styled(AppText)`
  font-weight: 600;
  margin-bottom: 12px;
`;

const DropdownsGrid = styled.div`
  display: grid;
  grid-template-columns: 272px 37px 272px;
  /* align-items: center; */
  grid-gap: 6px;
  margin: 0px 40px;
  /* height: calc(100% - 70px); */
  align-items: center;
  /* margin-top: 200px; */
`;

const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  margin-top: 8px;
  width: 100%;
  text-align: center;
`;

const GridTitleText = styled(AppText)`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
`;

const GridCellText = styled(AppText)`
  /* font-weight: 600; */
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  max-width: 368px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const GridCellClickText = styled(GridCellText)`
  font-weight: 600;
  color: ${theme.ATTENTION700};
  border-bottom: none;
  cursor: pointer;
`;

const GridTable = styled.table`
  cursor: auto;
  font-size: 10px;
  font-weight: 500;
  border-collapse: collapse;
  padding: 5px;
  /* min-width: 1040px; */
  width: 100%;
  /* :first-child {
    min-width: 240px;
    max-width: 240px;
  } */
`;

const GridTD = styled.td`
  white-space: nowrap;
  border: 1px solid ${theme.NEUTRAL200};
  line-height: 30px;
  font-size: 8px;
  padding: 11px 16px;
  /* cursor: pointer; */
  text-align: center;
  background: ${theme.WHITE_COLOR};
`;

const GridTH = styled.th`
  border: 1px solid ${theme.NEUTRAL300};
  padding: 11px 16px;
  /* height: 160px; */
  white-space: nowrap;
  /* transform: translate(2px, 55px) rotate(270deg); */
  min-width: 40px;
  max-width: 40px;
  background: ${theme.NEUTRAL200};
  /* :first-child {
    transform: translate(0px, 0px) rotate(0deg);
    min-width: 232px;
    border: 0px solid ${theme.NEUTRAL200};
  } */
`;

const SubmitDiv = styled.div`
  padding: 24px;
  border-top: 1px solid ${theme.NEUTRAL200};
  background-color: ${theme.NEUTRAL100};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const CustomSelect = styled(AppSelect)`
  text-align: left;
  border-color: ${theme.NEUTRAL100};
  :focus {
    border-color: ${theme.BLACK_COLOR};
  }
`;

const PaddingDiv = styled.div`
  padding: 24px;
  height: 572px;
  max-height: 60vh;
  overflow: auto;
`;

const ConfirmText = styled(AppText)`
  font-size: 14px;
  text-align: center;
  /* margin-bottom: 20px; */
  width: 100%;
`;

const LabelText = styled(AppText)`
  padding: 3px;
  text-align: left;
`;

const ConfirmButton = styled(NewAppButton)`
  /* margin-bottom: 16px;
  margin-top: 8px; */
  height: 40px;
  width: 120px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
`;

const DeleteButton = styled(NewAppButton)`
  height: 40px;
  width: 120px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  color: ${theme.ATTENTION700};
  margin-right: 12px;
`;

const SkipButton = styled(NewAppButton)`
  height: 48px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
`;

const CardTitle = styled.div`
  min-width: 100%;
  max-height: 20px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  background-color: ${theme.NEUTRAL100};
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
`;

interface ModalOnProps {
  blinds?: boolean;
}

const PopupContainerDiv = styled.div`
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
  z-index: 9;
`;

const ErrorDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 2rem 0;
  border: 1px solid ${theme.NEUTRAL100};
  padding: 2rem;
`;

const ErrorButton = styled(NewAppButton)`
  :active {
    background-color: ${theme.PRIMARY800};
  }
`;

export { IntegrationMapRules };
