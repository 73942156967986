import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Formik, FormikProps } from "formik";
import { debounce } from "lodash";
import * as React from "react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { CORE_LEAD_CARD_DATA } from "../../../apollo/fragments";
import { SHOW_LEAD_SOURCE_TO_USER } from "../../../apollo/query";
import { CallContext, ModalContext, SelfSourceLeadContext } from "../../../context";
import { SelectedContactType } from "../../../types";
import {
  SYSTEM_FIELD_CHAR_LIMIT,
  SYSTEM_FIELD_EMAIL_CHAR_LIMIT,
  extractNumberWithCountry,
  isValidEmail,
} from "../../../utils/format";
import { recursiveObjectTrim } from "../../../utils/misc";
import { PHONE_REGEX } from "../../../utils/regex";
import { theme } from "../../../utils/theme";
import { appToast, errorToast } from "../../../utils/toast";
import { FormSelectField, InputField } from "../../Field";
import { LeadCardV2 } from "../../Segments/DashboardSideBarSegments";
import { AppButton, AppSidebarCard, AppText, Loading, NewAppSelect } from "../../UI";
import { NewAppButton } from "../../UI/NewAppButton";
import { Modal } from "../../modal";
import { VALIDATE_PHONE_NUMBER } from "../CallSegments";
import { StateDropdown } from "../LeadCard/StateDropdown";
import { sortedCountryCodesData } from "../../../static/countries";
import { MixpanelActions } from "../../../services/mixpanel";
import { PhoenixMultiSelectField } from "../../Field/Phoenix";
import { loggedInUser } from "../../../apollo/cache";

interface DisappearingDivProps {
  visible: boolean;
  close: () => void;
  refetch: (key?: any) => Promise<any>;
}

interface MyFormikProps {
  first_name?: string;
  last_name?: string;
  business_name: string;
  phone_number: string;
  email: string;
  lead_source: string;
  industry: string;
  sub_industry: string;
  source_type: string;
  city: string;
  state: string;
  country_code: { label: string; value: { iso2: string; code: string } };
}

const REASSIGN_LEAD = gql`
  mutation reassignLead(
    $lead_id: String!
    $dest_user_id: String!
    $source_user_id: String
    $override: Boolean
    $set_own: Boolean
  ) {
    reassignLead(
      lead_id: $lead_id
      dest_user_id: $dest_user_id
      source_user_id: $source_user_id
      override: $override
      set_own: $set_own
    ) {
      id
    }
  }
`;

const source_type_options = [
  {
    label: "Inbound",
    value: "Inbound",
  },
  {
    label: "Outbound",
    value: "Outbound",
  },
];

const GET_POSSIBLE_INDUSTRIES_DATA = gql`
  query fetchIndustryOptions {
    fetchIndustryOptions {
      id
      label
      sub_industries
    }
  }
`;

const GET_POSSIBLE_LEAD_SOURCES_DATA = gql`
  query fetchLeadSourceOptions {
    fetchLeadSourceOptions {
      id
      label
    }
  }
`;

const FETCH_LEAD = gql`
  ${CORE_LEAD_CARD_DATA}
  query fetchLead($id: String!) {
    fetchLead(id: $id) {
      ...CoreLeadCardData
      id
      first_name
      last_name
      full_name
      primary_phone_number
      local_primary_phone_number
      primary_phone_number_country_code
      primary_email
      business_name
      industry
      sub_industry
      lead_source
      channel
      status
      title
      content
      city
      country
      state
      timezone_by_state
      address
      zip
      rep_id
      visible_custom_fields {
        id
        key
        value
        visible
        boo_value
        num_value
        type
        lead_id
        options
        computed_value
        list_value
        allow_reps_to_edit
      }
      alternate_contacts {
        id
        title
        channel
        type
        value
      }
      call_notes {
        id
        notes
        created_at
      }
      ...CoreLeadCardData
    }
  }
`;

export const ADD_LEAD = gql`
  mutation addLead(
    $first_name: String
    $last_name: String
    $self_sourced: Boolean
    $business_name: String
    $phone_number: String!
    $email: String
    $industry: String
    $sub_industry: String
    $lead_source: String
    $source_type: CHANNEL
    $status: String
    $title: String
    $content: String
    $city: String
    $state: String
    $country: String
    $country_code: String
    $claim: Boolean
  ) {
    addLead(
      first_name: $first_name
      last_name: $last_name
      business_name: $business_name
      self_sourced: $self_sourced
      phone_number: $phone_number
      email: $email
      industry: $industry
      sub_industry: $sub_industry
      lead_source: $lead_source
      source_type: $source_type
      status: $status
      title: $title
      content: $content
      city: $city
      state: $state
      country: $country
      country_code: $country_code
      claim: $claim
    ) {
      id
      lead {
        id
      }
    }
  }
`;

const CHECK_LEAD_PHONE_NUMBER = gql`
  query checkLeadPhoneNumber($phone_number: String!, $country_code: String!) {
    checkLeadPhoneNumber(phone_number: $phone_number, country_code: $country_code)
  }
`;

const CLAIM_LEAD = gql`
  mutation addLead(
    $first_name: String
    $last_name: String
    $self_sourced: Boolean
    $business_name: String
    $phone_number: String!
    $email: String
    $industry: String
    $sub_industry: String
    $lead_source: String
    $source_type: CHANNEL
    $status: String
    $title: String
    $content: String
    $city: String
    $state: String
    $country: String
    $country_code: String
    $claim: Boolean
  ) {
    addLead(
      first_name: $first_name
      last_name: $last_name
      business_name: $business_name
      self_sourced: $self_sourced
      phone_number: $phone_number
      email: $email
      industry: $industry
      sub_industry: $sub_industry
      lead_source: $lead_source
      source_type: $source_type
      status: $status
      title: $title
      content: $content
      city: $city
      state: $state
      country: $country
      country_code: $country_code
      claim: $claim
    ) {
      id
      lead {
        id
      }
    }
  }
`;

const optionsLoading = [
  {
    label: "Loading...",
    value: "",
  },
];

interface SelfSourceProps {
  /**
   * The id of the lead intent
   */
  intentId?: string;
  title?: string;
  setSelectedContact: (selectedContact: SelectedContactType) => void;

  collapseLead?: () => void;
  callState?: boolean;
  isSearchDashboard?: boolean;
  openCustomFieldsModal: () => void;
  closeOtherModals?: any;
  setSelfSource: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelfSourceLeadV2: React.FC<SelfSourceProps> = (props) => {
  const { reassignLeadModal, setReassignLeadModal, selectedLeadToReassign, setSelectedLeadToReassign } = useContext(
    ModalContext,
  );
  const { selfSourceLeadId, setSelfSourceLeadId, selfSourceIntentId, setSelfSourceIntentId } = useContext(
    SelfSourceLeadContext,
  );
  const { setNextActionOverride } = useContext(CallContext);
  const [claimModal, setClaimModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const phone_number_without_chars = extractNumberWithCountry(phoneNumber);
  const { data: showLeadSourceToUser } = useQuery(SHOW_LEAD_SOURCE_TO_USER);

  //set to 1 for US coutntry code (matches formik default)
  const [countryCode, setCountryCode] = useState("1");

  const [oldPhoneValue, setOldPhoneValue] = useState("");
  const [phoneNumberVariant, setPhoneNumberVariant] = useState<"error" | "primary" | "success" | "warning" | undefined>(
    undefined,
  );
  const history = useHistory();
  const formikRef = useRef() as any;

  const industryRequired = useMemo(() => {
    // hardcoded for surefire. This will be configurable by org eventually.
    return loggedInUser()?.organization_id === "1236a64a-c7cc-48e5-9282-aa049373e1b9";
  }, []);

  const debouncedChange = useMemo(
    () =>
      debounce((value) => {
        setPhoneNumber(value);
      }, 500),
    [],
  );

  const [claimLead, { loading: claimLoading }] = useMutation(CLAIM_LEAD, {
    onCompleted({ addLead }) {
      console.log("addLead: ", addLead);
      if (!addLead) {
        appToast("Error claiming lead. Something went wrong.");
        return;
      }
      // setIntentId(addLead?.id);
      // fetchSSLead({ variables: { id: addLead?.lead?.id } });
      setSelfSourceIntentId(addLead?.id);
      setSelfSourceLeadId(addLead?.lead?.id);
      // fetchSSLead({ variables: { id: addLead?.lead?.id } });
      setClaimModal(false);
      appToast("Claimed lead!");
    },
    onError({ message, name }) {
      Sentry.captureEvent({
        message: `claimLead GraphQL Error: ${message}`,
      });
      errorToast(message);
      console.log(`Error in ${name}: `, message);
    },
  });

  const [addLead, { loading, error }] = useMutation(ADD_LEAD, {
    async onCompleted({ addLead }) {
      console.log("addLead: ", addLead);
      if (!addLead) {
        setClaimModal(true);
        return;
      }
      // setIntentId(addLead.id);
      // fetchSSLead({ variables: { id: addLead.lead.id } });
      setSelfSourceIntentId(addLead.id);
      setSelfSourceLeadId(addLead.lead.id);
      // fetchSSLead({ variables: { id: addLead.lead.id } });
      setClaimModal(false);
      appToast("Lead created!");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `addLead GraphQL Error: ${message}`,
      });
      console.log("Error in createNewLead: ", message);
    },
  });

  const { data: leadsourceData, loading: leadsourceLoading, error: leadsourceError } = useQuery(
    GET_POSSIBLE_LEAD_SOURCES_DATA,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: industriesData, loading: industriesLoading, error: industriesError } = useQuery(
    GET_POSSIBLE_INDUSTRIES_DATA,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  useEffect(() => {
    if (!!formikRef?.current?.values?.phone_number && !!formikRef?.current?.values?.country_code?.value?.code) {
      debouncedChange(formikRef?.current?.values?.phone_number);
    } else {
      //reset the color if does not fit bounds to "reset" without recalling CHECK_LEAD_PHONE_NUMBER
      setPhoneNumberVariant(undefined);
    }
  }, [formikRef?.current?.values?.phone_number, formikRef?.current?.values?.country_code?.value?.code]);

  const [
    checkLeadPhoneNumber,
    { data: leadPhoneNumber, loading: leadPhoneNumberLoading, error: leadPhoneNumberError },
  ] = useLazyQuery(CHECK_LEAD_PHONE_NUMBER, {
    fetchPolicy: "network-only",
    variables: {
      phone_number: phone_number_without_chars,
      country_code: countryCode,
    },
    onCompleted({ checkLeadPhoneNumber }) {
      switch (checkLeadPhoneNumber?.type) {
        case "NOT_IN_SYSTEM":
          return setPhoneNumberVariant("success");
        case "IN_SYSTEM_CLAIMABLE":
          return setPhoneNumberVariant("warning");
        case "IN_SYSTEM_TAKEN":
          return setPhoneNumberVariant("error");
        default:
          return setPhoneNumberVariant(undefined);
      }
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });
  const [validatePhoneNumber] = useMutation(VALIDATE_PHONE_NUMBER);

  const newLeadSchema = Yup.object().shape({
    first_name: Yup.string()
      .notRequired()
      .trim()
      .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
    last_name: Yup.string()
      .notRequired()
      .trim()
      .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
    business_name: Yup.string()
      .required("Field is required")
      .trim()
      .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
    phone_number: Yup.string()
      .required("Field is required")
      .max(15, "Must be valid phone number")
      .min(7, "Must be valid phone number")
      .matches(PHONE_REGEX, "Must be a valid phone number")
      .test("phone_number", "", async (raw_phone_number: any) => {
        const phone_number = extractNumberWithCountry(raw_phone_number);
        if (!!phone_number && phone_number.length >= 7 && phone_number.length <= 11 && phone_number !== oldPhoneValue) {
          setOldPhoneValue(phone_number);
          checkLeadPhoneNumber({
            variables: {
              phone_number: phone_number || "",
              country_code: countryCode,
            },
          });
          // return isPhoneValid.data.validatePhoneNumber;
        }
        return true;
      })
      .when("country_code", {
        is: (country_code) => country_code?.value?.iso2 === "US",
        then: Yup.string().length(10, "US phone numbers must be 10 digits"),
      }),
    email: Yup.string()
      .notRequired()
      .email()
      .trim()
      .max(SYSTEM_FIELD_EMAIL_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_EMAIL_CHAR_LIMIT} characters or less`)
      .test("email", "Must be a valid email", (value) => {
        if (!!value) {
          return isValidEmail(value) ?? true;
        }
        return true;
      }),
    lead_source: Yup.string().notRequired(),
    source_type: Yup.string().required(),
    industry: industryRequired ? Yup.string().required() : Yup.string().notRequired(),
    sub_industry: Yup.string().notRequired(),
    city: Yup.string().notRequired().trim(),
    state: Yup.string().required().trim(),
    country_code: Yup.object()
      .required()
      .shape({
        label: Yup.string(),
        value: Yup.object().shape({ iso2: Yup.string(), code: Yup.string() }),
      }),
  });

  const { data: leadData, loading: leadLoading, error: leadError } = useQuery(FETCH_LEAD, {
    variables: {
      id: selfSourceLeadId,
    },
    fetchPolicy: "network-only",
  });

  if (leadsourceError) return <p>Error loading Lead Sources</p>;
  if (industriesError) return <p>Error loading Lead Sources</p>;

  const returnSubIndustryOptions = (industry: string) => {
    if (industriesLoading) {
      return optionsLoading;
    }
    if (!!industry) {
      return industriesData.fetchIndustryOptions
        .slice()
        .filter((item: any) => item.label === industry)[0]
        .sub_industries?.map((ls: string) => ({ label: ls, value: ls }))
        ?.sort((a: any, b: any) => (a?.label < b?.label ? -1 : 1));
    } else {
      return [{ label: "Select Primary Industry", value: "" }];
    }
  };

  if (leadLoading || leadError) return <Loading />;
  if (!selfSourceLeadId) {
    return (
      <Formik
        innerRef={formikRef}
        initialValues={{
          first_name: "",
          last_name: "",
          business_name: "",
          phone_number: "",
          email: "",
          lead_source: "",
          source_type: "",
          industry: "",
          sub_industry: "",
          city: "",
          state: "",
          country_code: { label: "United States (1)", value: { iso2: "US", code: "1" } },
        }}
        validationSchema={newLeadSchema}
        validateOnMount
        onSubmit={async (values) => {
          console.log("Sent data: ", values);

          recursiveObjectTrim(values);

          await addLead({
            variables: {
              first_name: values.first_name,
              last_name: values.last_name,
              business_name: values.business_name,
              phone_number: extractNumberWithCountry(values.phone_number, values.country_code?.value?.iso2),
              email: values.email,
              lead_source: values.lead_source,
              industry: values.industry,
              sub_industry: values.sub_industry,
              city: values.city,
              state: values.state ?? undefined,
              country: values.country_code?.value?.iso2 ?? undefined,
              country_code: values.country_code?.value?.code ?? undefined,
              self_sourced: true,
            },
          });
        }}
      >
        {({
          errors,
          submitForm,
          values,
          setFieldValue,
          isSubmitting,
          isValid,
          touched,
        }: FormikProps<MyFormikProps>) => {
          return (
            <>
              {claimModal && (
                <Modal
                  open={claimModal}
                  onClose={() => setClaimModal(false)}
                  // width={432}
                  // height={260}
                >
                  <PaddingDiv>
                    <ConfirmText style={{ fontWeight: 600 }}>Lead Already Exists</ConfirmText>
                    <ConfirmText>
                      This lead already exists, but is unassigned.
                      <br />
                      Would you like claim this lead?
                    </ConfirmText>
                  </PaddingDiv>
                  <SubmitDiv2>
                    <SkipButton onClick={() => setClaimModal(false)}>No, Go Back</SkipButton>
                    <ConfirmButton
                      variant={"primary"}
                      disabled={claimLoading}
                      onClick={async () => {
                        await claimLead({
                          variables: {
                            phone_number: extractNumberWithCountry(values.phone_number),
                            country_code: values.country_code?.value?.code,
                            claim: true,
                          },
                        });
                        // await claimLead({
                        //   variables: {
                        //     primary_phone_number: extractNumberWithCountry(values.phone_number),
                        //     country_code: values.country_code?.value?.code,
                        //   },
                        // });
                        MixpanelActions.track("Add Self Sourced Lead", { type: "submit", view: "Dashboard" });
                      }}
                    >
                      Yes, Claim Lead
                    </ConfirmButton>
                  </SubmitDiv2>
                </Modal>
              )}
              <Container>
                <TitleDiv>
                  <NewAppButton
                    // small
                    // borderless
                    onClick={() => {
                      props.setSelfSource(false);
                      props.closeOtherModals();

                      setSelfSourceIntentId("");
                      setSelfSourceLeadId("");
                    }}
                    style={{ textAlign: "left", marginLeft: 16 }}
                  >
                    Back
                  </NewAppButton>

                  <div>
                    <TitleText>Create or Claim Lead</TitleText>
                  </div>
                  <SubtextDiv>
                    <TitleText>
                      Enter your lead's primary phone number and country first to determine if they are in Sellfire
                    </TitleText>
                  </SubtextDiv>
                </TitleDiv>
                <ScrollingDiv>
                  <PopupInputLabel>
                    Primary Phone Number <span style={{ color: "red" }}>*</span>
                  </PopupInputLabel>
                  <NumberExtensionDiv>
                    {!!values.country_code?.value?.code && (
                      <ExtensionText>+{values.country_code?.value.code}</ExtensionText>
                    )}
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                      <LeadInput
                        variant={
                          !!leadPhoneNumberError || (!!errors.phone_number && touched.phone_number)
                            ? "error"
                            : phoneNumberVariant
                        }
                        onBlur={() => {
                          const cleanPhoneNumber = values.phone_number.match(/\d/g)?.join("");
                          setFieldValue("phone_number", cleanPhoneNumber);
                        }}
                        name="phone_number"
                        type="tel"
                      />
                      <div style={{ marginTop: -20 }}>
                        <AppText fontSize={12} variant={phoneNumberVariant}>
                          {!errors.phone_number &&
                            !!values.country_code &&
                            leadPhoneNumber?.checkLeadPhoneNumber?.message}
                        </AppText>
                      </div>
                    </div>
                  </NumberExtensionDiv>

                  <PopupInputLabel>
                    Country <span style={{ color: "red" }}>*</span>
                  </PopupInputLabel>
                  <PhoenixMultiSelectField
                    name="country_code"
                    isMulti={false}
                    options={sortedCountryCodesData}
                    isClearable={false}
                    value={sortedCountryCodesData.find(
                      (option) => option.value.code === values.country_code.value.code,
                    )}
                    placeholder={values.country_code.label}
                    onChange={(e: any) => {
                      setCountryCode(e?.value.code);
                      setFieldValue("country_code", !!e?.value ? { label: e?.label, value: e?.value } : {});
                      setFieldValue("state", "");
                    }}
                  />
                  <Divider />
                  {
                    <FilterDiv
                      disabled={!!leadPhoneNumberError || !!errors.phone_number || phoneNumberVariant !== "success"}
                    >
                      <PopupInputLabel>Contact First Name</PopupInputLabel>
                      <LeadInput name="first_name" />
                      <PopupInputLabel>Contact Last Name</PopupInputLabel>
                      <LeadInput name="last_name" />
                      <PopupInputLabel>
                        Business Name <span style={{ color: "red" }}>*</span>
                      </PopupInputLabel>
                      <LeadInput
                        name="business_name"
                        variant={!!errors.business_name && touched.business_name ? "error" : "primary"}
                      />
                      <PopupInputLabel>Primary Email</PopupInputLabel>
                      <LeadInput name="email" />
                      {showLeadSourceToUser?.showLeadSourceToUser && (
                        <>
                          <PopupInputLabel>Lead Source</PopupInputLabel>
                          <PhoenixMultiSelectField
                            name="lead_source"
                            isMulti={false}
                            isClearable
                            options={
                              leadsourceLoading
                                ? optionsLoading
                                : leadsourceData.fetchLeadSourceOptions?.map((ls: any) => ({
                                    label: ls.label,
                                    value: ls.label,
                                  }))
                            }
                            value={
                              !leadsourceLoading && !!values.lead_source
                                ? leadsourceData?.fetchLeadSourceOptions?.find(
                                    (option: any) => option.value === values.lead_source,
                                  )
                                : ""
                            }
                            onChange={(e: any) => {
                              setFieldValue(`lead_source`, e?.value);
                            }}
                          />
                        </>
                      )}
                      <PopupInputLabel>
                        Source Type <span style={{ color: "red" }}>*</span>
                      </PopupInputLabel>
                      <PhoenixMultiSelectField
                        isMulti={false}
                        isClearable={false}
                        name="source_type"
                        options={source_type_options}
                        value={source_type_options.find((option) => option.value === values.source_type)}
                        onChange={(e: any) => {
                          setFieldValue("source_type", e?.value);
                        }}
                        hideErrorMessage
                      />
                      <PopupInputLabel>
                        Industry {industryRequired && <span style={{ color: "red" }}>*</span>}
                      </PopupInputLabel>
                      <PhoenixMultiSelectField
                        name="industry"
                        isClearable
                        isMulti={false}
                        options={
                          industriesLoading
                            ? optionsLoading
                            : industriesData.fetchIndustryOptions?.map((ls: any) => ({
                                label: ls.label,
                                value: ls.label,
                              }))
                        }
                        value={industriesData?.fetchIndustryOptions?.find(
                          (option: any) => option.value === values.industry,
                        )}
                        onChange={(e: any) => {
                          setFieldValue(`industry`, !!e?.value ? e?.value : "");
                          if (!e?.value && values.sub_industry) {
                            setFieldValue("sub_industry", "");
                          }
                        }}
                      />
                      <PopupInputLabel>Sub-Industry</PopupInputLabel>
                      <PhoenixMultiSelectField
                        name="sub_industry"
                        isClearable
                        isMulti={false}
                        isDisabled={!values.industry}
                        options={returnSubIndustryOptions(values.industry)}
                        value={
                          returnSubIndustryOptions(values.industry).find(
                            (option: any) => option.value === values.sub_industry,
                          ) || ""
                        }
                        onChange={(e: any) => {
                          setFieldValue(`sub_industry`, !!e?.value ? e?.value : "");
                        }}
                      />
                      <PopupInputLabel>City</PopupInputLabel>
                      <LeadInput name="city" />
                      <PopupInputLabel>
                        State/Province <span style={{ color: "red" }}>*</span>
                      </PopupInputLabel>
                      <StateDropdown
                        callback={setFieldValue}
                        country_code={values.country_code?.value?.iso2}
                        current_value={values.state}
                      />
                    </FilterDiv>
                  }
                </ScrollingDiv>

                <SubmitDiv>
                  {phoneNumberVariant === "warning" ? (
                    <NewAppButton variant={"primary"} size={"lg"} onClick={() => setClaimModal(true)}>
                      Claim Lead
                    </NewAppButton>
                  ) : (
                    <NewAppButton
                      variant={"primary"}
                      size={"lg"}
                      onClick={submitForm}
                      disabled={
                        isSubmitting ||
                        !isValid ||
                        !!leadPhoneNumberError ||
                        !!errors.phone_number ||
                        phoneNumberVariant === "error"
                      }
                    >
                      {isSubmitting ? <Loading color={theme.WHITE_COLOR} /> : "Create & Claim Lead"}
                    </NewAppButton>
                  )}
                </SubmitDiv>
              </Container>
            </>
          );
        }}
      </Formik>
    );
  } else {
    return (
      <LeadResultContainer>
        <AppSidebarCard title={"New Lead"}>
          <LeadCardV2
            parentType={"searchLead"}
            expandOnRender
            leadData={leadData?.fetchLead}
            closeOtherModals={props.closeOtherModals}
            isSelfSourcedLead={true}
            handleSelfSourcedLeadPriorityClick={() => props.setSelfSource(false)}
            handleSelfSourcedLeadNextDialClick={() => {
              setNextActionOverride({
                intent_object: {
                  id: selfSourceIntentId,
                  lead: {
                    id: leadData?.fetchLead?.id,
                    primary_phone_number: leadData?.fetchLead?.primary_phone_number,
                  },
                },
                parent: "searchLead",
              });
              props.setSelfSource(false);
            }}
          />
        </AppSidebarCard>
      </LeadResultContainer>
    );
  }
};

const SubmitDiv2 = styled.div`
  padding: 16px 24px;
  border-top: 1px solid ${theme.NEUTRAL200};
  background-color: ${theme.NEUTRAL100};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
`;

const ConfirmText = styled(AppText)`
  font-size: 14px;
  text-align: center;
  /* margin-bottom: 20px; */
  width: 100%;
`;

const ConfirmButton = styled(NewAppButton)`
  /* margin-bottom: 16px;
  margin-top: 8px; */
  height: 48px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
`;

const SkipButton = styled(NewAppButton)`
  height: 48px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
`;

const ModalPopupContainerDiv = styled.div`
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
  z-index: 9;
`;

const SubmitDiv = styled.div`
  width: 100%;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 25px;
  top: 6px;
  cursor: pointer;
  z-index: 5;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const SubtextDiv = styled.div`
  margin-top: 26px;
  text-align: center;
  width: 376px;
`;

const TitleDiv = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ExtensionText = styled(AppText)`
  margin: 15px;
  margin-top: 10px;
  height: 40px;
  font-size: 13px;
`;

const NumberExtensionDiv = styled.div`
  display: flex;
`;

interface ModalOnProps {
  blinds?: boolean;
}

const PopupContainerDiv = styled.div<ModalOnProps>`
  position: fixed;
  display: ${(props) => (props.blinds ? "block" : "none")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
  z-index: 10;
`;

const LeadSelect = styled(FormSelectField)`
  /* height: 45px; */
  /* border-radius: 2.9px; */
  /* border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  } */
`;

const WhitespaceDiv = styled.div`
  height: 50px;
`;

const PopupInputLabel = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 13px;
  margin-left: 5px;
`;

const ReturnToDashboard = styled(AppButton)``;

const LeadInput = styled(InputField)`
  margin: 0px;
`;

const AddEmailCancelButton = styled(AppButton)`
  font-size: 10px;
  font-weight: 600;
  width: 136px;
  height: 32px;
  border-radius: 2px;
  text-transform: uppercase;
  margin: auto;
`;

const AddEmailButton = styled(AppButton)`
  width: 264px;
  height: 45px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  margin: 25px auto;
`;

const PopupTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 27px;
`;

interface ModalProps {
  width?: number;
  stickBottom?: boolean;
}

const PopupModal = styled.div<ModalProps>`
  position: absolute;
  top: 104px;
  bottom: ${(props) => (props.stickBottom ? 0 : "none")};
  left: ${(props) => `calc((100vw - ${props.width}px) / 2)` || "calc(50vw - (453px / 2))"};
  width: ${(props) => `${props.width}px` || "100%"};
  width: 453px;
  left: calc(50vw - 226.5px);
  border-radius: 7px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  height: auto;
  background: ${theme.WHITE_COLOR};
  border: 4px solid ${theme.NEUTRAL100};
  z-index: 3;
`;

const LeadsButton = styled(AppButton)`
  width: 144px;
  height: 32px;
  border-radius: 2.9px;
  border: solid 1px ${theme.NEUTRAL200};
  margin: 11px;
  font-size: 11px;
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const TopAlignDiv = styled.div`
  min-height: 400px;
  max-height: 450px;
  overflow: auto;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SaveAndContinueButton = styled(AppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
`;

const Divider = styled.div`
  width: 100%;
  margin: 22px 0px 32px 0px;
  background-color: ${theme.NEUTRAL200};
  height: 1px;
`;

type FilterDivProps = {
  disabled: boolean;
};

const FilterDiv = styled.div<FilterDivProps>`
  ${(props) => {
    if (props.disabled) {
      return `filter: blur(5px);
      pointer-events: none;`;
    }
  }}
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
`;

const PaddingDiv = styled.div`
  padding: 48px;
`;

const ScrollingDiv = styled.div`
  overflow-y: scroll;
  padding: 24px;
`;

const LeadResultContainer = styled.div`
  padding: 20px;
`;
const TwoButtonsDiv = styled.div`
  border-top: 1px solid ${theme.NEUTRAL200};
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  padding: 16px 24px;
`;

export { SelfSourceLeadV2 };
