import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { SingleDatePicker } from "react-dates";
import { useHistory } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Switch from "react-switch";
import styled from "styled-components";
import * as Yup from "yup";
import { info, plus, refresh, reorder, search, xIcon } from "../../../images/NewDesign";
import { theme } from "../../../utils/theme";
import { appToast } from "../../../utils/toast";
import { FormSelectField, RadioButtonField } from "../../Field";
import { AppButton, AppErrorText, AppText, AppTitle2, Loading } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";

import { ReactDatesWrapper } from "../../UI/ReactDatesWrapper";
import { DeleteRoutingRuleModal } from "../../modal";

import { useFlags } from "launchdarkly-react-client-sdk";
import { trash } from "../../../images/NewDesign";
import { DATE_RANGES_WITHOUT_SPACE, formatMultipleTypeNames, handleRateAndPercentage } from "../../../utils/format";
import {
  PhoenixIcon,
  PhoenixInput,
  PhoenixMultiSelect,
  PhoenixAppButton,
  PhoenixCheckbox,
  PhoenixRadio,
} from "../../UI/Phoenix";

import DateTimePicker from "react-datetime-picker";
import {
  LEAD_CREATION_STATUSES,
  LEAD_STATUSES,
  arraysAreEqual,
  isBalancedParentheses,
  returnIndividualDataFromArrayBasedOnID,
  testForArrayFormatedAsAString,
  testForArrayWithMultipleElements,
  testIfFieldsAreUnique,
} from "../../../utils/misc";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import { isEqual } from "lodash";
import { PhoenixInputField } from "../../Field/Phoenix";
import { OptionItem } from "../../../types";
import { LeadTable } from "./routing-rules/LeadTable";
import { MixpanelActions } from "src/services/mixpanel";

interface DisappearingDivProps {
  step?: number;
  isOnboarding?: boolean;
  togglePageTitle?: () => void;
  rule_id?: string;
  rule: "widget" | "lead";
  rule_subtitle: string;
  rule_title?: string;
  showDeleteRuleModal: boolean;
  setShowDeleteRuleModal: (blinds: boolean) => void;
  onIsValidChange?: (isValid: boolean) => void;
  onSubmitFormFunction?: (submitForm: () => void) => void;
}

interface FetchRulesExpectedResponse {
  fetchRules?: {
    id?: string;
    priority?: number;
    name?: string;
    updated_at?: string;
    active?: boolean;
    applyToInboundConciergeSchedule?: boolean;
    applyToInboundConciergeCallMeNow?: boolean;
    applyToInboundConcierge?: boolean;
    distribution_method?: string;
    rep_selection_method?: string;
    deferral_date_time?: string;
    conditions?: {
      id?: string;
      field?: string;
      operator?: string;
      value?: string;
      type?: string;
    }[];
    organization?: {
      id?: string;
      send_lead_routing_email?: boolean;
      lead_routing_email_list?: string[];
    };
    routings?: {
      rep_id?: string;
      rule_id?: string;
    }[];
  }[];
}
const FETCH_ORG_RULES = gql`
  query fetchRules($rule_type: RuleType!) {
    fetchRules(rule_type: $rule_type) {
      id
      priority
      name
      updated_at
      active
      applyToInboundConciergeSchedule
      applyToInboundConciergeCallMeNow
      applyToInboundConcierge
      distribution_method
      rep_selection_method
      deferral_date_time
      conditions {
        id
        field
        operator
        value
        type
      }
      organization {
        id
        send_lead_routing_email
        lead_routing_email_list
      }
      routings {
        rep_id
        rule_id
      }
      routing_count
    }
  }
`;

interface FetchOneLeadRoutingRuleExpectedResponse {
  fetchOneLeadRoutingRule?: {
    id: string;
    updated_at: string;
    created_at: string;
    name?: string;
    priority?: number;
    active?: boolean;
    applyToInboundConcierge?: boolean;
    applyToInboundConciergeSchedule?: boolean;
    applyToInboundConciergeCallMeNow?: boolean;
    rule_type?: string;
    rank_sort_type?: string;
    date_range?: string;
    entity?: string;
    metric?: string;
    deferral_date_time?: string;
    rep_priority_rule?: string;
    lead_creation_status?: string;
    distribution_method?: string;
    rep_selection_method?: string;
    los_rules?: string[];
    entity_type?: string;
    conditions?: {
      id?: string;
      field?: string;
      operator?: string;
      value: string;
      type?: string;
      cadence?: string;
      object: string;
      field_label: string;
      condition_set_reference?: number;
      record_matching?: string;
    }[];
    organization?: {
      id?: string;
      send_lead_routing_email?: boolean;
      lead_routing_email_list?: string[];
    };
    routings?: {
      rep_id?: string;
    }[];
    logic?: string;
  };
}
const FETCH_ONE_ROUTING_RULE = gql`
  query FetchOneLeadRoutingRule($rule_id: String!) {
    fetchOneLeadRoutingRule(rule_id: $rule_id) {
      id
      updated_at
      created_at
      name
      priority
      active
      applyToInboundConcierge
      rule_type
      rank_sort_type
      date_range
      entity
      metric
      deferral_date_time
      rep_priority_rule
      lead_creation_status
      applyToInboundConcierge
      applyToInboundConciergeSchedule
      applyToInboundConciergeCallMeNow
      distribution_method
      rep_selection_method
      los_rules
      entity_type
      conditions {
        id
        object
        field
        field_label
        operator
        value
        type
        cadence
        record_matching
        condition_set_reference
      }
      organization {
        id
        send_lead_routing_email
        lead_routing_email_list
      }
      routings {
        rep_id
      }
      logic
    }
  }
`;

const REMOVE_ROUTING_RULE = gql`
  mutation removeRoutingRule($rule_id: String!) {
    removeRoutingRule(rule_id: $rule_id)
  }
`;

const UPDATE_RULE_ORDER = gql`
  mutation updateRuleOrder($rule_ids: [String!]!) {
    updateRuleOrder(rule_ids: $rule_ids)
  }
`;
interface Field {
  key: string;
  label: string;
  type: ConditionTypes;
  options: string[];
  is_custom_object: boolean;
}

type ConditionTypes = "Text" | "Dropdown" | "MultiDropdown" | "Boolean" | "Date" | "Number" | "MultiText";

interface Operations {
  [key: string]: string[];
  Boolean: ("Equal" | "NotEqual")[];
  Dropdown: ("Equal" | "NotEqual" | "IsNull" | "NotNull" | "In" | "NotIn")[];
  MultiDropdown: ("Equal" | "NotEqual" | "IsNull" | "NotNull" | "In" | "NotIn")[];
  List: ("In" | "NotIn")[];
  Text: ("Contain" | "NotContain" | "Equal" | "NotEqual" | "IsNull" | "NotNull")[];
  Number: ("LessThan" | "LessThanOrEqualTo" | "GreaterThan" | "GreaterThanOrEqualTo" | "Equal" | "NotEqual")[];
  Date: (
    | "LessThan"
    | "LessThanOrEqualTo"
    | "GreaterThan"
    | "GreaterThanOrEqualTo"
    | "Equal"
    | "NotEqual"
    | "InTheNext"
    | "SinceTheLast"
  )[];
  MultiText: ("In" | "NotIn")[];
}

interface DateCadence {
  label: string;
  value: string;
}

interface FetchAvailableConditionOptionExpectedResponse {
  fetchAvaliableConditionOption: {
    objects: { [object: string]: Field[] };
    operations: Operations;
    date_cadence: DateCadence[];
  };
}

const FETCH_AVAILABLE_CONDITION_OPTIONS = gql`
  query fetchAvaliableConditionOption {
    fetchAvaliableConditionOption
  }
`;

interface RepOrderExpectedResponse {
  fetchRepOrder?: {
    id?: string;
    full_name?: string;
  }[];
}

const FETCH_REP_ORDER = gql`
  query fetchRepOrder($user_ids: [String!]!) {
    fetchRepOrder(user_ids: $user_ids) {
      id
      full_name
    }
  }
`;

const UPDATE_ROUTING_RULE_STATUS = gql`
  mutation updateRoutingRuleStatus($rule_id: String!, $active: Boolean!) {
    updateRoutingRuleStatus(rule_id: $rule_id, active: $active) {
      active
      name
      priority
    }
  }
`;

const UPDATE_ROUTING_RULE_APPLY_TO_INBOUND_CONCIERGE_CALL_ME_NOW = gql`
  mutation updateRoutingRuleApplyToInboundConcierge($rule_id: String!, $applyToInboundConciergeCallMeNow: Boolean!) {
    updateRoutingRuleApplyToInboundConcierge(
      rule_id: $rule_id
      applyToInboundConciergeCallMeNow: $applyToInboundConciergeCallMeNow
    ) {
      active
      applyToInboundConciergeCallMeNow
      name
      priority
    }
  }
`;

const UPDATE_ROUTING_RULE_APPLY_TO_INBOUND_CONCIERGE_SCHEDULE = gql`
  mutation updateRoutingRuleApplyToInboundConcierge($rule_id: String!, $applyToInboundConciergeSchedule: Boolean!) {
    updateRoutingRuleApplyToInboundConcierge(
      rule_id: $rule_id
      applyToInboundConciergeSchedule: $applyToInboundConciergeSchedule
    ) {
      active
      applyToInboundConciergeSchedule
      name
      priority
    }
  }
`;

const CREATE_OR_UPDATE_ROUTING_RULE = gql`
  mutation createOrUpdateLeadRoutingRule(
    $rule_id: String
    $name: String!
    $conditions: [RoutingCondition!]!
    $rep_ids: [String!]!
    $distribution_method: DISTRIBUTION_METHOD!
    $rep_selection_method: REP_SELECTION_METHOD!
    $deferral_date_time: DateTime
    $los_rules: [LOS!]!
    $lead_creation_status: LeadCreationStatus
    $rule_type: RuleType!
    $rule_logic: String
    $metric: METRIC
    $date_range: DateOption
  ) {
    createOrUpdateLeadRoutingRule(
      rule_id: $rule_id
      name: $name
      conditions: $conditions
      rep_ids: $rep_ids
      distribution_method: $distribution_method
      rep_selection_method: $rep_selection_method
      deferral_date_time: $deferral_date_time
      los_rules: $los_rules
      lead_creation_status: $lead_creation_status
      rule_type: $rule_type
      rule_logic: $rule_logic
      metric: $metric
      date_range: $date_range
    ) {
      id
      priority
      name
      updated_at
      active
      applyToInboundConcierge
      distribution_method
      rep_selection_method
      deferral_date_time
      los_rules
      lead_creation_status
      conditions {
        object
        field
        operator
        value
        type
        condition_set_reference
      }
      organization {
        id
        send_lead_routing_email
        lead_routing_email_list
      }
      routings {
        rep_id
      }
      logic
    }
  }
`;

interface FetchOrganizationExpectedResponse {
  fetchOrganization?: {
    id?: string;
    Reps?: {
      id?: string;
      full_name?: string;
      first_name?: string;
      last_name?: string;
      revenue_per_day?: number;
      email?: string;
    }[];
  };
}
const FETCH_ORGANIZATION = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      Reps {
        id
        full_name
        id
        first_name
        last_name
        revenue_per_day
        email
      }
    }
  }
`;

const SET_LEAD_ROUTING_EMAIL_TOGGLE = gql`
  mutation setLeadRoutingEmailToggle($send_lead_routing_email: Boolean!) {
    setLeadRoutingEmailToggle(send_lead_routing_email: $send_lead_routing_email) {
      send_lead_routing_email
      id
    }
  }
`;

const SET_LEAD_ROUTING_EMAIL_LIST = gql`
  mutation setLeadRoutingEmailList($lead_routing_email_list: [String!]!) {
    setLeadRoutingEmailList(lead_routing_email_list: $lead_routing_email_list) {
      lead_routing_email_list
      id
    }
  }
`;

interface ICondition {
  field?: string;
  operator?: string;
  value: string;
  type?: string;
  cadence?: string;
  object: string;
  field_label: string;
  condition_set_reference?: number;
  record_matching?: string;
}

interface IRule {
  id: string;
  created_at: string;
  updated_at: string;
  name?: string;
  priority?: number;
  active?: boolean;
  distribution_method?: string;
  rep_selection_method?: string;
  conditions?: ICondition[];
  rep_ids?: string[];
  emails_list?: string[];
  email_updates?: boolean;
  deferral_date_time?: string | null;
  lead_creation_status?: string;
  los_rules?: string[];
  logic?: string;
  rule_logic?: string;
  metric?: string;
  date_range?: string;
}

interface IRep {
  included_list: string[];
  ranked_list: string[];
}

interface MyFormikProps {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  priority: number;
  active: boolean;
  distribution_method: string;
  rep_selection_method: string;
  conditions: ICondition[];
  rep_ids: string[];
  emails_list: string[];
  email_updates: boolean;
  deferral_date_time: string | null;
  lead_creation_status: string;
  los_rules: string[];
  available_fields_search: string;
  logic: string;
}

const leadCreationLabelMap: Record<string, string> = {
  Create: "created",
  Update: "updated",
  Both: "both",
};

const priorityOptionMap: Record<string, string> = {
  RoundRobin: "Round Robin",
  Ranking: "Manual",
  Metric: "Dynamic",
};

const repSelectionMethodMap: Record<string, string> = {
  FirstRep: "Speed to Dial",
  BestRep: "Best Rep",
};

const RulesTableV2: React.FC<DisappearingDivProps> = ({
  step,
  isOnboarding,
  togglePageTitle,
  rule_id,
  rule,
  rule_title,
  rule_subtitle,
  showDeleteRuleModal,
  setShowDeleteRuleModal,
  onIsValidChange,
  onSubmitFormFunction,
}) => {
  const history = useHistory();

  const { leadRoutingOnUpdate, opsiqForm } = useFlags();

  const formikRef = useRef() as any;
  const [isValid, setIsValid] = useState(false);

  const [sortAlphabetically, setSortAlphabetically] = useState(true);

  const [focused, setFocused] = useState(null as any);
  const [dataRules, setDataRules] = useState([] as any[]);

  const [conditionSets, setConditionSets] = useState<number[]>([1]);

  const [metric, setMetric] = useState<string | undefined>(undefined);
  const [date_range, setDateRange] = useState<string | undefined>(undefined);

  const toggleLabel = opsiqForm ? "Sellfire Integration, Form or CSV" : "Sellfire Integration or CSV";
  const yupRoutingRules = Yup.object().shape({
    id: Yup.string().notRequired(),
    created_at: Yup.string().notRequired(),
    updated_at: Yup.string().notRequired(),
    name: Yup.string().notRequired(),
    priority: Yup.number().notRequired(),
    active: Yup.boolean().notRequired(),
    distribution_method: Yup.string().notRequired(),
    rep_selection_method: Yup.string().notRequired(),
    conditions: Yup.array()
      .test("unique fields", "Each field can only be used once", (value) => testIfFieldsAreUnique(value ?? []))
      .of(
        Yup.object().shape({
          object: Yup.string()
            .required("Object is required")
            .test("Object is required", "Object is required", (value) => value !== "" && value !== undefined),
          field: Yup.string()
            .required("Field is required")
            .test("Field is required", "Field is required", (value) => value !== "" && value !== undefined),
          operator: Yup.string()
            .required("Operator is required")
            .test("Operator is required", "Operator is required", (value) => value !== "" && value !== undefined),
          value: Yup.string().when("operator", {
            is: (value) => value === "In" || value === "NotIn",
            then: Yup.string().test("value", "Requires multiple values", (value) =>
              testForArrayWithMultipleElements(value ?? ""),
            ),
            otherwise: Yup.string().when("operator", {
              is: (value) => value === "IsNull" || value === "NotNull",
              then: Yup.string().notRequired(),
              otherwise: Yup.string().required("Value is required"),
            }),
          }),

          type: Yup.string().required("type is required"),
          field_label: Yup.string(),
          cadence: Yup.string().when("operator", {
            is: (value) => value === "InTheNext" || value === "SinceTheLast",
            then: Yup.string().required("Cadence is required"),
          }),
        }),
      ),
    rep_ids: Yup.array().of(Yup.string()),
    emails_list: Yup.array().of(Yup.string().email("Email addresses must be valid")),
    email_updates: Yup.boolean(),
    logic: Yup.string()
      .matches(/^[0-9\sANDOR()]+$/, "Logic can only contain numbers, spaces, 'AND', 'OR', and parentheses")
      .test("logic-syntax-regex", "Logic syntax is invalid.", (value) => {
        if (!value) return true; // Skip if empty
        // Remove extra spaces for easier parsing
        const sanitized = value.replace(/\s+/g, "");
        // Basic syntax check for balanced parentheses
        if (!isBalancedParentheses(sanitized)) {
          return false;
        }
        // Check if the string starts with a number or an opening parenthesis and ends with a number or a closing parenthesis
        if (!/^(\d|\()/.test(sanitized) || !/(\d|\))$/.test(sanitized)) {
          return false;
        }
        // Ensure it does not end with an operator
        if (/\s*(AND|OR)\s*$/.test(sanitized)) {
          return false;
        }
        // Ensure there is an operator between numbers or groups of numbers
        if (/(\d\(\d)|(\d\d)|(\)\d)|(\d\()|(\)\()/g.test(sanitized)) {
          return false;
        }
        // Ensure proper use of operators between numbers or groups
        if (!/\d\s*(AND|OR)\s*\d|^\d+$/.test(sanitized) && !/\(\d+\)/.test(sanitized)) {
          return false;
        }
        return true;
      })
      .test("logic-syntax-js", "Logic syntax is invalid.", (value) => {
        if (!value) return true; // Skip if empty

        // Remove extra spaces for easier parsing
        const sanitized = value.replace(/\s+/g, "");
        // Basic syntax check for balanced parentheses
        if (!isBalancedParentheses(sanitized)) {
          return false;
        }

        const split = value.split(" ");

        // Check for missing space
        for (let v of split) {
          if ((v.length > 2 && v.includes("OR")) || (v.length > 3 && v.includes("AND"))) {
            return false;
          }
        }

        // Check for recurring operators or invalid operator slice
        let prevIsOperator = false;
        for (let v of split) {
          if (v === "OR" || v === "AND") {
            if (prevIsOperator) return false;
            prevIsOperator = true;
          } else {
            prevIsOperator = false;
          }
        }
        return true;
      })
      .test(
        "logic-conditions",
        "Some filter conditions are defined but not referenced in your filter logic.",
        (value) => {
          if (!value) return true; // Skip if empty
          // Extract unique numbers from the logic string
          const matches = value.match(/\d+/g);
          const uniqueNumbers = matches ? Array.from(new Set(matches?.map(Number))) : [];
          // Check if all numbers correspond to existing condition sets
          return (
            uniqueNumbers.every((num) => conditionSets.includes(num)) &&
            conditionSets.every((num) => uniqueNumbers.includes(num))
          );
        },
      ),
  });

  useEffect(() => {
    if (!!formikRef.current) {
      formikRef.current?.validateForm();
    }
  }, [formikRef.current, conditionSets]);

  const URL = findRoutingURL(rule);
  const RULE_OPTIONS_WHEN_EDITING = findRuleOptions(rule);

  const { loading: loadingRules, error: errorRules } = useQuery<FetchRulesExpectedResponse>(FETCH_ORG_RULES, {
    variables: {
      rule_type: rule === "lead" ? "Routing" : rule === "widget" ? "Widget" : "Routing",
    },
    fetchPolicy: "network-only",
    onError({ message, name }: any) {
      console.log(`Error in ${name}: `, message);
    },
    onCompleted: ({ fetchRules }: any) => {
      setDataRules(fetchRules.slice().sort((a: any, b: any) => a.priority - b.priority));
    },
  });

  useQuery<FetchOneLeadRoutingRuleExpectedResponse>(FETCH_ONE_ROUTING_RULE, {
    fetchPolicy: "network-only",
    skip: !rule_id || (!!rule_id && rule_id === "new"),
    variables: { rule_id: rule_id },
    onCompleted({ fetchOneLeadRoutingRule }) {
      if (!fetchOneLeadRoutingRule) {
        return;
      }
      const newSelectedRule = {
        id: fetchOneLeadRoutingRule?.id,
        created_at: fetchOneLeadRoutingRule?.created_at,
        updated_at: fetchOneLeadRoutingRule?.updated_at,
        name: fetchOneLeadRoutingRule?.name,
        priority: fetchOneLeadRoutingRule?.priority,
        active: fetchOneLeadRoutingRule?.active,
        distribution_method: fetchOneLeadRoutingRule?.distribution_method,
        rep_selection_method: fetchOneLeadRoutingRule?.rep_selection_method,
        conditions: fetchOneLeadRoutingRule?.conditions?.map((el: ICondition, i: number) => ({
          object: el?.object,
          field: el?.field,
          field_label: el?.field_label,
          operator: el?.operator,
          value: !!el.value ? el.value.toString() : "",
          type: el?.type,
          cadence: el?.cadence,
          condition_set_reference: Number(el?.condition_set_reference) ?? i + 1,
          record_matching: objectOptions?.find((o: any) => o.value === el?.object)?.is_custom_object
            ? el?.record_matching
            : undefined,
        })),
        rep_ids: fetchOneLeadRoutingRule?.routings?.map((el: any) => el.rep_id) || [],
        deferral_date_time: fetchOneLeadRoutingRule?.deferral_date_time,
        email_updates: fetchOneLeadRoutingRule?.organization?.send_lead_routing_email,
        emails_list: fetchOneLeadRoutingRule?.organization?.lead_routing_email_list,
        lead_creation_status: fetchOneLeadRoutingRule?.lead_creation_status,
        los_rules: fetchOneLeadRoutingRule?.los_rules,
        logic: fetchOneLeadRoutingRule?.logic,
        metric: fetchOneLeadRoutingRule?.metric,
        date_range: fetchOneLeadRoutingRule?.date_range,
      };

      setSelectedRule(newSelectedRule);
      setMetric(newSelectedRule?.metric);
      setDateRange(newSelectedRule?.date_range);

      const conditionSetReferences =
        newSelectedRule?.conditions?.map((el: ICondition) => Number(el.condition_set_reference)) || [];
      const uniqueConditionSetReferences = [...new Set(conditionSetReferences)];
      setConditionSets(uniqueConditionSetReferences);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const defaultRuleTemplate = {
    id: "",
    created_at: "",
    updated_at: "",
    name: "",
    priority: !!dataRules ? dataRules.length : 0,
    active: false,
    distribution_method: "RoundRobin",
    rep_selection_method: "FirstRep",
    conditions: [
      { object: "", field: "", operator: "", value: "", type: "", field_label: "", condition_set_reference: 1 },
    ],
    rep_ids: [],
    email_updates: false,
    emails_list: [],
    deferral_date_time: null,
    lead_creation_status: "Create",
    los_rules: [],
  };

  const [selectedRule, setSelectedRule] = useState<IRule>(defaultRuleTemplate);

  const [selectedReps, setSelectedReps] = useState<IRep>({
    included_list: [] as string[],
    ranked_list: [] as string[],
  });

  const [createOrUpdateLeadRoutingRule] = useMutation(CREATE_OR_UPDATE_ROUTING_RULE, {
    onCompleted({ createOrUpdateLeadRoutingRule }) {
      if (!createOrUpdateLeadRoutingRule) {
        return;
      }
      if (rule_id !== createOrUpdateLeadRoutingRule?.id) {
        history.replace(`/system-config/${URL}/${createOrUpdateLeadRoutingRule?.id}`);
      }
      const values = formikRef.current?.values;
      MixpanelActions.track("Routing Rule Saved", {
        save_type: rule_id === "new" ? "new" : "modified",
        number_of_conditions: values?.conditions?.length,
        lead_creation_status: leadCreationLabelMap?.[values?.lead_creation_status],
        priority_option: priorityOptionMap?.[values?.distribution_method],
        rep_availability_option: repSelectionMethodMap?.[values?.rep_selection_method],
        lead_status: values?.los_rules,
        rules_logic: values?.logic ?? "N/A",
      });
      appToast("Success! Updated rules");
    },
    onError({ message }) {
      console.log("Error in createOrUpdateLeadRoutingRule: ", message);
      appToast(message);
    },
    refetchQueries: ["fetchRules"],
  });

  const [removeRoutingRule, { loading: loadingRemoveRule }] = useMutation(REMOVE_ROUTING_RULE, {
    onCompleted({ removeRoutingRule }) {
      if (!removeRoutingRule) {
        return;
      }
      history.replace(`/system-config/${URL}`);
      appToast("Deleted routing route");
    },
    onError({ message }) {
      console.log("Error in removeRoutingRule: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `removeRoutingRule GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchRules"],
  });

  const [updateRuleOrder] = useMutation(UPDATE_RULE_ORDER, {
    onCompleted({ updateRuleOrder }) {
      if (!updateRuleOrder) {
        return;
      }
      appToast("Updated order");
    },
    onError({ message }) {
      console.log("Error in updateRuleOrder: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateRuleOrder GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchRules"],
  });

  const [updateRoutingRuleApplyToInboundConciergeCallMeNow] = useMutation(
    UPDATE_ROUTING_RULE_APPLY_TO_INBOUND_CONCIERGE_CALL_ME_NOW,
    {
      onCompleted({ updateRoutingRuleApplyToInboundConcierge }) {
        appToast("Updated inbound concierge call me rule status");
        MixpanelActions.track("Routing Rule Option toggled", {
          option_changed: "Inbound Concierge Call Me",
          new_status: updateRoutingRuleApplyToInboundConcierge.applyToInboundConciergeCallMeNow,
        });
      },
      onError({ message }) {
        console.log("Error in updateRoutingRuleApplyToConcierge: ", message);
        appToast(message);
        Sentry.captureEvent({
          message: `updateRoutingRuleApplyToConcierge GraphQL Error: ${message}`,
        });
      },

      refetchQueries: ["fetchRules"],
    },
  );

  const [updateRoutingRuleApplyToInboundConciergeSchedule] = useMutation(
    UPDATE_ROUTING_RULE_APPLY_TO_INBOUND_CONCIERGE_SCHEDULE,
    {
      onCompleted({ updateRoutingRuleApplyToInboundConcierge }) {
        appToast("Updated inbound concierge schedule meeting application status");
        MixpanelActions.track("Routing Rule Option toggled", {
          option_changed: "Inbound Concierge Schedule Meeting",
          new_status: updateRoutingRuleApplyToInboundConcierge.applyToInboundConciergeSchedule,
        });
      },
      onError({ message }) {
        console.log("Error in updateRoutingRuleApplyToConcierge: ", message);
        appToast(message);
        Sentry.captureEvent({
          message: `updateRoutingRuleApplyToConcierge GraphQL Error: ${message}`,
        });
      },

      refetchQueries: ["fetchRules"],
    },
  );

  const [updateRoutingRuleStatus] = useMutation(UPDATE_ROUTING_RULE_STATUS, {
    onCompleted({ updateRoutingRuleStatus }) {
      if (!updateRoutingRuleStatus) {
        return;
      }
      if (rule === "widget") {
        appToast("Updated widget rule status");
      }
      if (rule === "lead") {
        appToast("Updated integration and csv rule status");
      }
      MixpanelActions.track("Routing Rule Option toggled", {
        option_changed: "Sellfire Integration or CSV",
        new_status: updateRoutingRuleStatus.active,
      });
    },
    onError({ message }) {
      console.log("Error in updateRoutingRuleStatus: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateRoutingRuleStatus GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchRules"],
  });

  const [setLeadRoutingEmailToggle] = useMutation(SET_LEAD_ROUTING_EMAIL_TOGGLE, {
    onCompleted({ setLeadRoutingEmailToggle }) {
      setLeadRoutingEmailToggle.send_lead_routing_email
        ? appToast("Email updates will now be sent")
        : appToast("Email updates will NOT be sent");
    },
    onError({ message }) {
      console.log("Error in setLeadRoutingEmailToggle", message);
      appToast(`Setting email updates error: ${message}`);
    },
  });

  const [setLeadRoutingEmailList] = useMutation(SET_LEAD_ROUTING_EMAIL_LIST, {
    onCompleted() {
      appToast("Email list updated");
    },
    onError({ message }) {
      console.log("Error in setLeadRoutingEmailList", message);
      appToast(`Update email list error: ${message}`);
    },
  });

  const { data: dataOrg, loading: loadingOrg, error: errorOrg } = useQuery<FetchOrganizationExpectedResponse>(
    FETCH_ORGANIZATION,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const [fetchRepOrder, { data: dataOrder }] = useLazyQuery<RepOrderExpectedResponse>(FETCH_REP_ORDER, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      !!dataOrder &&
      !!dataOrder.fetchRepOrder &&
      !!dataOrder.fetchRepOrder.length &&
      !!formikRef.current &&
      !!formikRef.current.setFieldValue &&
      !!formikRef.current.values
    ) {
      const sortedIDs = dataOrder.fetchRepOrder?.map((item: any) => item.id);
      if (arraysAreEqual(sortedIDs, formikRef.current.values.rep_ids)) {
        formikRef.current.setFieldValue("rep_ids", sortedIDs);
      }
    }
  }, [dataOrder]);

  useEffect(() => {
    !!onIsValidChange && onIsValidChange(isValid);
  }, [isValid]);

  useEffect(() => {
    if (!formikRef.current) return;
    !!onSubmitFormFunction && onSubmitFormFunction(formikRef.current.submitForm);
  }, [formikRef.current]);

  const { data: dataOptions } = useQuery<FetchAvailableConditionOptionExpectedResponse>(
    FETCH_AVAILABLE_CONDITION_OPTIONS,
    {
      fetchPolicy: "cache-and-network",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
      onCompleted({ fetchAvaliableConditionOption }) {
        const newSelectedRule = {
          ...selectedRule,
          conditions: selectedRule?.conditions?.map((c: ICondition) => ({
            ...c,
            record_matching: fetchAvaliableConditionOption?.objects?.[c.object]?.[0]?.is_custom_object
              ? c.record_matching
              : undefined,
          })),
        };
        setSelectedRule(newSelectedRule);
      },
    },
  );

  const objectOptions = useMemo(() => {
    const newOptions = Object.keys(dataOptions?.fetchAvaliableConditionOption?.objects || [])?.map((item: any) => ({
      label: item,
      value: item,
      is_custom_object: dataOptions?.fetchAvaliableConditionOption?.objects?.[item]?.[0]?.is_custom_object,
    }));
    const newSelectedRule = {
      ...selectedRule,
      conditions: selectedRule?.conditions?.map((c: ICondition) => ({
        ...c,
        record_matching: newOptions?.find((o: any) => o.value === c.object)?.is_custom_object
          ? c.record_matching
          : undefined,
      })),
    };
    setSelectedRule(newSelectedRule);
    return newOptions;
  }, [dataOptions]);

  const findTypeFromFieldKey = (object: string, value: string | undefined): ConditionTypes | string => {
    const DEFAULT_TYPE = "Text";

    const fields = dataOptions?.fetchAvaliableConditionOption?.objects[object];
    if (!value || !fields) {
      return DEFAULT_TYPE;
    } else {
      const field = fields.find((item) => item.key === value);
      if (!field) return DEFAULT_TYPE;

      return handleRateAndPercentage(field.type);
    }
  };

  const findOperatorsFromFieldType = (value: string) => {
    const val = value === "Rate" || value === "Percentage" ? "Number" : value;

    return !!value &&
      !!dataOptions?.fetchAvaliableConditionOption?.operations &&
      !!dataOptions.fetchAvaliableConditionOption.operations[val]
      ? value === "Text"
        ? [
            ...dataOptions.fetchAvaliableConditionOption.operations[val]?.map((item: string) => ({
              label: formatMultipleTypeNames(item),
              value: item,
            })),
            ...dataOptions.fetchAvaliableConditionOption.operations["MultiText"]?.map((item: string) => ({
              label: formatMultipleTypeNames(item),
              value: item,
            })),
          ]
        : dataOptions.fetchAvaliableConditionOption.operations[val]?.map((item: string) => ({
            label: formatMultipleTypeNames(item),
            value: item,
          }))
      : [{ label: "Select field first", value: "" }];
  };

  const findDropdownOptionsFromField = (object: string, value: string | undefined) => {
    if (!value) {
      return [{ label: "Select field first", value: "" }];
    }

    const fields = dataOptions?.fetchAvaliableConditionOption.objects[object];

    return !!value && !!fields && !!fields.slice().filter((item) => item.key === value).length
      ? fields
          .slice()
          .filter((item) => item.key === value)[0]
          .options?.map((item: string) => ({
            label: item,
            value: item,
          }))
      : [{ label: "Select field first", value: "" }];
  };

  if (loadingRules) return <Loading />;

  if (errorRules) return <AppErrorText>Error loading Rules</AppErrorText>;

  const reorderRules = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    MixpanelActions.track("Routing Rule Order Updated", {
      old_priority: startIndex + 1,
      new_priority: endIndex + 1,
    });

    // update local state
    setDataRules(result?.map((r, i) => ({ ...r, priority: i })));
    updateRuleOrder({
      variables: {
        rule_ids: result?.map((item: any) => item.id),
      },
    });
  };

  const onDragEndRules = (result: any) => {
    console.log("result: ", result);
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    reorderRules(
      dataRules.slice().sort((a: any, b: any) => a.priority - b.priority),
      result.source.index,
      result.destination.index,
    );
  };

  const isRepValid = (repId: string) => {
    const repIsValid =
      !!dataOrg?.fetchOrganization?.Reps &&
      dataOrg?.fetchOrganization?.Reps.slice().filter((item) => repId === item.id);
    return repIsValid;
  };

  return (
    <DisappearingDiv>
      <Formik
        enableReinitialize={true}
        innerRef={(formikActions) => {
          if (formikActions) {
            setIsValid(formikActions.isValid);
            formikRef.current = formikActions;
          }
        }}
        initialValues={{
          id: selectedRule?.id,
          created_at: selectedRule?.created_at,
          updated_at: selectedRule?.updated_at ?? "",
          name: selectedRule?.name ?? "",
          priority: selectedRule?.priority ? selectedRule?.priority + 1 : defaultRuleTemplate.priority + 1,
          active: selectedRule?.active ?? false,
          distribution_method: selectedRule?.distribution_method ?? "",
          rep_selection_method: selectedRule?.rep_selection_method ?? "",
          conditions: selectedRule?.conditions ?? [],
          rep_ids: selectedRule?.rep_ids ? selectedRule?.rep_ids.filter((id) => isRepValid(id)) : [],
          email_updates: selectedRule?.email_updates ?? false,
          emails_list: selectedRule?.emails_list ?? defaultRuleTemplate.emails_list,
          deferral_date_time: selectedRule?.deferral_date_time ?? "",
          lead_creation_status: selectedRule?.lead_creation_status ?? "",
          los_rules: selectedRule?.los_rules ?? [],
          available_fields_search: "",
          logic: selectedRule?.logic ?? "",
        }}
        validationSchema={yupRoutingRules}
        onSubmit={async (values) => {
          if (values.email_updates !== selectedRule?.email_updates) {
            await setLeadRoutingEmailToggle({
              variables: {
                send_lead_routing_email: values.email_updates,
              },
            });
          }

          if ([...values?.emails_list].sort().toString() !== [...(selectedRule?.emails_list || [])].sort().toString()) {
            await setLeadRoutingEmailList({
              variables: {
                lead_routing_email_list: values.emails_list,
              },
            });
          }

          await createOrUpdateLeadRoutingRule({
            variables: {
              rule_id: rule_id === "new" ? undefined : rule_id,
              name: values.name || "Untitled Rule",
              conditions: values.conditions,
              rep_ids: values.rep_ids,
              distribution_method: values.distribution_method,
              rep_selection_method: values.rep_selection_method,
              deferral_date_time: values.deferral_date_time || undefined,
              lead_creation_status: values.lead_creation_status,
              los_rules: values.los_rules,
              rule_type: rule === "lead" ? "Routing" : rule === "widget" ? "Widget" : "Routing",
              rule_logic: values.logic,
              metric: metric,
              date_range: date_range,
            },
          });
        }}
      >
        {({ values, setFieldValue, errors }: FormikProps<MyFormikProps>) => {
          const resetCondition = ({
            type,
            index,
            prev_val,
            current_val,
          }: {
            type: ResetObjectType;
            index: number;
            prev_val: string;
            current_val: string;
          }) => {
            if (prev_val === current_val) {
              return;
            }

            if (type === ResetObjectType.Object) {
              setFieldValue(`conditions[${index}].field`, "");
              setFieldValue(`conditions[${index}].operator`, "");
              setFieldValue(`conditions[${index}].value`, "");
              setFieldValue(`conditions[${index}].type`, "");
            }
            if (type === ResetObjectType.Field) {
              setFieldValue(`conditions[${index}].operator`, "");
              setFieldValue(`conditions[${index}].value`, "");
            }
            if (type === ResetObjectType.Operator) {
              setFieldValue(`conditions[${index}].value`, "");
            }
          };

          const reorderReps = (list: string[], startIndex: number, endIndex: number) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            setFieldValue("rep_ids", result);
          };

          const onDragEndReps = (result: any) => {
            // dropped outside the list
            if (!result.destination) {
              return;
            }

            reorderReps(values.rep_ids, result.source.index, result.destination.index);
          };

          const handleChangeEmails = (newValue: any, actionMeta: any) => {
            const emailsList = newValue?.map((item: any) => item.value);
            setFieldValue("emails_list", emailsList);
            console.group("Value Changed");
            console.log(newValue);
            console.log(`action: ${actionMeta.action}`);
            console.groupEnd();
          };

          const renderCadenceSelect = ({
            currentCondition,
            index,
            date_cadence,
          }: {
            currentCondition: ICondition;
            index: number;
            // date_cadence: { label: string; value: string }[];
            date_cadence: any;
          }) => {
            const dateTypeCondition = findTypeFromFieldKey(currentCondition.object, currentCondition?.field) === "Date";

            const operatorRequiresCadence = ["InTheNext", "SinceTheLast"]?.includes(currentCondition?.operator || "");

            if (dateTypeCondition && operatorRequiresCadence) {
              return (
                <FlexDiv direction="column" gap={8} grow={1} className="cadence">
                  <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                    Cadence
                  </AppText>
                  <PhoenixMultiSelect
                    name={`conditions[${index}]?.cadence`}
                    isMulti={false}
                    isClearable={false}
                    options={date_cadence}
                    value={{
                      value: values?.conditions[index]?.cadence,
                      label: date_cadence.find((item: any) => item.value === values?.conditions[index]?.cadence)?.label,
                    }}
                    onChange={(value: any) => {
                      setFieldValue(`conditions[${index}].cadence`, value?.value);
                    }}
                  />
                </FlexDiv>
              );
            } else {
              return null;
            }
          };
          const renderValueSelect = ({ currentCondition, index }: { currentCondition: ICondition; index: number }) => {
            // for Booleons there can be only 1 type of input regardless of the field

            // date checks

            const dateCondition = findTypeFromFieldKey(currentCondition.object, currentCondition.field) === "Date";

            const operatorRequiresCadence = ["InTheNext", "SinceTheLast"].includes(currentCondition?.operator || "");

            const handleDateValueArrowClick = (direction: 1 | -1) => {
              // check if value would be negative
              if (direction === -1 && parseInt(currentCondition.value || "1") <= 1) {
                appToast("Value must be a positive number");
                return;
              }

              setFieldValue("conditions", [
                ...values.conditions?.map((item: ICondition, i: number) =>
                  i === index
                    ? {
                        ...currentCondition,
                        value: ((parseInt(currentCondition.value) || 0) + direction).toString(),
                      }
                    : item,
                ),
              ]);
            };

            const handleDateValueChange = (e: any) => {
              const parsedValue = parseInt(e?.target?.value);

              if (parsedValue <= 0 || isNaN(parsedValue)) {
                setFieldValue("conditions", [
                  ...values.conditions?.map((item: ICondition, i: number) =>
                    i === index
                      ? {
                          ...currentCondition,
                          value: "",
                        }
                      : item,
                  ),
                ]);
                return;
              }

              setFieldValue("conditions", [
                ...values.conditions?.map((item: ICondition, i: number) =>
                  i === index
                    ? {
                        ...currentCondition,
                        value: parsedValue.toString(),
                      }
                    : item,
                ),
              ]);
            };

            // for Null and Not Null there can never be any input
            if (
              currentCondition.operator === "IsNull" ||
              currentCondition.operator === "NotNull" ||
              !currentCondition.operator
            ) {
              return (
                <div
                  style={{
                    width: "100%",
                  }}
                ></div>
              );
            }

            if (dateCondition && !operatorRequiresCadence) {
              return (
                <DateTimePickerWrapper>
                  <DateTimePicker
                    format="MM/dd/yyyy"
                    // no clock
                    disableClock={true}
                    onChange={(date) => {
                      setFieldValue(`conditions[${index}].value`, date);
                    }}
                    value={currentCondition.value ? new Date(currentCondition.value) : undefined}
                  />
                </DateTimePickerWrapper>
              );
            }

            if (dateCondition && !operatorRequiresCadence) {
              return (
                <DateTimePickerWrapper>
                  <DateTimePicker
                    format="MM/dd/yyyy"
                    // no clock
                    disableClock={true}
                    onChange={(date) => {
                      setFieldValue(`conditions[${index}].value`, date);
                    }}
                    value={currentCondition.value ? new Date(currentCondition.value) : undefined}
                  />
                </DateTimePickerWrapper>
              );
            }

            if (dateCondition && operatorRequiresCadence) {
              return (
                <PhoenixInput
                  inputValueType="number"
                  value={
                    typeof currentCondition.value === "string"
                      ? parseInt(currentCondition.value)
                      : currentCondition.value
                  }
                  showNumberArrows={true}
                  handleNumberArrowDownClick={() => handleDateValueArrowClick(-1)}
                  handleNumberArrowUpClick={() => handleDateValueArrowClick(1)}
                  onChange={handleDateValueChange}
                  displayNoContextText
                />
              );
            }

            if (
              !!currentCondition.field &&
              findTypeFromFieldKey(currentCondition.object, currentCondition.field) === "Boolean"
            ) {
              return (
                <RulesSelect
                  textAlign="left"
                  placeholder="Select one"
                  allowSelectPlaceholder
                  name={`conditions[${index}].value`}
                  options={[
                    { label: "True", value: "true" },
                    { label: "False", value: "false" },
                  ]}
                />
              );
            }

            // start of the logic based on the field type

            // for dropdows or multidropdowns there can be 2 types of inputs depending on the operator

            if (
              (!!currentCondition.field &&
                findTypeFromFieldKey(currentCondition.object, currentCondition.field) === "Dropdown") || // if the field is a multi
              findTypeFromFieldKey(currentCondition.object, currentCondition.field) === "MultiDropdown"
            ) {
              switch (currentCondition.operator) {
                // this is different from the text field which is a normal input not a select
                case "Equal":
                case "NotEqual":
                  return (
                    <RulesSelect
                      textAlign="left"
                      placeholder="Select one"
                      allowSelectPlaceholder
                      name={`conditions[${index}].value`}
                      options={findDropdownOptionsFromField(currentCondition.object, currentCondition.field)}
                    />
                  );

                default:
                  return (
                    <CreatableSelect
                      isMulti={true}
                      textAlign="left"
                      placeholder="Select two or more"
                      allowSelectPlaceholder
                      options={findDropdownOptionsFromField(currentCondition.object, currentCondition.field)}
                      onChange={(newValue: any, actionMeta: any) => {
                        const emailsList = newValue?.map((item: any) => item.value);
                        setFieldValue(`conditions[${index}].value`, JSON.stringify(emailsList));
                      }}
                      value={findDropdownOptionsFromField(currentCondition.object, currentCondition.field).filter(
                        (option: any) => {
                          const selectedValues = currentCondition.value
                            .replace(/[\[\]"]/g, "")
                            .split(",")
                            ?.map((val) => val.trim());
                          const isIncluded = selectedValues.includes(option.value);
                          return isIncluded;
                        },
                      )}
                    />
                  );
              }
            }
            // the default for the rest of the field types is the the same currently but this should be changed in the future by adding more cases above
            // this is applied currently applied to Text, List, Number, and Date field types

            switch (currentCondition.operator) {
              case "In":
              case "NotIn":
                return (
                  <PhoenixMultiSelect
                    name={`conditions[${index}].value`}
                    options={[]}
                    creatableOptions
                    placeholder="Enter multiple options..."
                    defaultValue={
                      testForArrayFormatedAsAString(currentCondition.value || "")
                        ? JSON.parse(currentCondition?.value || "")?.map((item: string) => ({
                            label: item,
                            value: item,
                          }))
                        : []
                    }
                    onChange={(newValue: any, actionMeta: any) => {
                      const emailsList = newValue?.map((item: any) => item.value);
                      setFieldValue("conditions", [
                        ...values.conditions.slice()?.map((item, i) =>
                          i === index
                            ? {
                                ...item,
                                value: JSON.stringify(emailsList),
                              }
                            : item,
                        ),
                      ]);
                    }}
                    maxValueWidth={300}
                    marginBottom={false}
                  />
                );
              default:
                // unlike the dropdown and multi dropdown the Equal and NotEqual operators for text fields are standard inputs not selects
                return (
                  <PhoenixInput
                    value={currentCondition.value}
                    onChange={(e: any) => {
                      setFieldValue("conditions", [
                        ...values.conditions.slice()?.map((item, i) =>
                          i === index
                            ? {
                                ...currentCondition,
                                value: e.target.value,
                              }
                            : item,
                        ),
                      ]);
                    }}
                    displayNoContextText
                  />
                );
            }
          };

          return (
            <>
              {showDeleteRuleModal && (
                <DeleteRoutingRuleModal
                  blinds={showDeleteRuleModal}
                  setBlinds={setShowDeleteRuleModal}
                  loadingDelete={loadingRemoveRule}
                  deleteFunction={async () => {
                    await removeRoutingRule({
                      variables: {
                        rule_id: values.id,
                      },
                    });
                  }}
                />
              )}
              <TopAlignDiv>
                {!!rule_id ? (
                  <>
                    <PhoenixStyledTooltip
                      multiline
                      place="right"
                      effect="solid"
                      css={{
                        fontFamily: theme.PRIMARY_FONT,
                      }}
                      backgroundColor={theme.PRIMARY800}
                      id="step-routing-rules-tooltip"
                      getContent={(dataTip) => (
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "10px",
                            lineHeight: "14px",
                          }}
                        >
                          {dataTip}
                        </span>
                      )}
                    />
                    <FlexDiv direction="column" gap={8}>
                      <FlexDiv gap={24} align="center">
                        {RULE_OPTIONS_WHEN_EDITING.includes("name") && (
                          <PhoenixInput
                            titleText="Routing Rule Name"
                            titleTextSpacing={8}
                            width={376}
                            value={values.name}
                            onChange={(e: any) => setFieldValue("name", e.target.value)}
                            displayNoContextText
                          />
                        )}
                        {RULE_OPTIONS_WHEN_EDITING.includes("priority") && (
                          <PhoenixInput
                            titleText="Priority"
                            titleTextSpacing={8}
                            showNumberArrows
                            handleNumberArrowUpClick={() => {
                              if (values.priority === undefined) {
                                setFieldValue("priority", 0);
                              } else {
                                setFieldValue("priority", values.priority + 1);
                              }
                            }}
                            handleNumberArrowDownClick={() => {
                              setFieldValue("priority", Math.max(0, values.priority - 1));
                            }}
                            width={104}
                            value={values.priority}
                            type="number"
                            onChange={(e: any) => {
                              if (isNaN(parseInt(e.target.value)) && e.target.value !== "") {
                                return;
                              }
                              setFieldValue("priority", e.target.value);
                            }}
                            displayNoContextText
                          />
                        )}
                      </FlexDiv>
                      {RULE_OPTIONS_WHEN_EDITING.includes("priority") && (
                        <AppText fontSize={12} fontWeight={400} lineHeight={18} color={theme.text.neutral.secondary}>
                          Priority can be assigned at any time
                        </AppText>
                      )}
                    </FlexDiv>
                    {RULE_OPTIONS_WHEN_EDITING.includes("conditions") && (
                      <ConditionContainer>
                        <FlexDiv justify="space-between" align="center" style={{ marginBottom: "24px" }}>
                          <AppText fontSize={20} fontWeight={500} lineHeight={26}>
                            {rule === "widget"
                              ? "If Lead Meets These Conditions, Don't Show Widget"
                              : "If Lead Meets These Conditions..."}
                          </AppText>

                          <PhoenixAppButton
                            variant="brand"
                            buttonType="secondary"
                            onClick={() => {
                              setConditionSets([...conditionSets, conditionSets.length + 1]);
                              setFieldValue("conditions", [
                                ...values.conditions,
                                {
                                  object: "",
                                  field: "",
                                  operator: "",
                                  value: "",
                                  type: "",
                                  field_label: "",
                                  condition_set_reference: conditionSets.length + 1,
                                },
                              ]);
                            }}
                          >
                            Add Conditions
                          </PhoenixAppButton>
                        </FlexDiv>

                        {conditionSets?.map((cs: number, i: number) => {
                          const showDeleteFieldButton =
                            values.conditions.filter((c: ICondition) => Number(c.condition_set_reference) === cs)
                              .length > 1;
                          return (
                            <>
                              <ConditionBlock key={`conditionblock-${i}`}>
                                <AppText
                                  fontSize={10}
                                  fontWeight={600}
                                  lineHeight={16}
                                  uppercase
                                  letterSpacing={1}
                                  color={theme.text.brand.primary}
                                  noWrap
                                >
                                  Condition Set {cs}
                                </AppText>
                                <ConditionBody>
                                  <FlexDiv
                                    gap={16}
                                    style={{
                                      paddingBottom: "16px",
                                      marginBottom: "16px",
                                      borderBottom: `1px solid ${theme.border.neutral.secondary}`,
                                    }}
                                  >
                                    <FlexDiv direction="column" gap={8}>
                                      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                                        Object
                                      </AppText>
                                      <div style={{ width: "396px" }}>
                                        <PhoenixMultiSelect
                                          isMulti={false}
                                          isClearable={false}
                                          name={`conditions-${cs}_object`}
                                          options={objectOptions}
                                          onChange={(value: {
                                            label: string;
                                            value: string;
                                            is_custom_object: boolean;
                                          }) => {
                                            const prev_val = values.conditions?.find(
                                              (c: ICondition) => Number(c.condition_set_reference) === cs,
                                            )?.object;
                                            if (value.value === prev_val) return;

                                            const newConditions = values.conditions?.map((c: ICondition) =>
                                              Number(c.condition_set_reference) === cs
                                                ? {
                                                    object: value.value,
                                                    condition_set_reference: cs,
                                                    field: "",
                                                    operator: "",
                                                    value: "",
                                                    type: "",
                                                    record_matching: value.is_custom_object ? "Any" : undefined,
                                                  }
                                                : c,
                                            );

                                            setFieldValue("conditions", newConditions);
                                          }}
                                          value={[
                                            {
                                              label: values.conditions.find(
                                                (c: ICondition) => Number(c.condition_set_reference) === cs,
                                              )?.object,
                                              value: values.conditions.find(
                                                (c: ICondition) => Number(c.condition_set_reference) === cs,
                                              )?.object,
                                            },
                                          ]}
                                          marginBottom={false}
                                        />
                                      </div>
                                    </FlexDiv>

                                    {!!values.conditions.find(
                                      (c: ICondition) => Number(c.condition_set_reference) === cs,
                                    )?.record_matching && (
                                      <FlexDiv direction="column" gap={8}>
                                        <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                                          Which record(s) should be evaluated for matching this condition?
                                        </AppText>
                                        <div style={{ width: "396px" }}>
                                          <PhoenixMultiSelect
                                            isMulti={false}
                                            isClearable={false}
                                            name={`conditions-${cs}_record_match`}
                                            options={recordMatchOptions}
                                            onChange={(v: OptionItem) => {
                                              const newConditions = values.conditions?.map((c: ICondition) =>
                                                Number(c.condition_set_reference) === cs
                                                  ? {
                                                      ...c,
                                                      record_matching: v.value,
                                                    }
                                                  : c,
                                              );

                                              setFieldValue("conditions", newConditions);
                                            }}
                                            value={[
                                              {
                                                label: recordMatchOptions.find(
                                                  (r: OptionItem) =>
                                                    r.value ===
                                                    values.conditions.find(
                                                      (c: ICondition) => Number(c.condition_set_reference) === cs,
                                                    )?.record_matching,
                                                )?.label,
                                                value: values.conditions.find(
                                                  (c: ICondition) => Number(c.condition_set_reference) === cs,
                                                )?.record_matching,
                                              },
                                            ]}
                                            marginBottom={false}
                                          />
                                        </div>
                                      </FlexDiv>
                                    )}
                                  </FlexDiv>

                                  <FlexDiv direction="column" gap={16}>
                                    {values.conditions
                                      // ?.filter((item: ICondition) => Number(item.condition_set_reference) === cs)
                                      ?.map((item: ICondition, index: number, arr: ICondition[]) => {
                                        if (Number(item.condition_set_reference) !== cs) {
                                          return null;
                                        }

                                        const { object, field, operator, type } = item;
                                        const fieldOptions = object
                                          ? dataOptions?.fetchAvaliableConditionOption?.objects[object]?.map(
                                              (item: any) => ({
                                                label: item.label,
                                                value: item.key,
                                                type: item.type,
                                              }),
                                            )
                                          : [];

                                        return (
                                          <>
                                            <FlexDiv gap={16} align="center">
                                              <FlexDiv direction="column" gap={8} grow={1} className="fields">
                                                {index === 0 && (
                                                  <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                                                    Fields
                                                  </AppText>
                                                )}
                                                <PhoenixMultiSelect
                                                  isMulti={false}
                                                  isClearable={false}
                                                  name={`conditions-${cs}-${index}_field`}
                                                  options={fieldOptions || []}
                                                  onChange={(value: any) => {
                                                    const prev_val = arr[index].field as string;
                                                    const current_val = value.value;

                                                    // Find the actual index in the original conditions array
                                                    const actualIndex = values.conditions.findIndex((item: any) =>
                                                      isEqual(item, arr[index]),
                                                    );

                                                    // Update the conditions array by mapping over it and updating the specific item
                                                    const updatedConditions = values.conditions?.map(
                                                      (item: any, i: number) => {
                                                        if (i === actualIndex) {
                                                          return {
                                                            ...item,
                                                            field: current_val,
                                                            type: value.type,
                                                          };
                                                        }
                                                        return item;
                                                      },
                                                    );

                                                    setFieldValue("conditions", updatedConditions);

                                                    resetCondition({
                                                      type: ResetObjectType.Field,
                                                      index: actualIndex,
                                                      prev_val,
                                                      current_val,
                                                    });
                                                  }}
                                                  value={{
                                                    label:
                                                      fieldOptions?.find((f: any) => f.value === field)?.label || field,
                                                    value: field,
                                                  }}
                                                  marginBottom={false}
                                                />
                                              </FlexDiv>

                                              <FlexDiv direction="column" gap={8} grow={1} className="operator">
                                                {index === 0 && (
                                                  <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                                                    Operator
                                                  </AppText>
                                                )}
                                                <PhoenixMultiSelect
                                                  isMulti={false}
                                                  isClearable={false}
                                                  name={`conditions-${cs}-${index}_operator`}
                                                  options={findOperatorsFromFieldType(
                                                    findTypeFromFieldKey(arr[index].object, arr[index].field),
                                                  )}
                                                  onChange={(value: any) => {
                                                    const prev_val = arr[index].operator as string;
                                                    const current_val = value.value;

                                                    // Find the actual index in the original conditions array
                                                    const actualIndex = values.conditions.findIndex((item: any) =>
                                                      isEqual(item, arr[index]),
                                                    );

                                                    // Update the conditions array by mapping over it and updating the specific item
                                                    const updatedConditions = values.conditions?.map(
                                                      (item: any, i: number) => {
                                                        if (i === actualIndex) {
                                                          return {
                                                            ...item,
                                                            operator: value.value,
                                                          };
                                                        }
                                                        return item;
                                                      },
                                                    );

                                                    setFieldValue("conditions", updatedConditions);

                                                    resetCondition({
                                                      type: ResetObjectType.Operator,
                                                      index: actualIndex,
                                                      prev_val,
                                                      current_val,
                                                    });
                                                  }}
                                                  value={[
                                                    {
                                                      label: formatMultipleTypeNames(operator as string),
                                                      value: operator,
                                                    },
                                                  ]}
                                                  marginBottom={false}
                                                />
                                              </FlexDiv>

                                              {!!arr[index].operator &&
                                                !["IsNull", "NotNull"].includes(arr[index].operator as string) && (
                                                  <FlexDiv
                                                    direction="column"
                                                    gap={8}
                                                    grow={1}
                                                    className={`value ${
                                                      ["Equal", "NotEqual"].includes(arr[index].operator as string)
                                                        ? "valueEqualNotEqual"
                                                        : ""
                                                    }`}
                                                  >
                                                    {index === 0 && (
                                                      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                                                        Value
                                                      </AppText>
                                                    )}
                                                    {renderValueSelect({
                                                      currentCondition: arr[index],
                                                      index: values.conditions.findIndex((item: any) =>
                                                        isEqual(item, arr[index]),
                                                      ),
                                                    })}
                                                  </FlexDiv>
                                                )}

                                              {renderCadenceSelect({
                                                currentCondition: arr[index],
                                                index,
                                                date_cadence:
                                                  dataOptions?.fetchAvaliableConditionOption?.date_cadence || "",
                                              })}

                                              {showDeleteFieldButton && (
                                                <div style={{ padding: index === 0 ? "24px 8px 0 0" : "0 8px 0 0" }}>
                                                  <PhoenixIcon
                                                    svg={xIcon}
                                                    size={24}
                                                    variant="neutral"
                                                    pointer
                                                    onClick={() => {
                                                      setFieldValue(
                                                        "conditions",
                                                        values.conditions.filter((_, i) => i !== index),
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              )}
                                            </FlexDiv>
                                          </>
                                        );
                                      })}
                                  </FlexDiv>

                                  <PlusButton
                                    onClick={() =>
                                      setFieldValue("conditions", [
                                        ...values.conditions,
                                        {
                                          object: values.conditions[cs - 1].object || "",
                                          field: "",
                                          operator: "",
                                          value: "",
                                          type: "",
                                          field_label: "",
                                          condition_set_reference: cs,
                                        },
                                      ])
                                    }
                                  >
                                    <PhoenixIcon
                                      size={16}
                                      svg={plus}
                                      variant="brand"
                                      color={theme.icon.brand.default}
                                      hoverColor={theme.icon.brand.default}
                                      alt="add condition"
                                      pointer
                                    />
                                  </PlusButton>

                                  {conditionSets.length > 1 && (
                                    <CloseDiv
                                      onClick={() => {
                                        // Filter out the condition sets that match the current set (cs)
                                        const filteredConditions = values.conditions.filter(
                                          (condition: any) => Number(condition.condition_set_reference) !== cs,
                                        );

                                        // Decrement the condition_set_reference for all conditions where the reference is greater than cs
                                        const updatedConditions = filteredConditions?.map((condition: any) => {
                                          if (Number(condition.condition_set_reference) > cs) {
                                            return {
                                              ...condition,
                                              condition_set_reference: Number(condition.condition_set_reference) - 1,
                                            };
                                          }
                                          return condition;
                                        });

                                        // Update the condition sets array
                                        setConditionSets(
                                          conditionSets.filter((s: number) => s !== cs)?.map((_, i: number) => i + 1),
                                        );

                                        // Update the form field with the new conditions array
                                        setFieldValue("conditions", updatedConditions);
                                      }}
                                    >
                                      <PhoenixIcon svg={trash} variant="danger" size={16} pointer />
                                    </CloseDiv>
                                  )}
                                </ConditionBody>
                              </ConditionBlock>
                            </>
                          );
                        })}

                        <LogicFieldWrapper style={{ marginBottom: !!errors.logic ? "24px" : 0 }}>
                          <PhoenixInputField
                            name="logic"
                            titleText="Rules Logic"
                            titleTextSpacing={8}
                            contextTextFontSize={10}
                            width={376}
                            value={values.logic}
                          />
                        </LogicFieldWrapper>

                        {leadRoutingOnUpdate && (
                          <>
                            {RULE_OPTIONS_WHEN_EDITING.includes("lead_creation_status") && (
                              <FlexDiv direction="column" gap={16} style={{ marginBottom: "40px" }}>
                                <AppText
                                  fontSize={10}
                                  fontWeight={600}
                                  letterSpacing={1}
                                  uppercase
                                  color={theme.text.neutral.secondary}
                                >
                                  Lead Creation Status
                                </AppText>
                                <FlexDiv direction="column" gap={16}>
                                  {LEAD_CREATION_STATUSES?.map((status) => (
                                    <FlexDiv align="center" gap={8}>
                                      <RadioButtonField name="lead_creation_status" id={status.id} />
                                      <AppText>{status.text}</AppText>
                                      {status.tip && (
                                        <PhoenixIcon
                                          svg={info}
                                          variant="brand"
                                          size={14}
                                          data-for="step-routing-rules-tooltip"
                                          data-tip={status.tip}
                                        />
                                      )}
                                    </FlexDiv>
                                  ))}
                                </FlexDiv>
                              </FlexDiv>
                            )}

                            {RULE_OPTIONS_WHEN_EDITING.includes("lead_status") && (
                              <FlexDiv direction="column" gap={16}>
                                <AppText
                                  fontSize={10}
                                  fontWeight={600}
                                  letterSpacing={1}
                                  uppercase
                                  color={theme.text.neutral.secondary}
                                >
                                  Lead Status
                                </AppText>
                                {LEAD_STATUSES?.map((item: any) => {
                                  const checked = values.los_rules.includes(item.value);
                                  return (
                                    <FlexDiv align="center" gap={8}>
                                      <PhoenixCheckbox
                                        checked={checked}
                                        onChange={() => {
                                          !!checked
                                            ? setFieldValue(
                                                "los_rules",
                                                values.los_rules.filter((rule: string) => rule !== item.value),
                                              )
                                            : setFieldValue("los_rules", [...values.los_rules, item.value]);
                                        }}
                                      />
                                      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                                        {item.title}
                                      </AppText>
                                    </FlexDiv>
                                  );
                                })}
                              </FlexDiv>
                            )}
                          </>
                        )}
                      </ConditionContainer>
                    )}

                    <ConditionContainer>
                      {RULE_OPTIONS_WHEN_EDITING.includes("rep_distribution") && (
                        <>
                          <AppText fontSize={20} fontWeight={500} lineHeight={26} style={{ marginBottom: "24px" }}>
                            ...Then Distribute to Reps According to These Rules
                          </AppText>

                          <RepSelectionContainer>
                            <div style={{ width: "100%" }}>
                              <div style={{ padding: "16px 16px 0px 16px" }}>
                                <AppText
                                  fontSize={10}
                                  fontWeight={600}
                                  letterSpacing={1}
                                  uppercase
                                  color={theme.text.neutral.secondary}
                                  style={{ marginBottom: "16px" }}
                                >
                                  Select reps to include in this rule
                                </AppText>
                                <FlexDiv direction="column" gap={16}>
                                  <PhoenixInput
                                    placeholder="Search Reps"
                                    insideLeftElementOverride={
                                      <PhoenixIcon
                                        svg={search}
                                        size={16}
                                        color={theme.icon.brand.default}
                                        hoverColor={theme.icon.brand.default}
                                      />
                                    }
                                    width={280}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      setFieldValue("available_fields_search", e.target.value);
                                    }}
                                    value={values.available_fields_search}
                                    displayNoContextText
                                  />

                                  <FlexDiv
                                    direction="column"
                                    gap={16}
                                    style={{ maxHeight: "160px", overflowY: "auto", paddingBottom: "8px" }}
                                  >
                                    {!!loadingOrg ? (
                                      <Loading />
                                    ) : errorOrg ? (
                                      <AppErrorText>Error loading reps</AppErrorText>
                                    ) : (
                                      !!dataOrg?.fetchOrganization?.Reps &&
                                      dataOrg?.fetchOrganization?.Reps.slice()
                                        .filter(
                                          (item) =>
                                            !values.available_fields_search ||
                                            `${item.first_name} ${item.last_name}`
                                              .toLowerCase()
                                              .includes(values.available_fields_search.toLowerCase()),
                                        )
                                        .filter((item: any) => !values.rep_ids.includes(item.id))
                                        .sort((a: any, b: any) =>
                                          sortAlphabetically
                                            ? `${a.first_name} ${a.last_name}`.localeCompare(
                                                `${b.first_name} ${b.last_name}`,
                                              )
                                            : b.revenue_per_day - a.revenue_per_day,
                                        )
                                        ?.map((item: any) => (
                                          <FlexDiv align="center" gap={8} key={item.id}>
                                            <PhoenixCheckbox
                                              checked={selectedReps.included_list.includes(item.id)}
                                              onChange={() => {
                                                selectedReps.included_list.includes(item.id)
                                                  ? setSelectedReps({
                                                      ranked_list: selectedReps.ranked_list,
                                                      included_list: selectedReps.included_list
                                                        .slice()
                                                        .filter((li: string) => li !== item.id),
                                                    })
                                                  : setSelectedReps({
                                                      ranked_list: selectedReps.ranked_list,
                                                      included_list: [...selectedReps.included_list, item.id],
                                                    });
                                              }}
                                            />
                                            <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                                              {item.first_name} {item.last_name}
                                              {!sortAlphabetically ? `(${item.revenue_per_day.toFixed(2)})` : ""}
                                            </AppText>
                                          </FlexDiv>
                                        ))
                                    )}
                                  </FlexDiv>
                                </FlexDiv>
                              </div>
                              <DistributeButtonContainer justify="space-between" align="center">
                                <PhoenixAppButton
                                  variant="danger-outline"
                                  buttonType="secondary"
                                  onClick={() => {
                                    setSelectedReps({
                                      ranked_list: [],
                                      included_list: [],
                                    });
                                  }}
                                >
                                  Clear Selection
                                </PhoenixAppButton>
                                <PhoenixAppButton
                                  variant="brand"
                                  buttonType="secondary"
                                  onClick={() => {
                                    const filteredReps = selectedReps.included_list.filter(
                                      (id) => !values.rep_ids.includes(id)
                                    );

                                    setFieldValue("rep_ids", [...new Set([...values.rep_ids, ...filteredReps])]);

                                    setSelectedReps({
                                      included_list: [],
                                      ranked_list: selectedReps.ranked_list,
                                    });
                                  }}
                                >
                                  Add These Reps
                                </PhoenixAppButton>
                              </DistributeButtonContainer>
                            </div>

                            <RankSelectedRepsContainer>
                              <FlexDiv align="center" justify="space-between">
                                <AppText
                                  fontSize={10}
                                  fontWeight={600}
                                  letterSpacing={1}
                                  uppercase
                                  color={theme.text.neutral.secondary}
                                  style={{ marginBottom: "16px" }}
                                >
                                  Rank Selected Reps
                                </AppText>

                                {values?.rep_ids?.length > 1 && (
                                  <FlexDiv
                                    onClick={async () => {
                                      await fetchRepOrder({
                                        variables: {
                                          user_ids: values.rep_ids,
                                        },
                                      });
                                    }}
                                    align="center"
                                    gap={4}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <PhoenixIcon
                                      svg={refresh}
                                      color={theme.icon.brand.default}
                                      hoverColor={theme.icon.brand.default}
                                      pointer
                                      size={12}
                                    />
                                    <AppText
                                      fontSize={10}
                                      fontWeight={600}
                                      lineHeight={14}
                                      color={theme.text.brand.primary}
                                    >
                                      Sellfire Suggested Ranking
                                    </AppText>
                                  </FlexDiv>
                                )}
                              </FlexDiv>
                              <DragDropContext onDragEnd={onDragEndReps}>
                                <Droppable droppableId="droppablereps">
                                  {(provided, snapshot) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                      <DragElements>
                                        {!!loadingOrg ? (
                                          <Loading />
                                        ) : errorOrg ? (
                                          <AppErrorText>Error loading reps</AppErrorText>
                                        ) : (
                                          values.rep_ids
                                            ?.map((item: any) =>
                                              returnIndividualDataFromArrayBasedOnID({
                                                id: item,
                                                array: dataOrg?.fetchOrganization?.Reps || ([] as any),
                                              }),
                                            )
                                            ?.map((item: any, index: number) => (
                                              <Draggable key={`rep_${item.id}`} draggableId={item.id} index={index}>
                                                {(provided, snapshot) => (
                                                  <div
                                                    key={`$rep_checkbox_${item.id}`}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                  >
                                                    <RepDraggableDiv>
                                                      <PhoenixIcon
                                                        svg={reorder}
                                                        size={16}
                                                        variant="neutral"
                                                        alt="drag handle"
                                                        style={{ cursor: "grab" }}
                                                        {...provided.dragHandleProps}
                                                      />
                                                      <SelectedRepPill>
                                                        <AppText
                                                          fontSize={8}
                                                          fontWeight={600}
                                                          lineHeight={12}
                                                          uppercase
                                                          color={theme.text.brand.primary}
                                                        >
                                                          {`${item.first_name} ${item.last_name}`}
                                                        </AppText>
                                                        <PhoenixIcon
                                                          svg={xIcon}
                                                          size={12}
                                                          variant="brand"
                                                          pointer
                                                          onClick={() => {
                                                            setFieldValue(
                                                              "rep_ids",
                                                              values.rep_ids.filter((id: string) => id !== item.id),
                                                            );
                                                          }}
                                                        />
                                                      </SelectedRepPill>
                                                    </RepDraggableDiv>
                                                  </div>
                                                )}
                                              </Draggable>
                                            ))
                                        )}
                                      </DragElements>
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </RankSelectedRepsContainer>
                          </RepSelectionContainer>

                          <AppText fontSize={18} fontWeight={500} style={{ margin: "16px 0" }}>
                            Priority Order Of Reps
                          </AppText>
                          <InputLabel>
                            How would you like to determine the order in which the above reps are prioritized?
                          </InputLabel>
                          <RadioLabel style={{ marginBottom: "16px" }}>
                            <PhoenixRadio
                              size={16}
                              selected={values.distribution_method === "RoundRobin"}
                              onClick={() => {
                                setDateRange(undefined);
                                setMetric(undefined);
                                setFieldValue("distribution_method", "RoundRobin");
                              }}
                            />
                            <AppText>Round Robin (Even Distribution)</AppText>
                          </RadioLabel>
                          <RadioLabel style={{ marginBottom: "16px" }}>
                            <PhoenixRadio
                              size={16}
                              selected={values.distribution_method === "Ranking"}
                              onClick={() => {
                                setDateRange(undefined);
                                setMetric(undefined);
                                setFieldValue("distribution_method", "Ranking");
                              }}
                            />
                            <AppText>Manual (By Stack Rank)</AppText>
                          </RadioLabel>
                          <RadioLabel style={{ marginBottom: "16px" }}>
                            <PhoenixRadio
                              size={16}
                              selected={values.distribution_method === "Metric"}
                              onClick={() => {
                                setMetric("SetRate");
                                setDateRange(DATE_RANGES_WITHOUT_SPACE[0].value);
                                setFieldValue("distribution_method", "Metric");
                              }}
                            />
                            <AppText>Dynamic (By Stack Rank)</AppText>
                          </RadioLabel>

                          {values.distribution_method === "Metric" && (
                            <FlexDiv direction="column" gap={16} style={{ width: "396px" }}>
                              <PhoenixMultiSelect
                                name="metric-select"
                                isMulti={false}
                                isClearable={false}
                                marginBottom={false}
                                options={metricOptions}
                                value={metricOptions?.find((item: OptionItem) => item.value === metric)}
                                onChange={(value: any) => {
                                  setMetric(value?.value);
                                }}
                              />

                              <PhoenixMultiSelect
                                name="metric-select"
                                titleText="Date Range"
                                isMulti={false}
                                isClearable={false}
                                options={DATE_RANGES_WITHOUT_SPACE}
                                value={DATE_RANGES_WITHOUT_SPACE.find((item: OptionItem) => item.value === date_range)}
                                onChange={(value: any) => {
                                  setDateRange(value?.value);
                                }}
                              />
                            </FlexDiv>
                          )}

                          <InputLabel>
                            How should we determine if a Rep is available to take a Priority Lead?
                          </InputLabel>
                          <RadioGroup>
                            <RadioLabel>
                              <RadioButtonField name="rep_selection_method" id="FirstRep" />
                              <AppText>Rep is not on any call (Emphasize Speed to Dial)</AppText>
                            </RadioLabel>
                            <RadioLabel>
                              <RadioButtonField name="rep_selection_method" id="BestRep" />
                              <AppText>
                                Rep may be on a call, but is not in a Scheduled Call Back, Demo, or Decision Call
                                (Emphasize Getting Leads to Best Reps)
                              </AppText>
                            </RadioLabel>
                          </RadioGroup>
                        </>
                      )}
                    </ConditionContainer>

                    {RULE_OPTIONS_WHEN_EDITING.includes("date") && (
                      <div style={{ paddingTop: "24px" }}>
                        <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                          Start Date
                        </AppText>

                        <FlexDiv direction="column" gap={8}>
                          <ReactDatesWrapper>
                            <SingleDatePicker
                              id="step-routing--date-picker"
                              required
                              date={moment(values?.deferral_date_time || undefined)}
                              onDateChange={(date) => setFieldValue("deferral_date_time", date)}
                              focused={focused}
                              onFocusChange={({ focused }) => setFocused(focused)}
                              numberOfMonths={1}
                              showDefaultInputIcon
                              inputIconPosition={"after"}
                            />
                          </ReactDatesWrapper>

                          <AppText fontSize={10} fontWeight={500} lineHeight={14} color={theme.text.neutral.secondary}>
                            This rep will not be prompted to call this lead until this date and time passes. If not
                            specified, the lead will be available to call immediately.
                          </AppText>
                        </FlexDiv>
                      </div>
                    )}
                    {RULE_OPTIONS_WHEN_EDITING.includes("email_updates") && (
                      <>
                        <EmailsSwitchDiv>
                          <Switch
                            onChange={(checked) => {
                              setFieldValue("email_updates", checked);
                            }}
                            onColor={theme.PRIMARY500}
                            offColor={theme.NEUTRAL200}
                            checked={values.email_updates}
                            height={16}
                            width={32}
                            handleDiameter={12}
                            checkedIcon={false}
                            uncheckedIcon={false}
                          />
                          <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                            Send email updates when this rule has been applied
                          </AppText>
                        </EmailsSwitchDiv>
                        {values.email_updates &&
                          (loadingOrg ? (
                            <Loading />
                          ) : errorOrg ? (
                            <AppErrorText>Error loading reps</AppErrorText>
                          ) : (
                            !!dataOrg &&
                            !!dataOrg?.fetchOrganization &&
                            !!dataOrg.fetchOrganization?.Reps && (
                              <div style={{ maxWidth: "732px" }}>
                                <CreatableSelect
                                  isClearable
                                  isMulti
                                  onChange={handleChangeEmails}
                                  placeholder="Enter email addresses..."
                                  options={dataOrg?.fetchOrganization?.Reps?.map((item: any) => ({
                                    label: `${item.email} (${item.first_name} ${item.last_name})`,
                                    value: item.email,
                                  }))}
                                  defaultValue={dataOrg?.fetchOrganization?.Reps.filter((item: any) =>
                                    values?.emails_list.includes(item.email),
                                  )?.map((item: any) => ({
                                    label: `${item.email} (${item.first_name} ${item.last_name})`,
                                    value: item.email,
                                  }))}
                                />
                                {!!`${JSON.stringify(errors.emails_list)}`.includes(
                                  "Email addresses must be valid",
                                ) && (
                                  <AppErrorText style={{ marginBottom: "12px" }}>
                                    Please make sure the email addresses are valid
                                  </AppErrorText>
                                )}
                              </div>
                            )
                          ))}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {!!rule_title && <AppTitleWithSpacing>{rule_title}</AppTitleWithSpacing>}
                    <>
                      {rule === "widget" && (
                        <WidgetCampaignHeader>
                          <TableHeadingText>Rule Name</TableHeadingText>
                          <TableHeadingText>Modified</TableHeadingText>
                          <TableHeadingText>Active</TableHeadingText>
                          <TableHeadingText>Manage</TableHeadingText>
                        </WidgetCampaignHeader>
                      )}
                      {rule === "lead" && (
                        <HeaderContainer>
                          <ColHeader width={leadTableCellWidths[0]}>Priority</ColHeader>
                          <ColHeader width={leadTableCellWidths[1]}>Rule Name</ColHeader>
                          <ColHeader width={leadTableCellWidths[2]}>Last Modified</ColHeader>
                          <ColHeader width={leadTableCellWidths[3]}>{toggleLabel}</ColHeader>
                          <ColHeader width={leadTableCellWidths[4]}>Inbound Concierge Call Me</ColHeader>
                          <ColHeader width={leadTableCellWidths[5]}>Inbound Concierge Schedule Meeting</ColHeader>
                          <ColHeader width={leadTableCellWidths[6]} center>
                            Lifetime Leads Routed
                          </ColHeader>
                          <ColHeader width={leadTableCellWidths[7]} center>
                            Manage
                          </ColHeader>
                        </HeaderContainer>
                      )}
                    </>
                    {loadingRules ? (
                      <Loading />
                    ) : errorRules ? (
                      <AppErrorText>Error loading rules</AppErrorText>
                    ) : !!dataRules ? (
                      <>
                        {rule === "widget" && (
                          <DragDropContext onDragEnd={onDragEndRules}>
                            <Droppable droppableId="droppable">
                              {(provided, snapshot) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                  {dataRules
                                    .slice()
                                    .sort((a, b) => a.priority - b.priority)
                                    ?.map((item: any, index: number) => (
                                      <Draggable key={`table_${item.id}`} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                          <WidgetCampaignTable>
                                            <TableText>{item.name}</TableText>
                                            <TableText>
                                              {!!item.updated_at && moment(item.updated_at).format("MM/DD/YYYY")}
                                            </TableText>
                                            <TableDiv>
                                              <Switch
                                                onChange={(checked) => {
                                                  updateRoutingRuleStatus({
                                                    variables: {
                                                      rule_id: item.id,
                                                      active: checked,
                                                    },
                                                  });
                                                  setDataRules(
                                                    dataRules?.map((item2: any) => {
                                                      if (item2.id === item.id) {
                                                        return {
                                                          ...item2,
                                                          active: checked,
                                                        };
                                                      }
                                                      return item2;
                                                    }),
                                                  );
                                                }}
                                                onColor={theme.PRIMARY500}
                                                offColor={theme.NEUTRAL200}
                                                checked={item.active}
                                                height={24}
                                                width={40}
                                                handleDiameter={18}
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                              />
                                            </TableDiv>

                                            <TableText>
                                              <EditSpan
                                                onClick={() => {
                                                  !!togglePageTitle && togglePageTitle();

                                                  history.replace(`/system-config/${URL}/${item.id}`);
                                                }}
                                              >
                                                Edit
                                              </EditSpan>
                                            </TableText>
                                          </WidgetCampaignTable>
                                        )}
                                      </Draggable>
                                    ))}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        )}
                        {rule === "lead" && (
                          <LeadTable
                            dataRules={dataRules}
                            setDataRules={setDataRules}
                            onDragEndRules={onDragEndRules}
                            leadTableCellWidths={leadTableCellWidths}
                            updateRoutingRuleStatus={updateRoutingRuleStatus}
                            updateRoutingRuleApplyToInboundConciergeCallMeNow={
                              updateRoutingRuleApplyToInboundConciergeCallMeNow
                            }
                            updateRoutingRuleApplyToInboundConciergeSchedule={
                              updateRoutingRuleApplyToInboundConciergeSchedule
                            }
                            togglePageTitle={togglePageTitle}
                            URL={URL}
                          />
                        )}
                      </>
                    ) : (
                      <AppText style={{ padding: "32px" }}>
                        No rules have been added. Adding some will display them here.
                      </AppText>
                    )}
                  </>
                )}
              </TopAlignDiv>
              {isOnboarding === true && (
                <CenterDiv>
                  <SaveAndContinueButton
                    type="submit"
                    onClick={() => {
                      !!step && history.push(`/onboarding?step=${step + 1}`);
                    }}
                  >
                    Continue
                  </SaveAndContinueButton>
                </CenterDiv>
              )}
            </>
          );
        }}
      </Formik>
    </DisappearingDiv>
  );
};

const PlusButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;
  padding: 8px;
  margin-top: 16px;

  border: 1px solid ${theme.buttonborder.brand_outline.default};
  border-radius: 4px;

  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  :hover {
    background-color: ${theme.buttonfill.brand_outline.hover};
  }
`;

const RepSelectionContainer = styled.div`
  display: flex;
  /* flex-direction: column; */

  width: 100%;

  border: 1px solid ${theme.border.neutral.secondary};
  border-radius: 8px;
`;

const DistributeButtonContainer = styled(FlexDiv)`
  padding: 16px;
  border-top: 1px solid ${theme.border.neutral.secondary};
`;

const RankSelectedRepsContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 16px 24px;

  border-left: 1px solid ${theme.border.neutral.secondary};
`;

const SelectedRepPill = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;

  border-radius: 4px;
  background-color: ${theme.fill.brand.secondary};
`;

const ConditionContainer = styled.div`
  min-width: 1075px;
  padding: 24px;
  margin-top: 24px;

  border: 1px solid ${theme.border.neutral.primary};
  border-radius: 8px;
  background-color: ${theme.fill.neutral.primary};
`;

const EmailsSwitchDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 48px 0px 16px 0px;
`;

const AppTitleWithSpacing = styled(AppTitle2)`
  margin-bottom: 16px;
`;

const RepDraggableDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  margin-bottom: 16px;
`;

const DragElements = styled.div`
  max-height: 208px;
  min-height: 208px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 40px;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;

  input {
    accent-color: ${theme.PRIMARY500};
  }
`;

const TableText = styled(AppText)<IOpacity>`
  font-size: 12px;
  opacity: ${(props) => (props.translucent ? 0.5 : 1)};
  padding-left: 16px;
  padding-right: 16px;
  border-right: 1px solid ${theme.NEUTRAL200};
  padding-top: 11px;
  width: 100%;
`;

const RulesSelect = styled(FormSelectField)`
  background-color: ${theme.WHITE_COLOR};
`;

const EditSpan = styled.span`
  color: ${theme.PRIMARY500};
  font-weight: 600;
  cursor: pointer;
`;

const CloseDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
`;

const ConditionBlock = styled.div`
  position: relative;

  display: flex;
  gap: 64px;
  position: relative;

  width: 100%;
  min-width: 1025px;
  padding: 16px;
  margin-bottom: 16px;

  border: 1px solid ${theme.border.neutral.secondary};
  border-radius: 8px;
`;

const ConditionBody = styled.div`
  width: 100%;
`;

const InputLabel = styled(AppText)`
  margin-bottom: 8px;
  margin-top: 8px;
  font-weight: 500;
`;

interface IOpacity {
  translucent?: boolean;
}

const TableDiv = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  border-right: 1px solid ${theme.NEUTRAL200};
  padding-top: 8px;
  width: 100%;
`;
const TableHeadingMainText = styled(AppText)`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 12px;
  font-weight: 600;
  padding-left: 11px;
  padding-top: 11px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  background-color: ${theme.NEUTRAL200};
  border: 1px solid ${theme.NEUTRAL200};
  :last-child {
    border-right: none;
  }
`;

const DateTimePickerWrapper = styled.div`
  .react-datetime-picker {
    height: 40px;
    * {
      /* color: ${theme.PRIMARY600} !important; */
    }
    /* Set the width of the date picker */
    /* border: 1px solid ${theme.PRIMARY600}; */
    border: none;
  }
`;

const TableHeadingTitleText = styled(AppText)`
  position: absolute;
  top: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #949ab1;
`;

interface TableHeadingProps {
  title?: string;
  children?: any;
}

const TableHeadingText = (props: TableHeadingProps) => {
  return (
    <TableHeadingMainText>
      {props.children}
      <TableHeadingTitleText>{props.title}</TableHeadingTitleText>
    </TableHeadingMainText>
  );
};

const WidgetCampaignTable = styled.div`
  display: grid;
  width: calc(100%);
  overflow-x: hidden;
  grid-template-columns: 1fr 200px 147px 147px;
  grid-template-rows: 40px;
  align-items: stretch;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  border-top: 1px solid ${theme.NEUTRAL200};
  margin-top: -1px;
  /* margin-left: 1px; */
  background-color: ${theme.WHITE_COLOR};
`;

const WidgetCampaignHeader = styled.div`
  display: grid;
  width: calc(100%);
  overflow-x: hidden;
  grid-template-columns: 1fr 200px 147px 147px;
  grid-template-rows: 50px;
  align-items: stretch;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  border-top: 1px solid ${theme.NEUTRAL200};
  margin-top: -1px;
  /* margin-left: 1px; */
  background-color: ${theme.WHITE_COLOR};
`;

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  background-color: ${theme.fill.brand.secondary};
`;

const ColHeader = styled.div<{ width: number; center?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  align-items: center;
  padding: 8px 16px;

  width: ${(props) => props.width}px;
  flex-grow: 1;

  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const TopAlignDiv = styled.div`
  width: 100%;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SaveAndContinueButton = styled(AppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
`;

enum ResetObjectType {
  Object = "object",
  Field = "field",
  Operator = "operator",
  Value = "value",
  Type = "type",
}

const recordMatchOptions = [
  { label: "Any Record(s)", value: "Any" },
  { label: "All Records", value: "All" },
  { label: "Newest Record", value: "Newest" },
  { label: "Oldest Record", value: "Oldest" },
  { label: "Most Recently Updated", value: "MostRecentlyUpdated" },
];

const LogicFieldWrapper = styled.div`
  transition: margin-bottom 0.2s ease-in-out;
`;

const metricOptions = [
  { label: "Close Rate", value: "CloseRate" },
  { label: "Set Rate", value: "SetRate" },
  { label: "Hold Rate", value: "HoldRate" },
];

const leadTableCellWidths = [82, 226, 226, 226, 226, 260, 172, 110];

const findRoutingURL = (rule: string) => {
  switch (rule) {
    case "lead":
      return "routing-rules";
    case "widget":
      return "inbound-concierge";
    default:
      return "routing-rules";
  }
};

// what options to display when editing or creating a rule
const findRuleOptions = (rule: string) => {
  switch (rule) {
    case "lead":
      return ["name", "conditions", "lead_creation_status", "lead_status", "rep_distribution", "date", "email_updates"];

    case "widget":
      return ["name", "conditions"];

    default:
      return [
        "name",
        "priority",
        "conditions",
        "lead_creation_status",
        "lead_status",
        "rep_distribution",
        "date",
        "email_updates",
      ];
  }
};

export { RulesTableV2 };
