import styled from "styled-components";
import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { AppButton, AppText, AppInput, Loading, AppErrorText, AppSidebarCard, AppMultiSelect } from "../../UI";
import { NewAppButton } from "../../UI/NewAppButton";
import { theme } from "../../../utils/theme";
import { mobileSize } from "../../../utils/breakpoints";
import { useMutation, useQuery, useLazyQuery, gql } from "@apollo/client";
import { isLoggedInVar, loggedInUser } from "../../../apollo/cache";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { InputField } from "../../Field";
import { Link, useLocation, useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import { BACKEND_URL } from "../../../utils/variables";
import { appToast } from "../../../utils/toast";
import { OutsideEvent } from "../../Dumb";
import { iconArrowBackNew, iconDownSmallBlue, hubspotLogo, salesforceLogo, pandaDocLogo } from "../../../images";
import { ModalContext } from "../../../context/ModalContext";
import {
  DisconnectIntegrationModal,
  ErrorLogReportModal,
  IntegrationMapRules,
  IntegrationMapSyncPicklist,
  Modal,
} from "../../modal/";
import { restAPI } from "../../../apollo";
import Switch from "react-switch";
import { IntegrationCard } from "../../Smart";
import moment from "moment";
import { useFlags } from "launchdarkly-react-client-sdk";
import { IntegrationReportingComponent } from "./IntegrationReportingComponent";
import CreatableSelect from "react-select/creatable";
import { IIntegrations } from "../../../types";
import { PhoenixAppButton } from "../../UI/Phoenix";
interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
  togglePageTitle?: () => void;
}

const FETCH_ORGANIZATION = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      hubspot_last_sync_date
      Reps {
        id
        full_name
        id
        first_name
        last_name
        revenue_per_day
        email
      }
      send_error_log_email
      error_log_email_address
    }
  }
`;

const COMPLETE_ONBOARDING = gql`
  mutation completeOrganizationOnboarding {
    completeOrganizationOnboarding {
      id
      name
      created_at
      updated_at
      default_demo_time_duration
      enable_pay_period_view
      enable_modified_metrics
      onboardComplete
      timezone
      use_revenue_planning
      salesforce_domain
      preferred_goal_setting
      inbound_dm_connect_rate
      inbound_set_rate
      inbound_scheduled_hold_rate
      inbound_lt_hold_rate
      inbound_close_rate
      inbound_revenue_value_of_sale
      outbound_dm_connect_rate
      outbound_set_rate
      outbound_scheduled_hold_rate
      outbound_lt_hold_rate
      outbound_close_rate
      outbound_revenue_value_of_sale
      initial_sdr_sets_reps_day
      maxium_day_lag_set_to_schedule
      resting_period_after_unsuccessful_cold_call
      resting_period_after_unsuccessful_demo
      number_of_aes_taking_demos
      estimated_average_num_of_weekly_overbooked_demo_slots
      sms_opt_in_dialog
      reps_can_view_all_notes
    }
  }
`;

const ADD_EMAIL_TO_ERROR_REPORTING = gql`
  mutation AddEmailToErrorReporting($emails: [String], $active: Boolean) {
    addEmailToErrorReporting(emails: $emails, active: $active)
  }
`;

const FETCH_INTEGRATION_MAPPINGS = gql`
  query fetchIntegrationMappings($integration_type: INTEGRATION_TYPE!, $template_id: String) {
    fetchIntegrationMappings(integration_type: $integration_type, template_id: $template_id) {
      id
      opsiq_object
      opsiq_field
      opsiq_field_label
      opsiq_field_type
      external_object
      external_field
      external_field_label
      sync_rule
      trigger_events
      mapping_type
      integration_type
      organization_id
      created_at
      updated_at
      status
      external_template_id
      inspect
    }
  }
`;

export const CHECK_INTEGRATION_STATUS = gql`
  query checkIntegrationStatus {
    checkIntegrationStatus
  }
`;

const DISCONNECT_INTEGRATION = gql`
  mutation disconnectIntegration($integration_type: INTEGRATION_TYPE!) {
    disconnectIntegration(integration_type: $integration_type)
  }
`;

export const FETCH_PANDADOC_TEMPLATE_ADMIN = gql`
  query fetchAllPandaDocTemplateForAdmin {
    fetchAllPandaDocTemplateForAdmin {
      template_id
      template_name
      active
      organization_id
    }
  }
`;

export const FETCH_PANDADOC_TEMPLATE_REP = gql`
  query fetchAllPandaDocTemplateForRep {
    fetchAllPandaDocTemplateForRep {
      template_id
      template_name
      active
      organization_id
    }
  }
`;

export const UPDATE_TEMPLATE_ACTIVE_STATUS = gql`
  mutation updateTemplateActiveStatus($active: Boolean!, $template_id: String!) {
    updateTemplateActiveStatus(active: $active, template_id: $template_id) {
      template_id
      template_name
      organization_id
      active
    }
  }
`;

export const SYNC_PANDADOC_TEMPLATE = gql`
  mutation syncPandaDocTemplate {
    syncPandaDocTemplate {
      template_id
      template_name
      active
      organization_id
    }
  }
`;

const dummySystemFields = [
  {
    id: "1",
    opsiq_field: "First Name",
    external_field: "Contact first name",
    sync_rule: "Prefer Salesforce unless blank",
    status: "Ok",
  },
  { id: "2", opsiq_field: "Last Name", external_field: "Contact last name", sync_rule: "Two-way", status: "Issue" },
  { id: "3", opsiq_field: "Business Name", external_field: "Business", sync_rule: "Two-way", status: "Ok" },
  {
    id: "4",
    opsiq_field: "Country",
    external_field: "Country",
    sync_rule: "Prefer Salesforce unless blank",
    status: "Ok",
  },
  { id: "5", opsiq_field: "Email", external_field: "Email address", sync_rule: "Don’t Sync", status: "Issue" },
];

const integrationsList = [
  {
    name: "SalesForce",
    url: BACKEND_URL + "/salesforce/redirect",
    logo: salesforceLogo,
    description: "Sync lead and sales data with your Salesforce account",
  },
  {
    name: "HubSpot",
    url: BACKEND_URL + "/hubspot/redirect",
    logo: hubspotLogo,
    description: "Import leads and sync data with HubSpot",
  },
  {
    name: "PandaDoc",
    url: BACKEND_URL + "/pandadoc/redirect",
    logo: pandaDocLogo,
    description: "Integrate sales contracts with PandaDoc",
  },
];

interface CreateOptionsProps {
  drillInPage: () => void;
  configureOption: string;
  togglePageTitle?: () => void;
  manageTemplatesOn: () => void;
  integrationReportingOn: () => void;
}

interface ManageTemplatesProps {
  backToIntegrations: () => void;
}

interface FieldMappingProps {
  tableType: string;
  dataMappings: any;
  errorMappings: any;
  loadingMappings: any;
}

const ManageTemplatesComponent: React.FC<ManageTemplatesProps> = ({ backToIntegrations }) => {
  const { data: dataVisibleTemplates, loading: loadingVisibleTemplates, error: errorVisibleTemplates } = useQuery(
    FETCH_PANDADOC_TEMPLATE_ADMIN,
  );

  const [updateTemplateActiveStatus] = useMutation(UPDATE_TEMPLATE_ACTIVE_STATUS, {
    async onCompleted({ updateTemplateActiveStatus }) {
      console.log("updateTemplateActiveStatus: ", updateTemplateActiveStatus);
      appToast("Updated template visibility");
      if (!updateTemplateActiveStatus) {
        return;
      }
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `updateTemplateActiveStatus GraphQL Error: ${message}`,
      });
      console.log("Error in updateTemplateActiveStatus: ", message);
    },
    refetchQueries: ["fetchAllPandaDocTemplateForAdmin"],
  });

  const [syncPandaDocTemplate, { loading: loadingReSync }] = useMutation(SYNC_PANDADOC_TEMPLATE, {
    async onCompleted({ syncPandaDocTemplate }) {
      console.log("syncPandaDocTemplate: ", syncPandaDocTemplate);
      if (!syncPandaDocTemplate) {
        return;
      }
      appToast(`Re-sync with PandaDoc was successful`);
    },
    onError({ message }) {
      appToast(`${message}`);
      Sentry.captureEvent({
        message: `syncPandaDocTemplate GraphQL Error: ${message}`,
      });
      console.log("Error in syncPandaDocTemplate: ", message);
    },
    refetchQueries: ["fetchAllPandaDocTemplateForAdmin"],
  });

  return (
    <TopAlignDiv>
      <SpaceBetweenDiv>
        <BackButton
          // borderless
          onClick={() => {
            backToIntegrations();
          }}
        >
          <img style={{ marginRight: "8px" }} src={iconArrowBackNew} alt="Back" />
          Back to Connected apps
        </BackButton>
        <div style={{ width: "200px" }}>
          {loadingReSync ? (
            <Loading />
          ) : (
            <NewAppButton variant={"primary"} height={40} onClick={async () => await syncPandaDocTemplate()}>
              Re-sync Templates
            </NewAppButton>
          )}
        </div>
      </SpaceBetweenDiv>
      <AppSidebarCard title="Manage Templates">
        <ManageTemplatesTable>
          <TableHeadingText>Name of Template</TableHeadingText>
          <TableHeadingText>Visible in Sellfire</TableHeadingText>
        </ManageTemplatesTable>
        {loadingVisibleTemplates ? (
          <Loading />
        ) : errorVisibleTemplates ? (
          <AppErrorText>Error loading templates data</AppErrorText>
        ) : (
          !!dataVisibleTemplates?.fetchAllPandaDocTemplateForAdmin &&
          dataVisibleTemplates.fetchAllPandaDocTemplateForAdmin
            .slice()
            .sort((a: any, b: any) =>
              `${a?.template_name} ${a?.template_id}`
                .toLocaleLowerCase()
                .localeCompare(`${b?.template_name} ${b?.template_id})`.toLocaleLowerCase()),
            )
            ?.map((item: any) => (
              <ManageTemplatesTableData id={item.template_id}>
                <TallTableText>{item.template_name}</TallTableText>
                <div style={{ padding: "9px 16px" }}>
                  <Switch
                    onChange={async (checked: boolean) =>
                      await updateTemplateActiveStatus({
                        variables: { template_id: item.template_id, active: checked },
                      })
                    }
                    checked={item.active}
                    onColor={theme.PRIMARY500}
                    offColor={theme.NEUTRAL200}
                    height={32}
                    width={54}
                    handleDiameter={24}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </ManageTemplatesTableData>
            ))
        )}
      </AppSidebarCard>
    </TopAlignDiv>
  );
};

const FieldMappingTable: React.FC<FieldMappingProps> = ({
  tableType,
  dataMappings,
  errorMappings,
  loadingMappings,
}) => {
  const {
    // deprecated
    integrationErrorLogModal,
    selectedIntegration,
    setSelectedIntegration,
    selectedIntegrationLabel,
    showIntegrationMapRulesModal,
    setShowIntegrationMapRulesModal,
    integrationMapPicklistModal,
    setIntegrationMapPicklistModal,
    mapStateData,
    setMapStateData,
    resetMapStateData,
    editIntegrationType,
    setEditIntegrationType,
    templateId,
    setTemplateId,
  } = useContext(ModalContext);

  // const { data: dataMappings, loading: loadingMappings, error: errorMappings, refetch: refetchMappings } = useQuery(
  //   FETCH_INTEGRATION_MAPPINGS,
  //   {
  //     variables: {
  //       integration_type: selectedIntegration,
  //       template_id: templateId,
  //     },
  //     fetchPolicy: "network-only",
  //   },
  // );

  // useEffect(() => {
  //   if (!!selectedIntegration && !!templateId) {
  //     refetchMappings();
  //   }
  // }, [selectedIntegration, templateId]);

  const convertRuleToLabel = (label: string) => {
    switch (label) {
      case "ExternalToOpsiq":
        return `Always use ${selectedIntegrationLabel}`;
      case "OpsiqToExternal":
        return `Always use Sellfire`;
      case "Both":
        return `Two-way`;
      case "None":
        return `Don't sync`;
      default:
        return "No rule set";
    }
  };
  const formatTableTypeName = (name: string) => {
    switch (name) {
      case "System":
        return "SystemField";
      case "Custom":
        return "CustomField";
      case "Static":
        return "Static";
      default:
        break;
    }
  };
  let mappingType = formatTableTypeName(tableType);

  return (
    <>
      {tableType === "System" ? (
        <AddNewDiv>
          <ConfirmButton
            variant={"primary"}
            disabled={selectedIntegration === "PandaDoc" && !templateId}
            onClick={() => {
              setEditIntegrationType(tableType);
              resetMapStateData();
              setShowIntegrationMapRulesModal(true);
            }}
          >
            {`Map ${tableType} Field`}
          </ConfirmButton>
        </AddNewDiv>
      ) : (
        <AddNewBottomDiv>
          <ConfirmButton
            variant={"primary"}
            disabled={selectedIntegration === "PandaDoc" && !templateId}
            onClick={() => {
              setEditIntegrationType(tableType);
              resetMapStateData();
              setShowIntegrationMapRulesModal(true);
            }}
          >
            {`Map ${tableType} Field`}
          </ConfirmButton>
        </AddNewBottomDiv>
      )}
      <AppSidebarCard title={`${tableType} Field Mapping`}>
        <CampaignTable selectedIntegration={selectedIntegration}>
          <TableHeadingText>Sellfire Object</TableHeadingText>
          <TableHeadingText>Sellfire Field</TableHeadingText>
          {selectedIntegration !== "PandaDoc" && <TableHeadingText>{selectedIntegrationLabel} Object</TableHeadingText>}
          <TableHeadingText>{selectedIntegrationLabel} Field</TableHeadingText>
          {selectedIntegration !== "PandaDoc" && <TableHeadingText>Sync Rule</TableHeadingText>}
          {selectedIntegration !== "PandaDoc" && <TableHeadingText>Events</TableHeadingText>}
          {selectedIntegration !== "PandaDoc" && <TableHeadingText>Status</TableHeadingText>}
          {selectedIntegration !== "PandaDoc" && <TableHeadingText>Inspect</TableHeadingText>}
          <TableHeadingText>Manage</TableHeadingText>
        </CampaignTable>
        {loadingMappings ? (
          <Loading />
        ) : errorMappings ? (
          <AppErrorText>Error loading mapping data</AppErrorText>
        ) : selectedIntegration === "PandaDoc" && !templateId ? (
          <TableText style={{ textAlign: "center" }}>Please select a template to begin mapping</TableText>
        ) : (
          !!dataMappings?.fetchIntegrationMappings &&
          dataMappings.fetchIntegrationMappings
            .slice()
            .filter((item: any) => item?.mapping_type === mappingType)
            ?.map((item: any, index: number) => (
              <CampaignTable selectedIntegration={selectedIntegration} key={index}>
                <TableText>{item.opsiq_object}</TableText>
                <TableText>{item.opsiq_field_label}</TableText>
                {selectedIntegration !== "PandaDoc" && <TableText>{item.external_object}</TableText>}
                <TableText>{item.external_field_label}</TableText>
                {selectedIntegration !== "PandaDoc" && <TableText>{convertRuleToLabel(item.sync_rule)}</TableText>}
                {selectedIntegration !== "PandaDoc" && (
                  <TableText>
                    {!!item.trigger_events
                      ? item.trigger_events?.map(
                          (ev: string, i: number) => `${ev}${i + 1 < item.trigger_events.length ? ", " : ""}`,
                        )
                      : "N/A"}
                  </TableText>
                )}
                {selectedIntegration !== "PandaDoc" && (
                  <TableText style={{ color: item.status ? theme.SUCCESS500 : theme.ATTENTION700 }}>
                    {item.status ? "Ok" : "Issue"}
                  </TableText>
                )}
                {selectedIntegration !== "PandaDoc" && (
                  <NewAppButton
                    variant="attention"
                    style={{ display: "contents" }}
                    onClick={() => {
                      setMapStateData({
                        id: item.id,
                        external_object: item.external_object,
                        external_field: item.external_field,
                        external_field_label: item.external_field_label,
                        opsiq_object: item.opsiq_object,
                        opsiq_field: item.opsiq_field,
                        opsiq_field_label: item.opsiq_field_label,
                        opsiq_field_type: item.opsiq_field_type,
                        sync_rule: item.sync_rule,
                        trigger_events: item.trigger_events || [],
                        mapping_type: item?.mapping_type,
                      });
                      setIntegrationMapPicklistModal(true);
                    }}
                    disabled={!item.inspect}
                  >
                    <TableText style={{ color: theme.ATTENTION700, opacity: item.inspect ? 1 : 0.25 }}>View</TableText>
                  </NewAppButton>
                )}
                <EditText
                  onClick={() => {
                    setMapStateData({
                      id: item.id,
                      external_object: item.external_object,
                      external_field: item.external_field,
                      external_field_label: item.external_field_label,
                      opsiq_object: item.opsiq_object,
                      opsiq_field: item.opsiq_field,
                      opsiq_field_label: item.opsiq_field_label,
                      opsiq_field_type: item.opsiq_field_type,
                      sync_rule: item.sync_rule,
                      trigger_events: item.trigger_events || [],
                      mapping_type: item?.mapping_type,
                    });
                    setEditIntegrationType(tableType);
                    setShowIntegrationMapRulesModal(true);
                  }}
                >
                  Edit
                </EditText>
              </CampaignTable>
            ))
        )}
      </AppSidebarCard>
    </>
  );
};

const CreateOptionsComponent: React.FC<CreateOptionsProps> = ({
  drillInPage,
  configureOption,
  togglePageTitle,
  manageTemplatesOn,
  integrationReportingOn,
}) => {
  const [isClicked, setIsClicked] = useState(false);
  // deprecated
  const {
    integrationErrorLogModal,
    setIntegrationErrorLogModal,
    selectedIntegration,
    setSelectedIntegration,
  } = useContext(ModalContext);
  const { hubspotBackfill } = useFlags();
  return (
    <OutsideEvent onOutsideClick={() => setIsClicked(false)}>
      <ContainerDiv>
        <div
          onClick={() => {
            setIsClicked(!isClicked);
          }}
        >
          <NewAppButton size="lg" variant={"secondary"}>
            More Actions <img style={{ paddingBottom: "2px" }} src={iconDownSmallBlue} alt="show options" />
          </NewAppButton>
        </div>
        {isClicked && (
          <OptionsDiv>
            <Option
              onClick={() => {
                setSelectedIntegration(configureOption as IIntegrations);
                drillInPage();
                !!togglePageTitle && togglePageTitle();
              }}
            >
              Configure Field Mapping
            </Option>
            {configureOption === "PandaDoc" && (
              <Option
                onClick={() => {
                  manageTemplatesOn();
                  setSelectedIntegration(configureOption as IIntegrations);
                  drillInPage();
                  !!togglePageTitle && togglePageTitle();
                }}
              >
                Manage Templates
              </Option>
            )}
            {configureOption === "HubSpot" && !!hubspotBackfill && (
              <Option
                onClick={() => {
                  //TODO:
                  manageTemplatesOn();
                  setSelectedIntegration(configureOption as IIntegrations);
                  drillInPage();
                  !!togglePageTitle && togglePageTitle();
                }}
              >
                Import Missing Leads
              </Option>
            )}
            <Option
              onClick={() => {
                integrationReportingOn();
                setSelectedIntegration(configureOption as IIntegrations);
                drillInPage();
                !!togglePageTitle && togglePageTitle();
              }}
            >
              Integration Reports
            </Option>
          </OptionsDiv>
        )}
      </ContainerDiv>
    </OutsideEvent>
  );
};

const StepIntegration: React.FC<DisappearingDivProps> = ({
  step,
  blinds,
  setBlinds,
  isOnboarding,
  togglePageTitle,
}) => {
  const show_static = new URLSearchParams(useLocation().search).get("show_static");
  const showStatic = !!show_static && show_static === "true";
  const [manageTemplatesOn, setManageTemplatesOn] = useState(false);
  const [integrationReportingOn, setIntegrationReportingOn] = useState(false);

  const [emails, setEmails] = useState(
    // string aray of emails
    [] as string[],
  );
  const [sendEmails, setSendEmails] = useState(false);

  const {
    // deprecated
    integrationErrorLogModal,
    selectedIntegration,
    setSelectedIntegration,
    selectedIntegrationLabel,
    showIntegrationMapRulesModal,
    integrationMapPicklistModal,
    setTemplateId,
    mapStateData,
    templateId,
    showDisconnectIntegrationModal,
    selectedIntegrationDisconnect,
  } = useContext(ModalContext);

  // useEffect(() => {
  //   return () => setSelectedIntegrationLabel("");
  // }, []);

  const [drilledInState, setDrilledInState] = useState(false);
  const [confirmHubspotSync, setConfirmHubspotSync] = useState(false);
  const [hubspotModalStep, setHubspotModalStep] = useState(1);

  const history = useHistory();

  const { data: dataOrg, loading: loadingOrg, error: errorOrg } = useQuery(FETCH_ORGANIZATION, {
    fetchPolicy: "network-only",
    onCompleted(organization) {
      if (!organization) {
        return;
      }
      setEnableEmailToErrorReporting(organization?.fetchOrganization?.send_error_log_email);
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [addEmailToErrorReporting, { loading: loadingAddEmailToErrorReporting }] = useMutation(
    ADD_EMAIL_TO_ERROR_REPORTING,
    {
      onCompleted({ addEmailToErrorReporting }) {
        if (!addEmailToErrorReporting) {
          appToast("Error: Something went wrong!");
          return;
        }
        appToast("Integration Erorr Reporting Settings Updated");
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `addEmailToErrorReporting GraphQL Error: ${message}`,
        });

        console.log("Error in addEmailToErrorReporting: ", message);
      },
    },
  );

  const {
    data: dataVisibleTemplatesRep,
    loading: loadingVisibleTemplatesRep,
    error: errorVisibleTemplatesRep,
  } = useQuery(FETCH_PANDADOC_TEMPLATE_REP, {
    fetchPolicy: "network-only",
  });

  const [completeOrganizationOnboarding, { loading }] = useMutation(COMPLETE_ONBOARDING, {
    onCompleted({ completeOrganizationOnboarding }) {
      if (!completeOrganizationOnboarding) {
        appToast("Error: Something went wrong!");
        return;
      }
      history.push(`/onboarding?step=${step + 1}`);
      setBlinds(true);
      // appToast("Setting updated");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `completeOrganizationalOnboarding GraphQL Error: ${message}`,
      });
      console.log("Error in updateOrg: ", message);
    },
  });

  const { data: dataMappings, loading: loadingMappings, error: errorMappings, refetch: refetchMappings } = useQuery(
    FETCH_INTEGRATION_MAPPINGS,
    {
      skip: !selectedIntegration,
      variables: {
        integration_type: selectedIntegration,
        template_id: templateId ? templateId : undefined,
      },
      fetchPolicy: "network-only",
      // skip: selectedIntegration === "PandaDoc" && !templateId,
    },
  );

  const { data: dataStatus, loading: loadingStatus, error: errorStatus } = useQuery(CHECK_INTEGRATION_STATUS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });
  // emails is a string array
  const handleChangeEmails = (emails: String[]) => {
    const emailsList = emails?.map((item: any) => item.value);
    console.log("emailsList", emailsList);
  };

  const renderFieldMapping = () => {
    const options = dataVisibleTemplatesRep?.fetchAllPandaDocTemplateForRep?.map((item: any) => ({
      label: item.template_name,
      value: item.template_id,
    }));
    return (
      <TopAlignDiv>
        <TopRowDiv>
          <BackButton
            // borderless
            onClick={() => {
              // setSelectedIntegrationLabel("");
              setTemplateId(undefined);
              setDrilledInState(false);
              !!togglePageTitle && togglePageTitle();
            }}
          >
            <img style={{ marginRight: "8px" }} src={iconArrowBackNew} alt="Back" />
            Back to Connected apps
          </BackButton>
          {selectedIntegration === "PandaDoc" && (
            <TemplateIdSelect>
              <AppMultiSelect
                isMulti={false}
                name="template_id"
                options={options}
                placeholder="Select a template..."
                onChange={(e: any) => {
                  setTemplateId((!!e?.value && e.value) || "");
                }}
              />
            </TemplateIdSelect>
          )}
        </TopRowDiv>
        <FieldMappingTable
          dataMappings={dataMappings}
          errorMappings={errorMappings}
          loadingMappings={loadingMappings}
          tableType={"System"}
          key={"System"}
        />
        <FieldMappingTable
          dataMappings={dataMappings}
          errorMappings={errorMappings}
          loadingMappings={loadingMappings}
          tableType={"Custom"}
          key={"Custom"}
        />
        {showStatic && (
          <FieldMappingTable
            dataMappings={dataMappings}
            errorMappings={errorMappings}
            loadingMappings={loadingMappings}
            tableType={"Static"}
            key={"Static"}
          />
        )}
      </TopAlignDiv>
    );
  };

  const backToIntegrations = () => {
    setSelectedIntegration(undefined);
    setDrilledInState(false);
    !!togglePageTitle && togglePageTitle();
    setManageTemplatesOn(false);
    setIntegrationReportingOn(false);
  };

  const syncHubspotContacts = async () => {
    try {
      const api_call = await restAPI
        .post(`${BACKEND_URL}/hubspot/syncContactsViaQueue`)
        .then((response) => {
          if (!!response?.data) {
            console.log("response sucessful", response);
            appToast("Hubspot Sync successful!");
            setHubspotModalStep(1);
            setConfirmHubspotSync(false);
          }
        })
        .catch((error) => console.log("error here: ", JSON.stringify(error)));

      return api_call;
    } catch (error: any) {
      console.log("error", error);
    }
  };

  const [enableEmailToErrorReporting, setEnableEmailToErrorReporting] = useState(
    dataOrg?.fetchOrganization?.send_error_log_email || false,
  );

  const renderHubspotSync = () => {
    return (
      <>
        <Modal
          open={confirmHubspotSync}
          onClose={() => {
            setHubspotModalStep(1);
            setConfirmHubspotSync(false);
          }}
        >
          <ModalDiv>
            {hubspotModalStep === 1 && (
              <>
                <AppText fontSize={16} style={{ fontWeight: 600 }}>
                  Import Missing Hubspot Contacts
                </AppText>
                <AppText style={{ width: 240 }}>
                  This action will import all Contacts in Hubspot that don't exist in Sellfire
                </AppText>
              </>
            )}
            {hubspotModalStep === 2 && (
              <>
                <AppText fontSize={16} style={{ fontWeight: 600, width: 282 }}>
                  Are you sure you would like to import your missing HubSpot Contacts into Sellfire?
                </AppText>
                <AppText style={{ width: 240 }}>You cannot undo this action</AppText>
              </>
            )}
            <ButtonDiv>
              {hubspotModalStep === 1 && (
                <NewAppButton variant="primary" size="lg" onClick={() => setHubspotModalStep(2)}>
                  Import
                </NewAppButton>
              )}
              {hubspotModalStep === 2 && (
                <>
                  <NewAppButton
                    variant="primary"
                    size="lg"
                    onClick={() => {
                      syncHubspotContacts();
                    }}
                  >
                    Import
                  </NewAppButton>
                  <NewAppButton
                    variant="attention"
                    size="lg"
                    onClick={() => {
                      setHubspotModalStep(1);
                      setConfirmHubspotSync(false);
                    }}
                  >
                    Cancel
                  </NewAppButton>
                </>
              )}
            </ButtonDiv>
          </ModalDiv>
        </Modal>
        <TopAlignDiv>
          <SpaceBetweenDiv>
            <BackButton
              // borderless
              onClick={backToIntegrations}
            >
              <img style={{ marginRight: "8px" }} src={iconArrowBackNew} alt="Back" />
              Back to Connected apps
            </BackButton>
          </SpaceBetweenDiv>
          <div style={{ maxWidth: "600px", display: "flex", flexDirection: "column", gap: 24 }}>
            <AppText>
              Syncing HubSpot Contacts will import your contacts in HubSpot to Sellfire. This is only for new contacts.
              Existing HubSpot Contacts that already exist in Sellfire will be Ignored.
            </AppText>
            <AppText fontSize={12} style={{ color: theme.NEUTRAL300 }}>
              Last Synced at: {moment(dataOrg?.fetchOrganization?.hubspot_last_sync_date).format("YYYY-MM-DD hh:mm a")}
            </AppText>
            <NewAppButton
              // disabled={
              //   !!dataOrg?.fetchOrganization?.hubspot_last_sync_date &&
              //   moment(dataOrg?.fetchOrganization?.hubspot_last_sync_date).diff(moment(), "days") < 30
              // }
              variant="secondary"
              onClick={() => setConfirmHubspotSync(true)}
            >
              Sync Hubspot Contacts
            </NewAppButton>
          </div>
        </TopAlignDiv>
      </>
    );
  };

  if (drilledInState && integrationReportingOn) {
    // the integration reporting page does not use the same top nav as the other pages
    return (
      <Sentry.ErrorBoundary fallback={"An error has occured on the Integrations step of Admin Onboarding"}>
        <IntegrationReportingComponent backToIntegrations={backToIntegrations} />
      </Sentry.ErrorBoundary>
    );
  }

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured on the Integrations step of Admin Onboarding"}>
      {/* deprecated */}
      {integrationErrorLogModal && !!selectedIntegration && <ErrorLogReportModal />}
      {showIntegrationMapRulesModal && <IntegrationMapRules />}
      {integrationMapPicklistModal && <IntegrationMapSyncPicklist integration_mapping_id={mapStateData.id} />}
      {showDisconnectIntegrationModal && !!selectedIntegrationDisconnect && <DisconnectIntegrationModal />}
      {selectedIntegration === "PandaDoc" && drilledInState && manageTemplatesOn ? (
        <ManageTemplatesComponent backToIntegrations={backToIntegrations} />
      ) : selectedIntegration === "HubSpot" && drilledInState && manageTemplatesOn ? (
        renderHubspotSync()
      ) : !!selectedIntegration && drilledInState ? (
        renderFieldMapping()
      ) : (
        // renderMappingTable("System")
        <TopAlignDiv>
          <BoxesGrid>
            {loadingStatus ? (
              <Loading />
            ) : errorStatus ? (
              <AppErrorText>Error loading integration states</AppErrorText>
            ) : (
              integrationsList?.map((item) => (
                <IntegrationCard
                  integration={item}
                  isConnected={!!dataStatus?.checkIntegrationStatus[item.name]}
                  optionsButton={
                    <CreateOptionsComponent
                      drillInPage={() => setDrilledInState(true)}
                      configureOption={item.name}
                      togglePageTitle={togglePageTitle}
                      manageTemplatesOn={() => setManageTemplatesOn(true)}
                      integrationReportingOn={() => setIntegrationReportingOn(true)}
                    />
                  }
                />
              ))
            )}
          </BoxesGrid>
          <EmailDiv>
            {loadingOrg ? (
              <Loading />
            ) : errorOrg ? (
              <AppErrorText>Error loading reps</AppErrorText>
            ) : (
              <EmailsSwitchDiv>
                <Switch
                  onChange={(checked) => {
                    addEmailToErrorReporting({
                      variables: {
                        active: checked,
                      },
                    });

                    setEnableEmailToErrorReporting(checked);
                  }}
                  onColor={theme.PRIMARY500}
                  offColor={theme.NEUTRAL200}
                  checked={enableEmailToErrorReporting}
                  height={32}
                  width={54}
                  handleDiameter={24}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
                <RadioText>Send integration email report updates </RadioText>
              </EmailsSwitchDiv>
            )}
            <>
              {loadingOrg ? (
                <Loading />
              ) : errorOrg ? (
                <AppErrorText>Error loading reps</AppErrorText>
              ) : (
                !!dataOrg &&
                !!dataOrg?.fetchOrganization &&
                !!dataOrg.fetchOrganization?.Reps && (
                  <div style={{ maxWidth: "732px" }}>
                    <CreatableSelect
                      isClearable
                      isMulti
                      onChange={(value: any) => {
                        setEmails(value?.map((item: any) => item.value));

                        addEmailToErrorReporting({
                          variables: {
                            emails: value?.map((item: any) => item.value),
                          },
                        });
                        console.log(emails);
                      }}
                      placeholder="Enter email addresses..."
                      options={dataOrg?.fetchOrganization?.Reps?.map((item: any) => ({
                        label: `${item.email} (${item.first_name} ${item.last_name})`,
                        value: item.email,
                      }))}
                      defaultValue={dataOrg?.fetchOrganization?.Reps.filter((item: any) =>
                        dataOrg?.fetchOrganization?.error_log_email_address?.includes(item.email),
                      )?.map((item: any) => ({
                        label: `${item.email} (${item.first_name} ${item.last_name})`,
                        value: item.email,
                      }))}
                    />
                  </div>
                )
              )}
            </>
          </EmailDiv>
        </TopAlignDiv>
      )}
    </Sentry.ErrorBoundary>
  );
};

const EmailDiv = styled.div`
  padding: 12px;
  min-width: 500px;
  background-color: ${theme.WHITE_COLOR};
  width: fit-content;
`;
const EmailsSwitchDiv = styled.div`
  display: grid;
  grid-template-columns: 75px 1fr;
  margin-top: 28px;
  margin-bottom: 20px;
  align-items: center;
`;

const BoxesGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 250px 250px 250px;
`;

const AddNewDiv = styled.div`
  padding: 4px 12px 4px 12px;
  background-color: ${theme.WHITE_COLOR};
  display: flex;
  justify-content: flex-end;
`;

const AddNewBottomDiv = styled.div`
  padding: 48px 12px 4px 12px;
  background-color: ${theme.WHITE_COLOR};
  display: flex;
  justify-content: flex-end;
`;

const ConfirmButton = styled(NewAppButton)`
  height: 40px;
  width: 150px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
`;

const TableText = styled(AppText)`
  font-size: 12px;
  padding-left: 16px;
  padding-right: 16px;
  border-right: 1px solid ${theme.NEUTRAL200};
  padding-top: 11px;
  width: 100%;
  letter-spacing: 0.2px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TallTableText = styled(TableText)`
  padding-top: 16px;
`;

const EditText = styled(TableText)`
  color: ${theme.PRIMARY500};
  font-weight: 600;
  border-bottom: none;
  border-right: none;
  cursor: pointer;
`;

interface CampaignTableProps {
  selectedIntegration?: string;
}

const CampaignTable = styled.div<CampaignTableProps>`
  display: grid;
  width: calc(100%);
  overflow-x: hidden;

  //string literals for props w/ grid template
  grid-template-columns: ${(props) =>
    props.selectedIntegration === "PandaDoc" ? `1fr 1fr 1fr 80px;` : `130px 1fr 150px 1fr 1fr 146px 80px 80px 80px;`};
  grid-template-rows: 40px;
  align-items: stretch;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  border-top: 1px solid ${theme.NEUTRAL200};
  margin-top: -1px;
  /* margin-left: 1px; */
  background-color: ${theme.WHITE_COLOR};
  :last-child {
    border-bottom: none;
  }
`;

const ManageTemplatesTable = styled(CampaignTable)`
  grid-template-columns: 1fr 1fr;
`;

const ManageTemplatesTableData = styled(ManageTemplatesTable)`
  grid-template-rows: 50px;
`;

const TableHeadingText = styled(AppText)`
  font-weight: 600;
  padding: 11px 16px;
  width: 100%;
  height: 100%;
  vertical-align: center;
  background-color: ${theme.NEUTRAL200};
  border-right: 1px solid ${theme.NEUTRAL200};
  :last-child {
    border-right: none;
  }
`;

const BackButton = styled(NewAppButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 245px;
`;

const ConnectedInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /* margin-top: -4px; */
`;

const TwoItemsDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
`;

const OptionsDiv = styled.div`
  position: absolute;
  left: 0px;
  top: 48px;
  width: 171px;
  background-color: ${theme.WHITE_COLOR};
  /* box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15); */
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 4px;
  z-index: 3;
`;

const Option = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 40px;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.2px;
  padding-left: 12px;
  background-color: ${theme.WHITE_COLOR};
  :first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  :hover {
    background-color: ${theme.NEUTRAL200};
  }
`;

const RadioText = styled(AppText)`
  font-size: 14px;
  line-height: 21px;
`;

const ContainerDiv = styled.div`
  min-width: 100%;
  height: 40px;
  position: relative;
  cursor: pointer;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
`;

const ConnectionStatusText = styled(AppText)`
  font-size: 10px;
  color: ${theme.NEUTRAL400};
  margin-bottom: 2px;
`;

const ConnectedStatusText = styled(AppText)`
  font-size: 10px;
  color: ${theme.SUCCESS500};
  letter-spacing: 0.2;
  line-height: 1;
`;

const IntegrationNameText = styled(AppText)`
  font-size: 14px;
  letter-spacing: 0.2;
  line-height: 1.5;
`;

const IntegrationNameTextConnected = styled(AppText)`
  font-size: 18px;
  letter-spacing: 0.2;
  line-height: 1.5;
  margin: 20px 0px;
`;

const InfoTextDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const IntegrationInfoDiv = styled.div`
  display: grid;
  grid-template-columns: 70px 2fr;
  grid-gap: 24px;
  margin-bottom: 24px;
`;

const ConnectButton = styled(NewAppButton)`
  height: 40px;
  text-transform: capitalize;
`;

const IntegrationBox = styled.div`
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 252px;
  height: fit-content;
  background: transparent;
`;

const SkipStepButton = styled(AppButton)`
  width: 221px;
  height: 47px;
  border-radius: 2px;
  border: solid 2px ${theme.NEUTRAL100};
  background-color: #d0d0d0;
  font-size: 15px;
  font-weight: 500;
  color: ${theme.BLACK_COLOR};
`;

const EmptyStateText = styled(AppText)`
  font-size: 14px;
  margin-bottom: 23px;
`;

const EmptyStateDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
`;

interface ProductsInputProps {
  width?: number;
  leftAlign?: boolean;
}

const ProductsInput = styled(InputField)<ProductsInputProps>`
  text-align: ${(props) => (props.leftAlign ? "left" : "center")};
  height: 45px;
  width: ${(props) => (props.width ? `${props.width}px` : "100px")};
  border: solid 1px ${theme.NEUTRAL200};
  font-size: 13px;
  :focus {
    border: solid 1px ${theme.BLACK_COLOR};
  }
`;

const InputLabelBold = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
  margin-left: 48px;
  white-space: nowrap;
`;

const AvergeCommissionEarnedText = styled(AppText)`
  font-size: 10px;
  line-height: 20px;
  position: relative;
  :before {
    position: absolute;
    content: " ";
    left: -28px;
    top: 10px;
    width: 22px;
    height: 13.5px;
    border-top: solid 1px ${theme.BLACK_COLOR};
    border-left: solid 1px ${theme.BLACK_COLOR};
  }
  :after {
    position: absolute;
    content: " ";
    right: -28px;
    top: 10px;
    width: 22px;
    height: 13.5px;
    border-top: solid 1px ${theme.BLACK_COLOR};
    border-right: solid 1px ${theme.BLACK_COLOR};
  }
`;

const ProductsDivLastColumn = styled.div`
  display: flex;
  align-items: center;
`;

const ProductsDiv = styled.div`
  display: grid;
  grid-template-columns: 300px 100px 500px;
  grid-gap: 30px;
`;

const SourcesTable = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr) 200px;
  grid-gap: 10px;
  justify-self: center;
  align-self: center;
`;

const SegmentTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
  margin: auto;
`;

const ContentSegment = styled.div``;

const SourcesRow = styled.div`
  display: grid;
  grid-template-rows: 50px 50px 50px;
  justify-self: center;
  align-self: center;
  break-inside: avoid;
  /* flex-direction: column;
  height: 250px;
  justify-content: center;
  align-items: center; */
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  break-inside: avoid;
  height: 50px;
  margin-bottom: 20px;
`;

const TwoSegments = styled.div`
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const TopAlignDiv = styled.div`
  min-height: 400px;
  /* max-height: 450px; */
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SaveAndContinueButton = styled(AppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1.0)};
`;

const TopRowDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TemplateIdSelect = styled.div`
  width: 200px;
`;

const ModalDiv = styled.div`
  padding: 48px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  justify-content: space-between;
  align-items: center;
  height: 314px;
  width: 400px;
  text-align: center;
`;
const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-around;
  width: 100%;
  align-items: center;
`;

export { StepIntegration };
